export const getters = (gaApp) => ({
  formattedAddressList() {
    return this.list.map((item) => {
      const { street: localityMain, house: localityHouse } =
        gaApp.services.location.addresses.formatAddressStreetHouseDivided(
          item.id,
        )
      const area = gaApp.services.location.addresses.formatAddressCityState(
        item.id,
      )

      return {
        name: item.id,
        value: item.id,
        id: item.id,

        localityMain,
        localityHouse,
        area,
      }
    })
  },

  addressListAsObject() {
    const result = {}
    this.list.forEach((item) => {
      result[item.id] = item
    })

    return result
  },

  isFormValid() {
    return Object.values(this.form).every((field) => !field.error)
  },

  defaultId() {
    return gaApp.services.location.main.getDeliveryAddress()?.saveId || ''
  },
})
