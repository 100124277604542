import { match } from 'path-to-regexp'

import { clearGarbagePrefix, locationHandler } from '../core/utils'

export class RouterService {
  constructor(gaApp) {
    this.gaApp = gaApp

    this.gaApp.eventBus.subscribe('module/app/link/redirect', (item) => {
      if (!window.serverCache) {
        window.serverCache = {}
      }
      window.serverCache[item.type] = { data: item.data }
    })

    this.gaApp.eventBus.subscribe('module/app/redirect/prefetch', (item) => {
      this.gaApp.repositories.app.main.getRedirectData({
        url: item.url,
      })
    })
  }

  pushState(...argArray) {
    const route = window.location
    const toPath = argArray[2]

    if (!toPath.includes(route.pathname)) {
      this.gaApp.stores.app.main.setRouter({
        from: {
          fullPath: route.href.replace(route.origin, ''),
          path: route.pathname,
          params: {},
        },
        to: {
          fullPath: toPath,
          path: toPath.split('?')[0].split('#')[0],
          params: {},
        },
      })
    }

    window.history.pushState(...argArray)
  }

  replaceState(...argArray) {
    const route = window.location
    const toPath = argArray[2]

    this.gaApp.stores.app.main.setRouter({
      from: {
        fullPath: route.href.replace(route.origin, ''),
        path: route.pathname,
        params: {},
      },
      to: {
        fullPath: toPath,
        path: toPath.split('?')[0].split('#')[0],
        params: {},
      },
    })

    window.history.replaceState(...argArray)
  }

  async push(url, onComplete) {
    if (url.includes('http')) {
      window.location.assign(url)
      await new Promise(() => {})
    } else {
      await this.gaApp.router.push(url, onComplete)
    }
  }

  async getData(context) {
    const transaction =
      this.gaApp.isClient &&
      this.gaApp.services.app.apm.startTransaction(
        'route-unknown',
        'route-change',
        {
          managed: true,
          canReuse: true,
        },
      )

    // флаг про начало смены роута
    this.gaApp.stores.app.main.routeChange = true

    const { path: pagePath, query } = this.gaApp.route

    // обьект с найденым роутом
    let route = null

    // обьект с данными страницы с апи
    let pageData = {}

    // проверяем наличие данных по странице в апи
    try {
      const cacheData = this.gaApp.services.cache.main.getServerData('redirect')

      // берем только path, без query и hash
      // вырезаем из роута локаль
      let url = this.clearedPath(pagePath)

      const searchParams = new URLSearchParams(query)

      // проверяем параметр 'p'
      if (searchParams.has('p')) {
        // удаляем параметр 'p'
        searchParams.delete('p')
      }

      //  добавляем параметры к роуту
      if (searchParams.toString()) {
        url += `?${searchParams.toString()}`
      }

      const { data } =
        cacheData ||
        (await this.gaApp.repositories.app.main.getRedirectData({
          url,
        }))

      pageData = data || {}
    } catch (error) {
      this.gaApp.redirectError({ statusCode: 404 })

      return
    }

    // если с апи пришел редирект на другую урлу
    if (pageData.redirect) {
      await this.gaApp.redirect(pageData.redirect)

      return
    }

    // ищем роут
    this.gaApp.routes.some((item) => {
      // проверяем роут по moduleType и pageType
      if (
        item.moduleType === pageData.moduleType &&
        item.pageType === pageData.pageType
      ) {
        route = item

        return true
      }

      // проверяем роут по полю path
      if (!item.path) return false

      const fn = match(item.path, { decode: decodeURIComponent })
      const routeData = fn(this.clearedPath(pagePath))

      if (!routeData) return false

      // если нашли роут по path создаем обьект pageData сами
      pageData = {
        ...pageData,
        ...item,
        ...routeData.params,
      }

      route = {
        ...item,
        ...routeData.params,
      }

      return true
    })

    // если не нашли роут
    if (!route) {
      this.gaApp.redirectError({ statusCode: 404 })

      return
    }

    // включаем вебвью без доп параметров
    if (route.meta.webviewOnly) {
      this.gaApp.services.app.main.initWebview()
    }

    if (transaction) {
      transaction.name = route.path
    }

    // скачиваем чанк страницы либо формируем имя для глобал компонента в режиме сср
    const pageComponent = this.gaApp.isClient
      ? await route.component().then((module) => module.default)
      : route.moduleType + '-' + route.pageType.replace('/', '-')

    // пытаемся вызвать метод getData в сервисе page если он есть
    await this.gaApp.services[pageData.moduleType]?.page?.getData(
      pageData,
      context,
    )

    // формируем данные для передачи на страницу pages/_.vue
    const { moduleType, pageType, path, meta, pageKey = null } = route
    const routeData = {
      moduleType,
      pageType,
      pageKey,
      path,
      meta,
      pageData,
    }

    delete pageData.component
    delete routeData.component

    // вызываем глобал событие о начеле рендера страницы
    this.gaApp.eventBus.publish('module/app/router/page-created', routeData)

    return { pageComponent, pageData, routeData, transaction }
  }

  /**
   * Получает URL-путь, и делает match с полем path
   * каждого объекта массива роутов
   *
   * @param {string} path URL-путь
   * @returns {object|undefined} - объект из массива роутов
   *                             - undefined, если не случится match роутов, например PDP/PLP
   */
  getRouteByPath(path) {
    return this.gaApp.routes.find((route) => {
      if (!route.path) {
        return false
      }

      return match(route.path)(path) && route
    })
  }

  /**
   * Возвращает роут-объект по переданным moduleType и pageType
   *
   * @param moduleType - moduleType роута
   * @param pageType - pageType роута
   * @returns {object|undefined} - объект из массива роутов
   *                             - undefined, если такой роут не найден в массиве роутов приложения
   */
  getRouteByModuleAndPageTypes(moduleType, pageType) {
    return this.gaApp.routes.find(
      (route) => route.pageType === pageType && route.moduleType === moduleType,
    )
  }

  /**
   * Возвращает url по переданным moduleType, pageType роута и параметрам его url.
   *
   * @param {object} obj
   * @param {string} obj.moduleType - moduleType роута, url которого хотим получить
   * @param {string} obj.pageType - pageType роута, url которого хотим получить
   * @param {Object} obj.params - параметры url роута, например, id
   * @returns {string|null} - url роута
   *                        - null, если такой роут не найден в массиве роутов приложения или у роута нет url
   */
  getPathByRoute({ moduleType, pageType, params = {} }) {
    const route = this.getRouteByModuleAndPageTypes(moduleType, pageType)

    if (!route || !route.path) {
      return null
    }

    let path = route.path

    if (Object.keys(params).length) {
      Object.entries(params).forEach(([key, value]) => {
        path = path.replace(`:${key}`, value)
      })
    }

    return path
  }

  /**
   * Переходит к url роута приложения исходя из переданных moduleType и pageType роута, а также параметрам его url,
   * если такой роут найден в массиве роутов и имеет url.
   *
   * @param {object} obj
   * @param {string} obj.moduleType - moduleType роута, на который редиректим
   * @param {string} obj.pageType - pageType роута, на который редиректим
   * @param {Object} obj.params - параметры url роута, например, id
   */
  pushToRoute({ moduleType, pageType, params = {} }) {
    const path = this.getPathByRoute({ moduleType, pageType, params })

    if (path) {
      this.gaApp.router.push(path)
    }
  }

  /**
   * Редиректит на url роута приложения исходя из переданных moduleType и pageType роута, а также параметрам его url,
   * если такой роут найден в массиве роутов и имеет url.
   *
   * @param {object} obj
   * @param {string} obj.moduleType - moduleType роута, на который редиректим
   * @param {string} obj.pageType - pageType роута, на который редиректим
   * @param {Object} obj.params - параметры url роута, например, id
   */
  redirectToRoute({ moduleType, pageType, params = {} }) {
    const path = this.getPathByRoute({ moduleType, pageType, params })

    if (path) {
      this.gaApp.redirect(path)
    }
  }

  isAuthRequired(path) {
    const clearedPath = this.clearedPath(path)

    return this.getRouteByPath(clearedPath)?.meta?.authRequired
  }

  isAnalyticDisabled(path) {
    return this.getRouteByPath(path)?.meta?.analyticDisabled
  }

  /**
   * вырезаем из роута локаль
   */
  clearedPath(path) {
    try {
      return clearGarbagePrefix(path)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  assign(path) {
    const {
      localePath,
      i18n: { locale, defaultLocale, localeCodes },
    } = this.gaApp.ctx

    window.location.assign(
      locationHandler(path, {
        locale,
        defaultLocale,
        localeCodes,
        localePath,
      }),
    )
  }
}
