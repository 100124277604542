import { computed } from 'vue'

import { GaIconAdditionalHeartSmall } from '@ga/icons'

import { GaIcon } from '@ga/ui-components/icon'

// @vue/component
export default {
  name: 'ga-brands-item-fake',

  components: {
    GaIcon,
    GaIconAdditionalHeartSmall,
  },

  props: {
    brandName: {
      type: String,
      required: true,
    },

    // вывод иконки удаления из избранного
    canRemove: {
      type: Boolean,
      default: false,
    },

    // показывать кнопку избранное
    showFavourite: {
      type: Boolean,
      default: false,
    },

    // кликабельный бренд
    clickableLabel: {
      type: Boolean,
      default: true,
    },

    // флаг, указывающий, что отрисовка и стартовые расчеты завершены
    // используется для отображения иконки, которая скрыта по умолчанию,
    // чтобы предотвратить UI дефекты при первой отрисовке
    initialRenderingCompleted: {
      type: Boolean,
      required: true,
      default: false,
    },
  },

  setup(props) {
    const mods = computed(() => ({
      removable: props.canRemove,
      raw: !props.initialRenderingCompleted,
    }))

    return { mods }
  },
}
