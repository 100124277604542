export class PuigService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  changeWelcomeModalVisibility(value) {
    this.gaApp.stores.info.puig.opened = value
  }

  openWelcomeModal() {
    this.changeWelcomeModalVisibility(true)
  }

  closeWelcomeModal() {
    this.changeWelcomeModalVisibility(false)
  }
}
