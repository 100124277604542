import { ANALYTICS_EVENT_TYPE } from '../../../../constants'
import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'

export class GtmGa4ClickNewCityModel extends GtmBaseModel {
  constructor({ city }) {
    super({})

    this.action_type = 'click_new_city'
    this.type = ANALYTICS_EVENT_TYPE.KPPK
    this.city = city
  }
}
