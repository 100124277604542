import { useContext } from '@nuxtjs/composition-api'
import get from 'lodash/get'

import { computed, onUnmounted, ref } from 'vue'

import { GaIconPrimarySearch } from '@ga/icons'

import { GaButtonBase } from '@ga/ui-components/button-base'
import { GaClearButton } from '@ga/ui-components/clear-button'
import { GaIcon } from '@ga/ui-components/icon'
import { GaSearchBar } from '@ga/ui-components/search-bar'

import { useAnalytics } from '../../../composable/analytics'

import {
  useMediaType,
  useSqueezeAnimation,
  useVirtualKeyboardHide,
} from './scripts/use'

// @vue/component
export default {
  name: 'ga-brands-search',

  components: {
    GaButtonBase,
    GaIcon,
    GaSearchBar,
    GaClearButton,
    GaIconPrimarySearch,
  },

  props: {
    searchBarConfig: {
      type: Object,
      default: () => null,
    },
  },

  emits: ['input', 'focus', 'blur', 'animated'],

  setup(props, { emit }) {
    const { $gaApp } = useContext()

    const searchBarRef = ref(null)
    const rootRef = ref(null)
    const valueInternal = ref('')

    let isSearching = false
    let searchBarAutofocused = false
    const analytic = useAnalytics()

    const searchBarConfigCurrent = computed(() => {
      const { mediaType } = useMediaType()

      return get(props.searchBarConfig ?? {}, mediaType.value)
    })

    const scrollerRef = computed(() => {
      return process.client && document.documentElement
    })

    const { onSearchBarAnimated, iconStyle } = useSqueezeAnimation(emit)

    const { attachListener, detachListener } = useVirtualKeyboardHide(rootRef)
    const onSearchBarFocus = () => {
      attachListener()

      // отправляем событие только если
      // пользователь сам выставил фокус на поле
      if (!searchBarAutofocused) {
        analytic.onSearchFocus()
      } else {
        searchBarAutofocused = false
      }

      emit('focus')
    }
    const onSearchBarBlur = () => {
      isSearching = false
      detachListener()
      emit('blur')
    }

    const focusInput = () => {
      searchBarRef.value.$el[0]?.focus()
      searchBarAutofocused = true
    }

    const onInput = (value) => {
      valueInternal.value = value

      emit('input', value)

      // событие указывающее,
      // что пользователь начал вводить поисковый запрос
      if (!isSearching && value) {
        isSearching = true
        emit('search')
      }
    }

    const isDesktop = computed(() => $gaApp.mq('tablet-large+'))

    const onSearchBarMounted = () => {
      if (isDesktop.value) {
        focusInput()
      }
    }

    onUnmounted(() => {
      detachListener()
    })

    return {
      valueInternal,

      scrollerRef,
      rootRef,
      searchBarRef,
      searchBarConfigCurrent,

      onInput,
      focusInput,
      onSearchBarMounted,

      onSearchBarAnimated,
      iconStyle,

      onSearchBarFocus,
      onSearchBarBlur,
    }
  },
}
