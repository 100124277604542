import { ANALYTICS_EVENT_TYPE } from '../../../../constants'
import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'

export class GtmGa4ClickChangeCityModel extends GtmBaseModel {
  constructor({ city, place }) {
    super({})

    this.action_type = 'click_change_city'
    this.city = city
    this.place = place
    this.type = ANALYTICS_EVENT_TYPE.KPPK
  }
}
