import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'

export class GtmGa4ChangeInnClickModel extends GtmBaseModel {
  constructor({ type }) {
    super({})

    this.action_type = 'click_change_inn'
    this.type = type
  }
}
