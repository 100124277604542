export const priceDiscountSize = {
  type: 'object',
  properties: {
    fontSize: {
      type: 'number',
      required: true,
      description: 'Размер шрифта в пикселях',
    },
    lineHeight: {
      type: 'number',
      required: true,
      description:
        'Высота строки в безразмерном значении (font-size * lineHeight)',
    },
    gap: {
      type: 'number',
      required: true,
      description: 'Расстояние между строками цен',
    },
  },
  additionalProperties: false,
}
