import { FILTER_KEY } from '../constants/keys'

export class OffersFiltersService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  /**
   * Выполняет запрос на получение фильтров акций на основе выбранных фильтров.
   * Устанавливает в стор фильтры, быстрые фильтры, и количество выбранных фильтров.
   *
   * @return {Promise<Object|undefined>}
   */
  async request() {
    try {
      const filters = this.gaApp.stores.filters.main.filtersSelected
      const quickFilters = this.gaApp.stores.filters.main.filtersQuickSelected

      const { id: cityId } =
        this.gaApp.services.location.main.getDeliveryAddress()
      const selectedCityId =
        filters.find((el) => el.key === FILTER_KEY.CITY_ID)?.value || cityId

      const { data } = await this.gaApp.services.promo.api.fetchOffersFilters({
        cityId: selectedCityId,
        filters,
        quickFilters,
      })

      this.gaApp.stores.filters.main.setFilters(data.filters)
      this.gaApp.stores.filters.main.setFiltersQuick(data.quickFilters)

      this.gaApp.stores.filters.main.setFiltersHash({
        filters,
        quickFilters,
      })

      this.gaApp.stores.filters.main.setCountSelectedFilters(
        data.countSelectedFilters,
      )
      this.gaApp.stores.filters.main.setCountProductsFiltered(
        data.filteredCount,
      )

      return data
    } catch {
      this.gaApp.services.notification.main.open(
        this.gaApp.i18n.t('promo.error.filters'),
      )
    }
  }

  /**
   * Метод нужен, чтобы получить актуальный список выбранных фильтров на основе тех,
   * которые пришли с бека и которые находятся в query params
   * @returns {Promise<Array<object>>}
   */
  async getFilteredQueryFilters() {
    // Сбрасываем стейт, чтобы получить фильтры, относящиеся только к текущей локации
    this.gaApp.services.filters.main.resetState()

    // Устанавливаем ссылку на функцию запроса фильтров, т.к. после ресета
    // эта ссылка теряется
    this.gaApp.services.filters.main.setRequestFiltersFn(
      this.gaApp.services.promo.offersFilters.request.bind(
        this.gaApp.services.promo.offersFilters,
      ),
    )

    const { filters, quickFilters } =
      await this.gaApp.services.promo.offersFilters.request()

    const query = this.gaApp.route.query

    // Получаем список только тех фильтров из query, которые есть и в query, и в фильтрах,
    // пришедших с бека
    const filtersFromQuery =
      this.gaApp.services.filters.queryParse.getFiltersFromQuery(filters, query)
    const quickFiltersFromQuery =
      this.gaApp.services.filters.queryParse.getFiltersFromQuery(
        quickFilters,
        query,
      )

    // Устанавливаем актуальные выбранные фильтры
    this.gaApp.stores.filters.main.setFiltersSelected(filtersFromQuery)
    this.gaApp.stores.filters.main.setFiltersQuickSelected(
      quickFiltersFromQuery,
    )

    this.gaApp.stores.filters.main.setFiltersHash({
      filters: filtersFromQuery,
      quickFilters: quickFiltersFromQuery,
    })

    // Устанавливаем актуальное кол-во выбранных фильтров
    this.gaApp.stores.filters.main.setCountSelectedFilters(
      this.gaApp.stores.filters.main.getFiltersMainSelected.length,
    )

    return filtersFromQuery
  }

  /**
   * Обновление фильтров модалки
   */
  async requestFilters() {
    this.gaApp.stores.filters.main.setFiltersPending(true)

    await this.request()
    this.gaApp.stores.filters.main.setFiltersPending(false)
  }

  getFiltersQuickData(filtersValues) {
    const selectedFilters =
      filtersValues.value.filter((el) => el.isSelected) || []
    const value = selectedFilters.map((el) => el.id)

    const options = filtersValues.value.map(({ id, name }) => {
      return {
        value: id,
        label: name,
      }
    })

    return {
      value,
      options,
    }
  }

  /**
   * Нужно для того, чтобы обновить query параметры, убрав оттуда
   * те фильтры, которых нет в текущей локации пользака
   */
  updateFiltersUrlPostTask() {
    this.gaApp.services.app.scheduler.postTasks([
      {
        task: () => {
          this.gaApp.services.filters.main.updateFiltersURL()
        },
      },
    ])
  }
}
