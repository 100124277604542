import { ACTIONS } from '../../../constants'
import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'
import { GtmGa4ProductAddToBaseModel } from '../gtm-ga4-product-add-to-base.model/gtm-ga4-product-add-to-base.model'

export class GtmGa4ProductAddToFavoritesModel extends GtmBaseModel {
  constructor({ product }) {
    super(ACTIONS.ADD_TO_WISHLIST.GA4)

    this.pdp_type = ''

    this.ecommerce = new GtmGa4ProductAddToBaseModel({ product })
  }
}
