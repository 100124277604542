import {
  ACCESS_TOKEN_KEY,
  HAS_ACCESS_TOKEN_KEY,
  HAS_REFRESH_TOKEN_KEY,
} from '../constants'
import { buildContentTypeHeader, buildRequestPayload } from '../helpers'

export class CoreService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  /**
   * Метод инициализации
   */
  async init() {
    try {
      const { hasAccessToken, hasRefreshToken } = this.checkTokens()

      // Если есть accessToken, то извлекаем из токена юзера и устанавливаем
      if (hasAccessToken) {
        await this.gaApp.services.user.api.fetchUserInfoFull()

        return
      }

      /**
       * Если нет accessToken, но есть refreshToken,
       * то перевыпускаем токены
       */
      if (!hasAccessToken && hasRefreshToken) {
        await this.doRefreshTokens()

        await this.gaApp.services.user.api.fetchUserInfoFull()
      }
      // eslint-disable-next-line no-useless-catch
    } catch (error) {
      throw error
    } finally {
      this.gaApp.stores.auth.main.setIsInit()

      this.gaApp.eventBus.publish('module/auth/init')
    }
  }

  /**
   * Метод обновления токенов
   *
   * @returns {object} response | error
   */
  async doRefreshTokens() {
    this.gaApp.stores.auth.main.setIsTokenPending(true)

    try {
      const reqType = 'form'
      const data = buildRequestPayload('refreshTokens', reqType, {
        magento: !this.gaApp.features.get('cartPlaidApi'), // при включенной плед корзине, отправляем флаг для отключения мага авторизации
      })
      const config = {
        _isRefreshRequest: true,
        ...buildContentTypeHeader(reqType),
      }

      const response = await this.gaApp.services.auth.api.refreshTokens(
        data,
        config,
      )

      return response
    } catch (error) {
      this.gaApp.services.auth.main.logout()

      throw error
    } finally {
      this.gaApp.stores.auth.main.setIsTokenPending(false)
    }
  }

  /**
   * Методы возвращает статусы наличия токенов
   *
   * @returns {object} статусы наличия токенов
   */
  checkTokens() {
    return {
      hasAccessToken: this._accessToken().has(),
      hasRefreshToken: this._refreshToken().has(),
    }
  }

  /**
   * Выполняется сброс токенов
   */
  resetTokens() {
    this.gaApp.cookies.remove(ACCESS_TOKEN_KEY)
    this.gaApp.cookies.remove(HAS_REFRESH_TOKEN_KEY)
  }

  _refreshToken() {
    return {
      has: () => Boolean(this.gaApp.cookies.get(HAS_REFRESH_TOKEN_KEY)),
    }
  }

  _accessToken() {
    const accessToken = this.gaApp.cookies.get(ACCESS_TOKEN_KEY)
    const hasAccessTokenKey = this.gaApp.cookies.get(HAS_ACCESS_TOKEN_KEY)

    return {
      has: () => Boolean(accessToken) || Boolean(hasAccessTokenKey),
    }
  }
}
