import { GaIconAdditionalCrossTiny } from '@ga/icons'

import { GaButtonBase } from '../button-base'
import { GaIcon } from '../icon'

export const SIZE = {
  S: 's',
  M: 'm',
}

// @vue/component
export default {
  name: 'ga-clear-button',

  components: {
    GaButtonBase,
    GaIcon,
    GaIconAdditionalCrossTiny,
  },

  inheritAttrs: false,

  props: {
    size: {
      type: String,
      default: SIZE.M,
      validator: (value) => Object.values(SIZE).includes(value),
    },
  },
}
