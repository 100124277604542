<template>
  <svg fill="none" viewBox="0 0 19 20">
    <path
      fill="currentColor"
      d="m12.5 9.369-1.5-1.5V3.662l-2.087 2.12-1.06-1.06L12.5 0v9.369Z"
    />
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="m12.5 13.56 4.97 4.97 1.06-1.06-16-16-1.06 1.06 3.184 3.184H0v8.572h6.875L12.5 20v-6.44Zm-1.5-1.5v4.278l-3.497-3.552H1.5V7.214h4.654L11 12.061Z"
      clip-rule="evenodd"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-video-player-v2-muted',
}
</script>
