import { queryHighlight } from '@ga/utils'

import { BRANDS_SEARCH_ABORT_KEY } from '../constants'

export class SearchService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  buildBrandLabel({ start, size, label }) {
    const highlighted = label.substr(start, size)

    return queryHighlight(label, highlighted, {
      htmlTag: 'mark',
    })
  }

  buildBrand(option) {
    let label = option.label

    const reversedNameSelections = option.nameSelections.reverse() // нужно для того, чтобы правильно вставлять выделение жирным с конца

    reversedNameSelections.forEach((selection) => {
      const start = selection.offset
      const size = selection.length

      label = this.buildBrandLabel({ size, start, label })
    })

    return {
      ...option,
      label,
    }
  }

  getSearchResult(result) {
    return result.map((option) =>
      option.nameSelections?.length ? this.buildBrand(option) : option,
    )
  }

  searchBrands(params) {
    return this.gaApp.services.brands.api.searchBrands(params, {
      abortKey: BRANDS_SEARCH_ABORT_KEY,
    })
  }

  setSearchResults(data, { query }) {
    this.gaApp.stores.brands.search.searchResults = this.getSearchResult(
      data.brands,
    )
    this.gaApp.stores.brands.search.queryOriginal = query
  }

  reset() {
    this.gaApp.stores.brands.search.$reset()
  }
}
