import { useContext } from '@nuxtjs/composition-api'

import { computed, unref } from 'vue'

import { useCartAdd } from '~/modules/cart'

/**
 * Юс для управления количеством товара в корзине
 *
 * @param {Object} product - Объект продукта
 * @param {boolean} cancelDefaultUpdateQty - Флаг отмены стандартного обновления количества
 * @param {Function} emit - Функция для эмита событий
 *
 * @returns {Object} Объект с методами и состоянием
 */
export const useBehavior = (product, cancelDefaultUpdateQty, emit) => {
  const { $gaApp } = useContext()

  const { isLoading: loading, updateQty } = useCartAdd(unref(product))

  const cartProduct = computed(() =>
    $gaApp.services.cart.main.getProductItem(unref(product)?.itemId),
  )

  const quantity = computed(() => unref(cartProduct)?.qty)

  /**
   * Обновляет количество товара
   * @param {number} qty - Новое количество
   */
  const handleQuantityUpdate = (qty) => {
    emit('update-qty', { qty })

    return unref(cancelDefaultUpdateQty) ? false : updateQty({ qty })
  }

  const onCartIncrease = () => handleQuantityUpdate(quantity.value + 1)
  const onCartDecrease = () => handleQuantityUpdate(quantity.value - 1)
  const onCartClick = () => emit('click')

  return {
    loading,
    quantity,

    onCartClick,
    onCartIncrease,
    onCartDecrease,
  }
}
