import axios from 'axios'

export const callbackResponseDefault = (eventBus) => ({
  success(data) {
    eventBus.publish('end', {
      data,
    })

    return data
  },

  error(error) {
    console.error(error)

    const isApiError = !axios.isCancel(error)

    if (isApiError) {
      eventBus.publish('error', {
        error,
      })
    }

    return Promise.reject(error)
  },
})
