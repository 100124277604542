import { REACTIONS_RECEIVE_EXCEPTION_REDIRECT_PATH } from '../constants/reactions'

export class ApiService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  /**
   * Получение данных страницы главной страницы ПК
   */
  async getDataMainPage() {
    const { data } =
      await this.gaApp.repositories.giftCards.main.getDataMainPage()
    return data.giftCardOnboarding
  }

  /**
   * Получение данных для меню
   */
  async getDataMenu() {
    const { data } = await this.gaApp.repositories.giftCards.main.getDataMenu()
    return data
  }

  /**
   * Получение данных страницы "о картах"
   */
  async getDataAboutPage() {
    const { data } =
      await this.gaApp.repositories.giftCards.main.getDataAboutPage()
    return data.giftCardAboutCards
  }

  /**
   * Получение данных для страницы получения ЭПК
   * @param {object} redirect данные редиректа page.service
   * @param {string} redirect.id идентификатор ЭПК
   */
  async getDataReceivePage({ id }) {
    if (!id) {
      throw new Error('There is no cardId in parameters')
    }

    const source = this.gaApp.services.giftCards.digitalReceive.getSourceLink()

    const { data } =
      await this.gaApp.repositories.giftCards.main.getReceiveCard(id, source)

    this.gaApp.services.giftCards.digitalReceive.setReceivedCard({
      ...data,
      cardNumberHash: id,
    })
  }

  /**
   * Получение данных для страницы получения ВЭПК
   * @param {object} redirect данные редиректа page.service
   * @param {string} redirect.id идентификатор ВЭПК
   */
  async getDataReceiveReturnPage({ id }) {
    if (!id) {
      throw new Error('There is no cardId in parameters')
    }

    const { data } =
      await this.gaApp.repositories.giftCards.main.getReceiveReturnCard(id)

    return data
  }

  /**
   * Запускает процесс оплаты банковской картой
   * @param {object} submitData данные ЭПК для оформления заказа
   */
  async paymentOrderCard(submitData) {
    const captchaToken = await this.gaApp.services.captcha.main.generateToken()

    if (captchaToken) {
      submitData.captchaToken = captchaToken
    }

    const response =
      await this.gaApp.repositories.giftCards.payment.orderCard(submitData)

    this.gaApp.stores.giftCards.payment.orderId = response.data.orderId

    return response
  }

  /**
   * Получение scriptUrl для оплаты мастеркард
   * @param {string} sessionId идентификатор оплаты
   */
  async orderMasterCardInfo(sessionId) {
    return await this.gaApp.repositories.giftCards.payment.orderMasterCardInfo(
      sessionId,
    )
  }

  /**
   * Запускает процесс оплаты через СБП
   * @param {object} submitData данные ЭПК для оформления заказа
   */
  async paymentOrderSbp(submitData) {
    const captchaToken = await this.gaApp.services.captcha.main.generateToken()

    if (captchaToken) {
      submitData.captchaToken = captchaToken
    }

    const { data } =
      await this.gaApp.repositories.giftCards.payment.orderSbp(submitData)

    const { sbpMembers, paymentId, paymentUrl, orderId } = data

    if (!paymentUrl) {
      throw new Error('There is no paymentURL in response')
    }

    this.gaApp.stores.giftCards.payment.paymentId = paymentId
    this.gaApp.stores.giftCards.payment.orderId = orderId
    this.gaApp.stores.giftCards.payment.sbp.banks = sbpMembers || []
    this.gaApp.stores.giftCards.payment.sbp.paymentUrl = paymentUrl
  }

  /**
   * Проверка статуса заказа
   * @param {string} paymentId идентификатор оплаты
   */
  async checkOrderStatus(paymentId) {
    return await this.gaApp.repositories.giftCards.payment.checkOrderStatus(
      paymentId,
    )
  }

  /**
   * Получение конфигурации для оформления ЭПК
   * @param {object} parameters параметры конфигурации
   */
  async getConfig(parameters = {}) {
    const { data } =
      await this.gaApp.repositories.giftCards.main.getConfig(parameters)

    return this.gaApp.services.giftCards.dto.config.getConfigDto(data)
  }

  /**
   * Получение конфигурации реакций
   */
  async getReactionsConfig() {
    try {
      const { data } =
        await this.gaApp.repositories.giftCards.reactions.getReactionsConfig()

      this.gaApp.stores.giftCards.reactions.items = data?.reactions || []
      this.gaApp.stores.giftCards.reactions.timer.duration =
        data?.timerInMilliseconds
    } catch (error) {}
  }

  /**
   * Отправка реакции получателя
   * @param {string} imageId идентификатор изображения реакции
   * @param {string} emojiId идентификатор реакции
   * @param {string} cardHash идентификатор подарочной карты
   */
  async sendReaction(imageId, emojiId, cardHash) {
    Object.entries({
      imageId,
      emojiId,
      cardHash,
    }).forEach(([parameterName, parameterValue]) => {
      if (!parameterValue) {
        throw new Error(`There is no ${parameterName} for reaction send`)
      }
    })

    return await this.gaApp.repositories.giftCards.reactions.sendReaction({
      imageId,
      emojiId,
      cardHash,
    })
  }

  /**
   * Получение остатка на подарочной карте
   * @param {string} cardNumberHash идентификатор подарочной карты
   */
  async getDenomination(cardNumberHash) {
    try {
      if (!cardNumberHash) {
        throw new Error('There is no card hash for denomination')
      }

      const { data } =
        await this.gaApp.repositories.giftCards.main.getDenomination(
          cardNumberHash,
        )

      this.gaApp.stores.giftCards.balance.$patch(data)
    } catch (error) {
      this.gaApp.stores.giftCards.balance.isError = true
    }
  }

  /**
   * Получение данных реакции для отображения отправителю
   * @param {object} redirect данные редиректа page.service
   * @param {string} [redirect.reactionId] идентификатор получения реакции
   */
  async receiveReaction({ reactionId }) {
    if (!this.gaApp.features.get('giftCardsReactions')) {
      this.gaApp.redirect(REACTIONS_RECEIVE_EXCEPTION_REDIRECT_PATH)
    }

    if (!reactionId) {
      throw new Error('There is no reaction id for reaction receive')
    }

    const { data } =
      await this.gaApp.repositories.giftCards.reactions.receiveReaction(
        reactionId,
      )

    if (data.isExpired) {
      this.gaApp.redirect(REACTIONS_RECEIVE_EXCEPTION_REDIRECT_PATH)
    }

    this.gaApp.stores.giftCards.reactions.receive.image = data.reaction
    this.gaApp.stores.giftCards.reactions.receive.recipientName =
      data.recipientName?.trim() ||
      this.gaApp.i18n.t('giftCards.reactions.receive.defaultRecipientName')
    this.gaApp.stores.giftCards.reactions.receive.isImageLoaded = false
    this.gaApp.stores.giftCards.reactions.receive.isExpired = data.isExpired
  }

  /**
   * Получение сохраненных карт для текущего пользователя
   *
   * Если пользователь неавторизован, то массив сохраненных карт будет пустым
   */
  async getPaymentSavedCards() {
    const { data } =
      await this.gaApp.repositories.giftCards.payment.getSavedCards()

    const { saveable, saved, defaultPaymentMethod } = data

    this.gaApp.stores.giftCards.payment.card.saved = saved
    this.gaApp.stores.giftCards.payment.card.saveable = saveable
    this.gaApp.stores.giftCards.payment.defaultPaymentMethod =
      defaultPaymentMethod
  }

  /**
   * Получение конфигурации для оплаты
   *
   * Если пользователь неавторизован, то массив сохраненных карт будет пустым
   */
  async getPaymentConfig() {
    const { data } =
      await this.gaApp.repositories.giftCards.payment.getPaymentConfig()

    const {
      saveable,
      saved,
      defaultPaymentMethod,
      availablePaymentMethods,
      merchantId,
      merchantCapabilities,
      supportedNetworks,
    } = data

    return {
      saveable,
      saved,
      defaultPaymentMethod,
      availablePaymentMethods,
      merchantId,
      merchantCapabilities,
      supportedNetworks,
    }
  }

  /**
   * Удаление сохраненной карты у текущего пользователя
   * @param {string} cardId идентификатор сохраненной карты
   */
  async removePaymentSavedCard(cardId) {
    if (!cardId) {
      throw new Error('There is no card id for config')
    }

    const { data } =
      await this.gaApp.repositories.giftCards.payment.removeSavedCard(cardId)

    if (!data.success) {
      throw new Error('Response is not ok')
    }
  }

  /**
   * Запускает генерацию изображение через нейросеть
   * @param {string} prompt содержание запроса для нейросети
   */
  async startAiGeneration(prompt) {
    if (!prompt) {
      throw new Error('Нет запроса для генерации')
    }

    const captchaToken = await this.gaApp.services.captcha.main.generateToken()

    const { data } =
      await this.gaApp.repositories.giftCards.digitalAiDesign.startGeneration({
        prompt,
        captchaToken,
      })

    const { yandexImageId } = data

    if (!yandexImageId) {
      throw new Error('Нет идентификатора генерации в ответе')
    }

    return yandexImageId
  }

  /**
   * Получение изображения по ранее начатой генерации
   * @param {string} yandexImageId идентификатор генерации нейросети
   */
  async getAiImage(yandexImageId) {
    if (!yandexImageId) {
      throw new Error('There is no generationId for ai image get')
    }

    const { data } =
      await this.gaApp.repositories.giftCards.digitalAiDesign.getImage(
        yandexImageId,
      )

    return {
      imgUrl: data.url,
      id: data.mediaImageId,
    }
  }

  /**
   * Получает данные конфигурации генерации дизайна через нейросеть
   */
  async getAiConfig() {
    const { data } =
      await this.gaApp.repositories.giftCards.digitalAiDesign.getConfig()

    return {
      limit: data.generationsLimit,
      limitMessage: data.generationsLimitMessage,
    }
  }
}
