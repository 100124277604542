import { ApiService } from './api.service'
import { CardsService } from './cards.service'
import { ConfirmService } from './confirm.service'
import { DialogService } from './dialog.service'
import { EditService } from './edit.service'
import { MainService } from './main.service'
import { MenuService } from './menu.service'
import { NotificationModalService } from './notification-modal.service'
import { OrderService } from './order.service'
import { OrdersService } from './orders.service'
import { PageService } from './page.service'
import { SettingsService } from './settings.service'

export const services = (gaApp) => ({
  api: new ApiService(gaApp),
  confirm: new ConfirmService(gaApp),
  dialog: new DialogService(gaApp),
  main: new MainService(gaApp),
  menu: new MenuService(gaApp),
  order: new OrderService(gaApp),
  orders: new OrdersService(gaApp),
  page: new PageService(gaApp),
  settings: new SettingsService(gaApp),
  edit: new EditService(gaApp),
  cards: new CardsService(gaApp),
  notificationModal: new NotificationModalService(gaApp),
})
