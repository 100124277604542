import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'

export class GtmGa4OrderSendClickModel extends GtmBaseModel {
  constructor({ type, amount }) {
    super({})

    this.action_type = 'click_order_send'
    this.type = type
    this.amount = amount
  }
}
