import { ANALYTICS_EVENT_TYPE } from '../../../../constants'
import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'

export class GtmGa4ChangeDesignPlasticClickModel extends GtmBaseModel {
  constructor({ id }) {
    super({})

    this.action_type = 'click_change_design'
    this.type = ANALYTICS_EVENT_TYPE.KPPK
    this.design_id = id
  }
}
