import { TYPE } from '../../constants'
import {
  GaAnalyticsArticleAllProductsButtonClickModel,
  GtmGa4ArticleAllProductsButtonClickModel,
} from '../../models'
import { BaseBuilder } from '../base.builder/base.builder'

export class ArticleAllProductsButtonClickBuilder extends BaseBuilder {
  constructor(data) {
    const models = new Map([
      [TYPE.GTM_4, GtmGa4ArticleAllProductsButtonClickModel],
      [TYPE.GAA, GaAnalyticsArticleAllProductsButtonClickModel],
    ])
    super(models, data)
  }
}
