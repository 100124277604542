<template>
  <svg fill="currentColor" viewBox="0 0 40 40">
    <path
      fill="currentColor"
      d="M9.2 12.258C5.005 18.25 6.45 26.493 12.418 30.673c4.273 2.992 9.71 3.093 13.996.71l.73 1.31c-4.768 2.652-10.824 2.543-15.586-.791-6.651-4.658-8.254-13.838-3.587-20.504 4.668-6.667 13.843-8.3 20.494-3.643a14.646 14.646 0 0 1 6.157 10.31 14.78 14.78 0 0 1 .045 3.04l3.424-3.424 1.061 1.06-4.95 4.95-.018.065-.036-.01-.781.78-5.63-5.63 1.06-1.06 4.232 4.232a13.26 13.26 0 0 0 .103-3.827 13.146 13.146 0 0 0-5.527-9.257c-5.97-4.18-14.21-2.717-18.405 3.274Z"
    />
    <path
      fill="currentColor"
      d="M19.716 24.55c-2.175 0-3.198-1.19-3.302-2.783h1.606c.142.867.492 1.45 1.683 1.45 1.075 0 1.67-.738 1.67-1.878 0-1.126-.634-1.826-1.67-1.826-.945 0-1.515.324-1.93.803h-.88l.078-5.154h5.62v1.36h-4.3l-.012 2.512c.363-.414 1.01-.777 1.994-.777 1.67 0 2.85 1.179 2.85 3.108 0 1.969-1.412 3.186-3.407 3.186Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-video-player-v2-forward-animated',
}
</script>
