import {
  COOKIE_SELECTED_ADDRESS,
  STORAGE_SELECTED_ADDRESS_KEY,
} from '../constants/confirm'

/**
 * Кеш сервис модуля локации
 * работает с выбранным адресом
 */
export class CacheService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  /**
   * Сохраняет выбранный адрес для гостя
   * @param selectedAddress
   */
  saveGuestSelectedAddress(selectedAddress) {
    if (!this.gaApp.services.user.main.isAuthorized) {
      this.setSelectedAddress(selectedAddress)
    }
  }

  /**
   * Удаляет временный адрес для гостя
   */
  removeGuestSelectedAddress() {
    this.gaApp.services.app.browserStorage.local.remove(
      STORAGE_SELECTED_ADDRESS_KEY,
    )

    this.removeSelectedAddressCookies()
  }

  /**
   * Модифицирует адрес перед сохранением
   * @param {object} selectedAddress - выбранный адрес
   */
  prepareSelectedAddress(selectedAddress) {
    const lang = selectedAddress.lang || this.gaApp.i18n.locale.code

    return { ...selectedAddress, lang }
  }

  /**
   * Записывает выбранный адрес в локальное хранилище
   * @param {object} selectedAddress - выбранный адрес
   */
  setSelectedAddress(selectedAddress) {
    if (!selectedAddress?.city || !selectedAddress?.id) {
      const error = new Error(
        'Ошибка сохранения выбранного адреса в локальное хранилище',
      )
      error.data = JSON.stringify(selectedAddress || {})

      this.gaApp.services.app.apm.captureError(error)

      return
    }

    const preparedSelectedAddress = this.prepareSelectedAddress(selectedAddress)

    this.gaApp.services.app.browserStorage.local.set(
      STORAGE_SELECTED_ADDRESS_KEY,
      preparedSelectedAddress,
    )

    this.updateSelectedAddressCookies(preparedSelectedAddress)
  }

  /**
   * Отдает выбранные адрес с локального хранилища
   */
  getSelectedAddress() {
    return this.gaApp.services.app.browserStorage.local.get(
      STORAGE_SELECTED_ADDRESS_KEY,
    )
  }

  /**
   * Сохраняет фиас локации в куку
   * @param {string} addressId - фиас локации
   */
  saveSelectedAddressId(addressId) {
    this.gaApp.cookies.set(COOKIE_SELECTED_ADDRESS.ID, addressId)
  }

  /**
   * Удаляет фиас локации с куки
   */
  removeSelectedAddressId() {
    this.gaApp.cookies.remove(COOKIE_SELECTED_ADDRESS.ID)
  }

  /**
   * Сохраняет дистрикт локации в куку
   * @param {string} cityDistrict - дистрикт локации
   */
  saveSelectedAddressCityDistrict(cityDistrict) {
    this.gaApp.cookies.set(COOKIE_SELECTED_ADDRESS.CITY_DISTRICT, cityDistrict)
  }

  /**
   * Удаляет дистрикт локации с куки
   */
  removeSelectedAddressCityDistrict() {
    this.gaApp.cookies.remove(COOKIE_SELECTED_ADDRESS.CITY_DISTRICT)
  }

  /**
   * Если ФТ geoPolygonDeliveryAreas включен - сохраняем геополигоны локации в куку
   * @param {array} geoPolygons - геополигоны локации
   */
  saveSelectedAddressGeoPolygons(geoPolygons) {
    if (this.gaApp.features.get('geoPolygonDeliveryAreas')) {
      this.gaApp.cookies.set(COOKIE_SELECTED_ADDRESS.GEO_POLYGONS, geoPolygons)
    }
  }

  /**
   * Удаляет геополигоны локации с куки
   */
  removeSelectedAddressGeoPolygons() {
    this.gaApp.cookies.remove(COOKIE_SELECTED_ADDRESS.GEO_POLYGONS)
  }

  /**
   * Сетим в куки инфу по выбранному адресу
   * В дефолтном городе не будет cityDistrict, geoPolygons, в этом случае очищаем соответствующие куки
   *
   * @param {object} obj
   * @param {string} obj.addressId - фиас локации
   * @param {array | null | undefined} obj.geoPolygons - геополигоны локации
   * @param {string | undefined} obj.cityDistrict - район локации
   */
  updateSelectedAddressCookies({ addressId, geoPolygons, cityDistrict }) {
    this.removeSelectedAddressCookies()

    this.saveSelectedAddressId(addressId)

    if (geoPolygons && geoPolygons.length) {
      this.saveSelectedAddressGeoPolygons(geoPolygons)
    }

    if (cityDistrict) {
      this.saveSelectedAddressCityDistrict(cityDistrict)
    }
  }

  /**
   * Очищаем инфу по выбранному адресу из кук
   */
  removeSelectedAddressCookies() {
    this.removeSelectedAddressId()
    this.removeSelectedAddressGeoPolygons()
    this.removeSelectedAddressCityDistrict()
  }
}
