import { useContext } from '@nuxtjs/composition-api'

import { computed, unref } from 'vue'

/**
 * Юс для управления отображением счетчиком корзины
 *
 * @param {Object} product - Объект продукта
 */
export const useCartCounter = (product) => {
  const { $gaApp } = useContext()

  /**
   * Определяет, нужно ли показывать счетчик в корзине
   *
   * @returns {boolean} true - если включена фича plpCounterAddingToCart и товар без вариантов, простой.
   */
  const cartWithCounter = computed(() => {
    return (
      $gaApp.features.get('plpCounterAddingToCart') &&
      !unref(product)?.configurable
    )
  })

  return {
    cartWithCounter,
  }
}
