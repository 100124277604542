export class ApiStreamsService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  // Листинг
  async fetchStreamsListing(params) {
    try {
      let response =
        await this.gaApp.repositories.articles.streams.fetchListing(params)

      const noDataOnPage =
        params.pagination.pageNumber !== 0 && !response.data.streams.length

      // Если для текущей страницы нет данных - грузим первую страницу
      if (noDataOnPage) {
        response = await this.fetchFirstPageForce(params)
      }

      return {
        ...response.data,
        items: response.data.streams,
      }
    } catch {
      return this.getEmpty()
    }
  }

  async fetchStreamsListingPreview(id) {
    try {
      const response =
        await this.gaApp.repositories.articles.streams.fetchListingPreview(id)
      return response.data
    } catch {
      return this.getEmpty()
    }
  }

  async fetchStreamsFilterListing(params) {
    try {
      const response =
        await this.gaApp.repositories.articles.streams.fetchFilterListing(
          params,
        )
      return response.data
    } catch {
      return this.getEmpty()
    }
  }

  // Статусы
  async fetchStreamsStatuses() {
    try {
      const response =
        await this.gaApp.repositories.articles.streams.fetchStatuses()
      return response.data
    } catch (error) {
      return { statuses: [] }
    }
  }

  // Инфо
  fetchStreamInfoById(id) {
    return this.gaApp.repositories.articles.streams.fetchStreamInfoById(id)
  }

  fetchStreamInfoByUrl(url) {
    return this.gaApp.repositories.articles.streams.fetchStreamInfoByUrl(url)
  }

  // Фильтры
  async fetchFilters() {
    try {
      const response =
        await this.gaApp.repositories.articles.streams.fetchListingFilters()

      return response.data
    } catch {
      return { filters: [] }
    }
  }

  // Просмотры
  async fetchStreamViewed({ captchaToken, id }) {
    try {
      await this.gaApp.repositories.articles.streams.fetchViewed({
        captchaToken,
        id,
      })
    } catch (e) {
      console.error(e.message)
    }
  }

  async fetchStreamViewedWebview({ id }) {
    try {
      await this.gaApp.repositories.articles.streams.fetchViewedWebview({ id })
    } catch (e) {
      console.error(e.message)
    }
  }

  // Грузим данные для первой страницы
  fetchFirstPageForce(params) {
    this.gaApp.services.articles.streams.setCurrentPageNumber(0)

    return this.gaApp.repositories.articles.streams.fetchListing({
      ...params,
      pagination: {
        pageNumber: 0,
        pageSize: params.pagination.pageSize,
      },
    })
  }

  // Пустой объект
  getEmpty() {
    return {
      currentPage: 0,
      totalCount: 0,
      articles: [],
    }
  }
}
