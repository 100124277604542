import { computed, watch } from 'vue'

import {
  phone as phoneValidation,
  phoneLocale,
  required,
  useValidation,
} from '@ga/validation'

/**
 * Формирует валидацию формы
 *
 * @param {Object} $gaApp - контекст приложения
 * @param {Ref<string>} phone - значение phoneInternal из формы
 * @param {Ref<Object>} phoneMetadata - обьект с мета информацией телефона
 * @param {Ref<Object>} error - объект ошибки сервера
 */
export function useValidate($gaApp, phone, phoneMetadata, error) {
  const rules = computed(() => {
    return {
      phone: {
        phoneLocale: phoneLocale(
          $gaApp.i18n.locale,
          $gaApp.i18n.t('auth.validations.incorrectPhoneNumber'),
        ),
        phone: phoneValidation(phoneMetadata.value),
        required: required(),
      },
    }
  })

  const { hasErrors, getError, setError, resetErrors, validate, v$ } =
    useValidation(rules, { phone })

  watch(phone, () => {
    // Если юзер вводит номер другой страны,
    // То показываем сообщение немедленно
    if (!v$.value.phone.phoneLocale) {
      validate()
    }
  })

  watch(error, (value) => {
    const message = value?.invalidParameters?.phone_number.localizedMessage
    message && setError('phone', message)
  })

  return {
    hasErrors,
    getError,
    setError,
    resetErrors,
    validate,
  }
}
