import {
  ADULT_MODAL_REASON_TO_OPEN,
  AUTH_MODAL_REASON_TO_OPEN,
  CART_MODAL_REASON_TO_OPEN,
  MODAL_NAME,
  TYPE,
} from '../constants'

export class MainService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  // закрываем модалку по id, если id не передано, то закрываем последнюю открытую
  close(id) {
    this.gaApp.services.modal.action.close(id)
  }

  // закрываем открытый 2-ой и более компонент внутри открытой модалки
  goBack(id) {
    this.gaApp.services.modal.action.goBack(id)
  }

  // закрываем все открытые модалки
  closeAll() {
    this.gaApp.services.modal.action.closeAll()
  }

  // закрываем все открытые модалки без анимации закрытия
  hardCloseAll() {
    this.gaApp.services.modal.action.hardCloseAll()
  }

  // открываем модалку авторизации
  openAuthModal(type) {
    const types = {
      products: AUTH_MODAL_REASON_TO_OPEN.ADD_PRODUCT_TO_FAVORITES,
      brands: AUTH_MODAL_REASON_TO_OPEN.ADD_BRAND_TO_FAVORITES,
    }

    const payload = { push: this.gaApp.mq('tablet+'), reason: types[type] }

    this.openSuperModalAuth(payload)
  }

  // открываем модалку с быстрого просмотра продукта
  openQuickPreviewProductModal(productId) {
    const payload = { push: this.gaApp.mq('tablet+'), productId }

    this.openSuperModalQuickPreview(payload)
  }

  // открываем модалку с подверждением возраста
  openAdultModal() {
    if (this.gaApp.stores.modal.main.hasOpenedAdultModal) {
      return
    }

    const payload = { push: this.gaApp.mq('tablet+') }

    this.openSuperModalAdult(payload)
  }

  // открываем модалку с подверждением возраста
  openSuperModalAdult({
    push,
    reason = ADULT_MODAL_REASON_TO_OPEN.PRODUCT,
    callbacks = {},
  } = {}) {
    const name = MODAL_NAME.ADULT
    const data = { reason, callbacks }
    const isMobileView = this.gaApp.mq('tablet-')
    const type = isMobileView ? TYPE.SWIPE : TYPE.BASE

    this.gaApp.services.modal.action.open({
      id: MODAL_NAME.ADULT,
      name,
      data,
      push,
      type,
    })

    this.gaApp.stores.user.main.isAdultWasAsked = true

    this.gaApp.eventBus.publish('module/modal/open/adult', data)
  }

  // открываем модалку с авторизацией
  openSuperModalAuth({
    push,
    redirect = '',
    reason = AUTH_MODAL_REASON_TO_OPEN.REGULAR_LOGIN,
    type,
    callbacks = {},
  } = {}) {
    if (this.gaApp.stores.modal.main.hasOpenedAuthModal) {
      return
    }

    const name = MODAL_NAME.AUTH
    const data = { redirect, reason, callbacks }

    this.gaApp.services.modal.action.open({
      id: MODAL_NAME.AUTH,
      name,
      data,
      push,
      type,
    })
  }

  // открываем модалку с корзиной
  openSuperModalCart({
    push,
    reason = CART_MODAL_REASON_TO_OPEN.REGULAR,
    type,
  } = {}) {
    if (this.gaApp.stores.modal.main.hasOpenedCartModal) {
      return
    }

    const name = MODAL_NAME.CART
    const data = { reason }

    this.gaApp.services.modal.action.open({
      name,
      data,
      push,
      type,
    })
  }

  // открываем модалку с подпиской на продукт не в наличии
  openSuperModalProductSubscription({
    push,
    productId,
    associatedProductId,
    type,
  } = {}) {
    const name = MODAL_NAME.PRODUCT_SUBSCRIPTION
    const data = { productId, associatedProductId }

    this.gaApp.services.modal.action.open({
      name,
      data,
      push,
      type,
    })
  }

  // открываем модалку с информацией о частичной оплате
  openSuperModalPartsPayment({ data, type, push } = {}) {
    const name = MODAL_NAME.PARTS_PAYMENT

    this.gaApp.services.modal.action.open({
      name,
      data,
      push,
      type,
    })
  }

  // открываем модалку с информацией о доставке
  openSuperModalDelivery({ push, data, type } = {}) {
    const name = MODAL_NAME.DELIVERY

    this.gaApp.services.modal.action.open({
      name,
      data,
      push,
      type,
    })
  }

  // открываем модалку быстрого просмотра карточки продукта
  openSuperModalQuickPreview({ push, productId } = {}) {
    const name = MODAL_NAME.QUICK_PREVIEW
    const data = { productId }
    const isMobileView = this.gaApp.mq('tablet-')
    const type = isMobileView ? TYPE.SWIPE : TYPE.BASE

    this.gaApp.services.modal.action.open({
      name,
      data,
      push,
      type,
    })
  }

  checkRouter(to, from) {
    if (to.path !== from.path) {
      this.hardCloseAll()
    }

    this.gaApp.services.modal.hash.check(to.hash)
  }
}
