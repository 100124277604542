<template>
  <svg fill="none" stroke="none" viewBox="0 0 15 15">
    <path stroke="#000" stroke-width="1.25" d="m5 13 5.5-5.5L5 2" />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-common-15-arrow-polygon-medium',
}
</script>
