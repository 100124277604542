import merge from 'lodash/merge'

import { unref } from 'vue'

import { useCartAdd } from '~/modules/cart'

export const useProductCardCart = (product) => {
  const {
    addToCart: _addToCart,
    updateQty: _updateQty,
    isLoading,
    isAdded,
    isWholeStock,
  } = useCartAdd(unref(product))

  const addToCart = (...args) => _addToCart(merge(...args))

  const updateQty = (...args) => {
    return _updateQty(merge(...args))
  }

  return {
    addToCart,
    updateQty,

    isLoading,
    isAdded,
    isWholeStock,
  }
}
