import { doubleRAF } from '@ga/shared-browser'

import { EDITOR_BLOCK_TYPES } from '../constants/editor'
import { sectionChunks } from '../utils'
import { buildBannerApiType } from '../utils/banner'

export class OneService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  formatArticleData(data) {
    return {
      ...data,
      recommendations: this.mapRecommendationsArticles(data.recommendations),
      rawDate: data.date,
      date: this.formatDateForView(data.date, 'D MMMM YYYY'),
      sections: data.sections?.map((section) => ({
        ...section,
        chunks: sectionChunks(section),
      })),
    }
  }

  formatDateForView(date, format) {
    return this.gaApp.libs.dateWrapper(date).format(format)
  }

  mapRecommendationsArticles(articles) {
    return articles.map((item) => ({
      image: item.imageUrls,
      date: item.publishedDate
        ? this.formatDateForView(item.publishedDate, 'D MMMM YYYY')
        : '',
      subtitle: item.subTitle,
      ...item,
    }))
  }

  async prepareArticlePage(data) {
    await this.gaApp.stores.articles.one.$patch(this.formatArticleData(data))
    await this.scrollToTop()
    this.gaApp.stores.articles.one.isLoadedArticle = true
  }

  async fetchPreviewArticle(id) {
    if (this.gaApp.isServer) {
      this.gaApp.redirectError({ statusCode: 500 })
    }

    this.gaApp.stores.articles.one.isPreview = true
    const { data } = await this.gaApp.services.articles.api.fetchArticle(id)
    await this.prepareArticlePage(data)
  }

  async fetchArticleByUrl(url) {
    this.gaApp.stores.articles.one.isPreview = false

    const { data } =
      await this.gaApp.services.articles.api.fetchArticleByUrl(url)
    await this.prepareArticlePage(data)

    if (this.gaApp.features.get('flaconPromo')) {
      await this.loadBanners()
    }
  }

  async fetchProductsBySku(skuArray = []) {
    const productsRaw =
      await this.gaApp.services.articles.api.fetchProductsBySku(skuArray)

    const productsFormattedForModuleProductCard = productsRaw.map(
      (product) => ({
        name: product.name,
        brand: product.brand,
        itemId: product.itemId,
        type: product.productType,
        url: product.url,
        inStock: product.inStock,
        reviews: product.reviews ? product.reviews : false,
        media: (() => {
          const media = product.imageUrls.map((image) => ({
            image,
            meta: false,
          }))

          if (!this.gaApp.features.get('articlesHowToShortVideoOn')) {
            return media
          }

          if (product.shortVideos) {
            const inStock = product.inStock

            product.shortVideos.forEach((video) => {
              // если товар не в наличии, выводим только превью-изображение
              const source = inStock
                ? { video, priority: true }
                : {
                    image: video.videoThumbnail,
                    meta:
                      this.gaApp.services.plp.seo.getImageAttributes(product) ??
                      false,
                  }

              media.splice(video.position || 0, 0, source)
            })
          }

          return media
        })(),
        favourite: product.favourite,
        configurable: product.configurable,
        needOnlineConsultation: product.needOnlineConsultation,
        isAdult: product.isAdult,
        prices: product.price
          ? {
              actual: product.price.actual,
              old: product.price.old ? product.price.old : undefined,
              prefix:
                product.price.viewOptions.priceFrom && product.price.hasLoyalty,
              oldStrikethrough: product.price.viewOptions.crossPrice,
            }
          : false,
        attributes: product.attributes
          ? {
              units: {
                name: product.attributes.units.name,
                values: product.attributes.units.values,
                count: product.attributes.units.count,
                currentUnitValue: product.attributes.units.currentUnitValue,
              },
              colors: {
                values: product.attributes.colors.values,
                count: product.attributes.colors.count,
              },
            }
          : false,
        dolyame: product.dolyame ? product.dolyame : false,
        labels: product.labels ? product.labels : false,
        disclaimer: product.disclaimer ? product.disclaimer : false,
      }),
    )

    return {
      productsRaw,
      productsFormattedForModuleProductCard,
    }
  }

  async fetchProductsByUrl(url, pageNumber = 0) {
    const { products, count } =
      await this.gaApp.services.articles.api.fetchProductsByUrl(url, pageNumber)

    return {
      productsRaw: products,
      count,
    }
  }

  async fetchArticleViewed() {
    if (
      this.gaApp.stores.articles.one.isPreview ||
      !this.gaApp.stores.articles.one.id
    ) {
      return
    }

    const captchaToken = !this.gaApp.isWebview
      ? await this.gaApp.services.captcha.main.generateToken()
      : null

    const id = this.gaApp.stores.articles.one.id

    await this.gaApp.services.articles.api.fetchArticleViewed({
      captchaToken,
      id,
    })
  }

  // перед загрузкой новых данных - поднимаем страницу наверх чтобы не было скачка контента
  scrollToTop() {
    // Должен срабатывать только при переходах между статьями
    // Так как очистка хранилища происходит после ухода со страницы статьи
    // и только в случае если мы перейдем на другой тип страницы - то тут
    // будет храниться информация о том что страница статьи была загружена ранее
    if (!this.gaApp.stores.articles.one.isLoadedArticle) {
      return
    }

    doubleRAF().then(() => {
      window?.scrollTo?.({ top: 0 })
    })
  }

  getBanners() {
    const banners = []

    this.gaApp.stores.articles.one.sections.forEach((section) => {
      section.chunks.forEach((chunk) => {
        chunk.blocks.forEach((block) => {
          if (block.type === EDITOR_BLOCK_TYPES.BANNER) {
            banners.push(block)
          }
        })
      })
    })

    return banners
  }

  async loadBanners() {
    const banners = this.getBanners()

    if (!banners.length) {
      return
    }

    const customerId = this.gaApp.stores.user.main?.data?.id
    const deviceId = this.gaApp.stores.app.main?.deviceId

    const apiBanners =
      await this.gaApp.services.articles.advBanners.loadBanners({
        userId: customerId || deviceId,
        articleId: this.gaApp.stores.articles.one.id,
        banners: banners.map((banner) => ({
          bannerType: buildBannerApiType(banner.data.type),
          blockId: banner.id,
        })),
      })

    if (!apiBanners || !apiBanners.length) {
      return
    }

    // Заполняем баннер данными
    banners.forEach((banner) => {
      // находим баннер из запроса и расширяем текущий баннер
      const apiBanner = apiBanners.find((item) => item.blockId === banner.id)

      if (!apiBanner) {
        return
      }

      banner.data = {
        ...banner.data,
        ...apiBanner,
      }
    })
  }

  reset() {
    this.gaApp.stores.articles.one.$reset()
  }
}
