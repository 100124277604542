export const getters = () => ({
  isEmpty() {
    const { queryOriginal, resultFirst, resultSecond } = this

    return Boolean(
      queryOriginal && !resultFirst?.length && !resultSecond?.length,
    )
  },

  isSearching() {
    return Boolean(this.queryOriginal)
  },

  searchableBrands() {
    return this.brands.map((x) => {
      return { label: x }
    })
  },
})
