export class PageService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  getData(redirect) {
    const categoryId = this.gaApp.i18n.t('dolyame.products.categoryId')

    try {
      switch (redirect.pageType) {
        case 'main':
        case 'appMain':
          if (this.gaApp.features.get('bnplHideProductList')) return

          return this.gaApp.services.dolyame.api.fetchProductsByPage({
            categoryId,
          })
        default:
          return undefined
      }
    } catch (error) {
      // здесь только запрос товаров для одного блока
      // не обрабатываем ошибку, т.к. блок просто скроется при отсутствии товаров
    }
  }
}
