import { computed, toRefs, unref } from 'vue'

import { GaIconAdditionalCartAdd } from '@ga/icons'

import { GaButtonIconOnly } from '@ga/ui-components/button-icon-only'

import { SIZE } from './scripts/consts'

// @vue/component
export default {
  name: 'ga-product-card-cart-button',

  components: {
    GaIconAdditionalCartAdd,
    GaButtonIconOnly,
  },

  props: {
    size: {
      type: String,
      default: SIZE.S,
      validator: (value) => Object.values(SIZE).includes(value),
    },
    loading: {
      type: Boolean,
      default: false,
    },
    testId: {
      type: String,
      default: '',
    },
  },

  emits: ['click'],

  setup(props) {
    const { size } = toRefs(props)

    const buttonSize = computed(() => {
      return { s: 's', m: 'l' }[unref(size)]
    })

    return {
      buttonSize,
    }
  },
}
