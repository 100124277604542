import { useContext } from '@nuxtjs/composition-api'

import { computed, unref } from 'vue'

import { STOCK_STATUS } from '../constants'

/**
 * @typedef {Object} ProductForStockStatusCheck
 * @property {String} itemId - id продукта (из api)
 * @property {Boolean} inStock - продукт в наличии (из api)
 */

/**
 * Определяет статуст продукта - в наличии, закончился или последний в текущей корзине
 * @param {Ref<ProductForStockStatusCheck>} product - продукт из пропс - ссылка на весь продукт для computed полей
 */
export const useStockStatus = (product) => {
  const { $gaApp } = useContext()

  const isWholeStockInCart = computed(() =>
    $gaApp.services.cart.main.checkProductWholeStock(unref(product)?.itemId),
  )

  const productStatus = computed(() => {
    /**
     * Если в корзину добавлены все запасы продукта
     *
     * В сложных продуктах должна быть кнопка добавления в корзину, что бы открыть фастпревью.
     * Поэтому для сложных продуктов статус "Упс. продукт закончился" выводить не нужно
     */
    if (!unref(product)?.configurable && isWholeStockInCart.value) {
      return STOCK_STATUS.WHOLE_STOCK_IN_CART
    }

    // Если продукта нет в наличии
    if (!unref(product)?.inStock) {
      return STOCK_STATUS.OOS
    }

    return STOCK_STATUS.IN_STOCK
  })

  const isNotInStock = computed(() =>
    [STOCK_STATUS.OOS, STOCK_STATUS.WHOLE_STOCK_IN_CART].includes(
      productStatus.value,
    ),
  )

  const isOutOfStock = computed(() => {
    return productStatus.value === STOCK_STATUS.OOS
  })

  const isWholeStock = computed(() => {
    return productStatus.value === STOCK_STATUS.WHOLE_STOCK_IN_CART
  })

  return {
    productStatus,
    isOutOfStock,
    isWholeStock,
    isNotInStock,
  }
}
