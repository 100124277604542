import { ACTIONS } from '../../../constants'
import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'
import { GtmGa4BannerBaseModel } from '../gtm-ga4-banner-base.model/gtm-ga4-banner-base.model'

export class GtmGa4BannerClickModel extends GtmBaseModel {
  constructor(data) {
    super(ACTIONS.SELECT_PROMOTION.GA4)

    const { type } = data

    this.type = type
    this.ecommerce = new GtmGa4BannerBaseModel(data)
  }
}
