import { price } from './price'

export const prices = {
  type: 'object',
  properties: {
    actual: { ...price, required: true },
    old: price,
    prefix: { type: 'boolean' },
    oldStrikethrough: { type: 'boolean' },
    discountPercent: { type: 'String' },
  },
  additionalProperties: false,
}
