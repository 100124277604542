export const LISTING_PAGE_TYPE = {
  ARTICLES: 'articles',
  STREAMS: 'streams',
}

export const LISTING_SERVICE_KEY = {
  ARTICLES: 'listing',
  STREAMS: 'streams',
}

export const LISTING_API_SERVICE_KEY = {
  ARTICLES: 'api',
  STREAMS: 'apiStreams',
}

export const LISTING_STORE_KEY = {
  ARTICLES: 'list',
  STREAMS: 'listStreams',
}
