import { computed, useContext } from '@nuxtjs/composition-api'

export const useAppCommonStore = () => {
  const { $gaApp } = useContext()

  const availableStores = computed(
    () => $gaApp.stores.app.common.availableStores,
  )

  return {
    availableStores,
  }
}
