import { computed, toRefs } from 'vue'

import { useCartCounter } from '../../../composables'
import { propValidator } from '../../../utils'
import { GaProductCardCartButton } from '../../molecules/cart-button'
import { GaProductCardCounterAdapter } from '../counter-adapter'

import { useBehavior } from './scripts/composables'
import { cartAdapter as schemaCartAdapter } from './scripts/schemas'

// @vue/component
export default {
  name: 'ga-product-card-cart-adapter',

  components: {
    GaProductCardCartButton,
    GaProductCardCounterAdapter,
  },

  props: {
    data: {
      type: Object,
      default: () => ({}),
      validator: (value) => propValidator(value, schemaCartAdapter),
      required: true,
    },

    cancelDefaultAddToCart: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['click-add-to-cart', 'add-to-cart', 'open-preview', 'update-qty'],

  setup(props, { attrs, emit }) {
    const { cancelDefaultAddToCart, data } = toRefs(props)

    const { cartWithCounter } = useCartCounter(data)

    const { onCartClick, onUpdateQty } = useBehavior(
      data,
      cancelDefaultAddToCart,
      emit,
    )

    const propsToPass = computed(() => {
      const { data, cancelDefaultAddToCart, ...restProps } = attrs
      return restProps
    })

    return {
      cartWithCounter,

      propsToPass,

      onCartClick,
      onUpdateQty,
    }
  },
}
