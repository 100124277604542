import { useContext } from '@nuxtjs/composition-api'

import { computed, unref } from 'vue'

import {
  GaIconAdditionalCrossTiny,
  GaIconAdditionalHeartSmall,
  GaIconAdditionalHeartSmallFilled,
} from '@ga/icons'

import { GaAction } from '@ga/ui-components/action'
import { GaIcon } from '@ga/ui-components/icon'

import { useBusyBrands } from '~/modules/favorites'
import { useUser } from '~/modules/user'

import { useAnalytics } from '../../../composable/analytics'

// @vue/component
export default {
  name: 'ga-brands-item',

  components: {
    GaAction,
    GaIcon,
    GaIconAdditionalCrossTiny,
    GaIconAdditionalHeartSmallFilled,
    GaIconAdditionalHeartSmall,
  },

  props: {
    brand: {
      type: Object,
      required: true,
    },

    isFavorite: {
      type: Boolean,
      default: false,
    },

    showFavourite: {
      type: Boolean,
      default: true,
    },

    clickableLabel: {
      type: Boolean,
      default: true,
    },

    canRemove: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const { $gaApp } = useContext()

    const analytic = useAnalytics()
    const { userIsAuth } = useUser()
    const { checkIsBusy } = useBusyBrands()

    const brandLabel = computed(() => props.brand.labelOriginal)

    const onFavoriteToggle = (favoriteToggleFn) => {
      // проверяем, что бренд в этот момент не добавляется/удаляется из Избранного
      if (
        unref(userIsAuth) &&
        !props.isFavorite &&
        !checkIsBusy(props.brand.id)
      ) {
        analytic.onAddBrandToFavorite(props.brand)
      }

      favoriteToggleFn()
    }

    const onItemClick = () => {
      analytic.onBrandItemClick({
        ...props.brand,
        isFavorite: props.isFavorite,
        searchQuery: $gaApp.stores.brands.search.queryOriginal,
      })
    }

    const mods = computed(() => ({
      favorite: props.isFavorite,
      removable: props.canRemove,
    }))

    return {
      mods,
      brandLabel,

      onItemClick,
      onFavoriteToggle,
    }
  },
}
