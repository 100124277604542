import capitalize from 'lodash/capitalize'

const REGION_TYPES = {
  CITY: 'город',
  REPUBLIC: 'республика',
  CHUVASHIA: 'Чувашия',
}

const DIRECT_WORD_ORDER_REPUBLICS = [
  'Донецкая Народная',
  'Кабардино-Балкарская',
  'Карачаево-Черкесская',
  'Луганская Народная',
  'Удмуртская',
  'Чеченская',
]

export class FormatterService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  composeHouse(options) {
    const { house, blockType, block } = options

    return [house, block ? `${blockType}. ${block}` : block]
      .filter(Boolean)
      .join(', ')
  }

  composeRegionWithType(region, regionType) {
    let directWordOrder = true
    let separator = null
    let composeRegionType = regionType

    /*
     * region содержит regionType
     * region: Ханты-Мансийский Автономный округ - Югра, regionType: aвтономный округ
     * region: Кемеровская область - Кузбасс, regionType: область
     * */
    if (
      String(region).toLowerCase().includes(String(regionType).toLowerCase())
    ) {
      composeRegionType = null
    }

    /*
     * Города федерального значения без "город"
     * "город Москва" -> "Москва"
     * */
    if (regionType === REGION_TYPES.CITY) {
      composeRegionType = null
    }

    /*
     * Правильная очередность для республик
     * "Республика Хакасия"
     * "Чеченская Республика"
     * */
    if (
      regionType === REGION_TYPES.REPUBLIC &&
      !DIRECT_WORD_ORDER_REPUBLICS.includes(region)
    ) {
      directWordOrder = false
    }

    /* Республика с большой буквы
     * "Чеченска Республика"
     * */
    if (regionType === REGION_TYPES.REPUBLIC) {
      composeRegionType = capitalize(regionType)
    }

    /*
     * Только для Чувашии
     * region: 'Чувашская республика'
     * regionType: 'Чувашия'
     * Чувашская Республика — Чувашия
     * */
    if (regionType === REGION_TYPES.CHUVASHIA) {
      separator = '—'
    }

    return (
      directWordOrder
        ? [region, separator, composeRegionType]
        : [composeRegionType, separator, region]
    )
      .filter(Boolean)
      .join(' ')
  }

  /*
   * Адрес доставки одной строкой
   * */
  formatLocationDeliveryName(location) {
    const { city: composeCity, street, house, block, blockType } = location
    const composeHouse = this.composeHouse({
      house,
      blockType,
      block,
    })

    return [street || composeCity, composeHouse].filter(Boolean).join(', ')
  }

  /*
   * Адрес доставки в мобильном меню, пдп, фильтрах на категории
   * разделенный на дом и остальное
   * */
  formatLocationDeliveryNameDivided(location) {
    const { city: composeCity, street, house, block, blockType } = location
    const composeHouse = this.composeHouse({
      house,
      blockType,
      block,
    })

    const streetHouseSeparator =
      (street || composeCity) && composeHouse ? `,&nbsp;` : ''

    return {
      main: [street || composeCity, streetHouseSeparator].join(''),
      house: composeHouse,
    }
  }

  /*
   * Нужен в хедере на десктопе
   * разделение на house и все остальное нужно чтобы отображать в адресе ..., если адрес длинный, например:
   * Москва · Екатерины Буданов...5
   * */
  formatLocationDeliveryNameExtended(location) {
    const { city: composeCity, street, house, block, blockType } = location
    const composeHouse = this.composeHouse({
      house,
      blockType,
      block,
    })

    const streetHouseSeparator = street && composeHouse ? `,&nbsp;` : ''
    const citySeparator =
      composeCity && (street || composeHouse) ? `&nbsp;&nbsp;·&nbsp;&nbsp;` : ''

    return {
      main: [composeCity, citySeparator, street, streetHouseSeparator].join(''),
      house: composeHouse,
    }
  }

  convertLocationToAddress(location) {
    const {
      street,
      house,
      block,
      blockType,

      address,
    } = location

    const { region, regionTypeFull, city, settlement } = address

    return {
      stateName: region,
      stateTypeName: regionTypeFull,
      cityName: city,
      settlementName: settlement,
      streetName: street,
      houseNumber: this.composeHouse({ house, block, blockType }),
    }
  }

  composeAddress(address) {
    const {
      stateName,
      stateTypeName,
      cityName,
      settlementName,
      streetName,
      houseNumber,
    } = address

    const stateNameWithType = this.composeRegionWithType(
      stateName,
      stateTypeName,
    )

    const items = [settlementName, cityName, stateNameWithType]
    const uniqueItems = [...new Set(items.filter(Boolean))]

    const localityMain =
      houseNumber || streetName ? streetName : uniqueItems.shift()
    const area = uniqueItems.shift()

    return {
      area,
      localityMain,
      localityHouse: houseNumber,
    }
  }

  /*
   * Список адресов в модалке (новый формат)
   * */
  formatLocationAddressList(location) {
    const address = this.convertLocationToAddress(location)
    const { area, localityMain, localityHouse } = this.composeAddress(address)

    const separator = localityMain && localityHouse ? ',&nbsp;' : ''

    return {
      area,
      localityMain: [localityMain, separator].join(''),
      localityHouse,
    }
  }

  /*
   * Инпут улицы в попапе редактирования адреса
   * */
  formatStreetHouse(address) {
    const { street, streetType, house, houseType, block, blockType } = address

    const items = [
      [streetType, street].filter(Boolean).join('. '),
      [houseType, house].filter(Boolean).join('. '),
      [blockType, block].filter(Boolean).join('. '),
    ].filter(Boolean)

    return {
      result: items.join(', '),
      itemsCount: items.length,
    }
  }

  /**
   * Форматирует уточнения по дому
   * @param { string } apartment - квартира
   * @param { string } entrance - подъезд
   * @param { string } floor - этаж
   * @param { string } intercom - домофон
   * @returns {string}
   */
  formatBuildingDetails({ apartment, entrance, floor, intercom }) {
    const items = [
      apartment
        ? `${this.gaApp.i18n.t('location.addressPrefix.apartment')} ${apartment}`
        : null,
      intercom
        ? `${this.gaApp.i18n.t('location.addressPrefix.intercom')} ${intercom}`
        : null,
      entrance
        ? `${this.gaApp.i18n.t('location.addressPrefix.entrance')} ${entrance}`
        : null,
      floor
        ? `${this.gaApp.i18n.t('location.addressPrefix.floor')} ${floor}`
        : null,
    ]

    return items.filter(Boolean).join(', ')
  }

  /**
   * Собирает максимально подробную строку из адреса
   * @param { object } location - адрес в формате location
   * @returns {string}
   */
  formatFullAddressLine(location) {
    const {
      apartment,
      entrance,
      floor,
      intercom,
      region,
      regionTypeFull,
      city,
      settlement,
    } = location?.address || {}

    const regionWithType = this.composeRegionWithType(region, regionTypeFull)
    const streetHouse = this.formatStreetHouse(location).result
    const buildingDetails = this.formatBuildingDetails({
      apartment,
      entrance,
      floor,
      intercom,
    })

    const items = [
      regionWithType,
      city,
      settlement,
      streetHouse,
      buildingDetails,
    ]

    const uniqueItems = [...new Set(items.filter(Boolean))]

    return uniqueItems.join(', ')
  }

  // список адресов в ЛК
  formatAddressFull(address) {
    if (!address) {
      return ''
    }

    return address.fullAddressName
  }

  formatAddressStreetHouse(address) {
    if (!address) {
      return ''
    }

    return [address.streetName, address.houseNumber].filter(Boolean).join(', ')
  }

  /*
   * ЛК список адресов верхняя строка
   * */
  formatAddressStreetHouseDivided(address) {
    if (!address) {
      return ''
    }

    return {
      street: address.streetName ? `${address.streetName},&nbsp;` : '',
      house: address.houseNumber,
    }
  }

  /*
   * ЛК модалка редактирования адреса на мобилках,
   * ЛК список адресов нижняя строка
   * */
  formatAddressCityState(address) {
    if (!address) {
      return ''
    }

    const { settlementName, cityName, stateName, stateTypeName } = address

    const stateWithType = this.composeRegionWithType(stateName, stateTypeName)

    const items = [settlementName, cityName, stateWithType]
    const uniqueItems = [...new Set(items.filter(Boolean))]

    return uniqueItems.join(', ')
  }

  /*
   * ЛК модалка редактирования адреса на десктопе
   * */
  formatAddressCityStateCascade(address) {
    if (!address) {
      return ''
    }

    const { settlementName, cityName, stateName, stateTypeName } = address

    const stateWithType = this.composeRegionWithType(stateName, stateTypeName)

    const items = [settlementName, cityName, stateWithType]
    const uniqueItems = [...new Set(items.filter(Boolean))]
    const [noLastItems, lastItem] = [
      uniqueItems.slice(0, -1),
      uniqueItems.at(-1),
    ]

    return [noLastItems.join(', '), lastItem]
  }

  /*
   * Параметр query для запроса подсказок улицы (плейд)
   * */
  formatStreetSuggestQuery(location = {}, street) {
    const { address } = location

    const {
      region: regionWithoutType,
      regionTypeFull,
      city,
      settlement,
      settlementWithType: settlementWithShortType,
    } = address || {}

    const regionWithType = this.composeRegionWithType(
      regionWithoutType,
      regionTypeFull,
    )

    const queryItems = [
      regionWithType,
      city,
      settlementWithShortType || settlement,
    ]

    const uniqueQueryItems = [...new Set(queryItems)]

    return [...uniqueQueryItems, street].filter(Boolean).join(', ')
  }

  /*
   * Параметры фильтрации подсказок для запроса улицы (плейд)
   * если герион и город одинаковые, то передаем только город. касается и query и отдельного параметра
   * */
  formatStreetSuggestFilterParams(location = {}) {
    const {
      address,
      city: cityOrSettlement,
      region: regionWithShortType, // тут может быть localityRegion с полным типом если мы на ручках маги, у нас не Питер и не Москва и dadataJson пуст
    } = location

    const { region: regionWithoutType } = address || {}

    // должен быть регион без типа, либо регион с кратким типом
    // если регион с полным типом, то бэк ничего не найдет
    const region = regionWithoutType || regionWithShortType

    const items = [region, cityOrSettlement]
    const uniqueItems = [...new Set(items.filter(Boolean))]

    return {
      cityName: uniqueItems.pop(),
      regionName: uniqueItems.pop(),
    }
  }
}
