import { LOCAL_STORAGE_KEY } from '../constants'

/** запрашивает спиок способов авторизации, сохраняет в стор */
export class ModalAuthTypesService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  async fetchAuthTypes() {
    const data = await this.gaApp.services.auth.api.getAuthTypes()

    this.gaApp.stores.auth.main.externalAuthTypes = data.externalAuthTypes || []
  }

  /** редиректит на страницу авторизации через внешние сервисы (яндекс, вк и тд),
   * сохраняет данные, необходимые при возврате в local storage */
  redirectToExternalAuthService(serviceName) {
    const { url, params } =
      this.gaApp.config.get('authorization')[serviceName] || {}

    let redirectUrl

    if (url) {
      redirectUrl = new URL(url)

      if (params) {
        // добавляем параметр redirect_uri для теста/препрода/прода
        if (!this.gaApp.config.get('dev')) {
          params.redirect_uri = `${window.location.origin}/auth/${serviceName}`
        }
        Object.entries(params).forEach(([key, value]) => {
          redirectUrl.searchParams.append(key, value)
        })
      }

      // сохраняем текущий URL в local storage для дальнейшего перехода на него
      this.gaApp.services.app.browserStorage.local.set(
        LOCAL_STORAGE_KEY.AUTH_REDIRECT_URL,
        this.gaApp.route.fullPath,
      )

      window.location.assign(redirectUrl)
    } else {
      const message =
        this.gaApp.i18n.t(`auth.error.externalAuthFailed.${serviceName}`) ??
        this.gaApp.i18n.t(`auth.error.externalAuthFailed.default`)

      this.gaApp.services.notification.main.open(message)
    }
  }
}
