import {
  FILTER_TYPE,
  LOAD_DIR,
  STREAMS_BASE_URL,
  STREAMS_PAGE_CHUNK_SIZE,
} from '../constants'
import { LISTING_PAGE_TYPE } from '../constants/listing'
import { STREAM_UPDATE_STATUS_INTERVAL } from '../constants/stream'

import { ListService } from './list.service'

export class StreamsService extends ListService {
  constructor(gaApp) {
    super(gaApp, LISTING_PAGE_TYPE.STREAMS)
    this.updateStatusesIntervalId = null
  }

  // Загрузка начальных данных
  // сброс хранилища, загрузка фильтров, загрузка списка стримов, обновление статусов
  async loadData() {
    this.gaApp.stores.articles.listStreams.isPreview = false

    await this.gaApp.services.articles.filters.loadFilters(
      this.gaApp.route.query,
      LISTING_PAGE_TYPE.STREAMS,
    )

    await this.fetchInitialData()
    await this.updateStatuses()
  }

  async loadPreviewListingData(id) {
    await this.loadPreviewListing(id)
    await this.updateStatuses()
  }

  // Загружаем дынные для превью карточки стрима в листинге.
  async loadPreviewListing(id) {
    this.gaApp.stores.articles.listStreams.isLoading = true
    this.gaApp.stores.articles.listStreams.isPreview = true

    try {
      const response =
        await this.gaApp.services.articles.apiStreams.fetchStreamsListingPreview(
          id,
        )

      const data = {
        ...response,
        items: response.streams,
      }

      this.clearPages()
      this.setInitialListingDataToStore(data)

      this.gaApp.stores.articles.listStreams.totalArticlesAmount =
        response.streams.length
    } catch {
      this.clearPages()
    } finally {
      this.gaApp.stores.articles.listStreams.isLoading = false
    }
  }

  // Загрузка первой страницы стримов
  // фильтры передаем при загрузке страницы стримов с выделенной рубрикой для SSR
  async fetchInitialData(filters) {
    this.resetFirstLoadedImagesCount()
    this.gaApp.stores.articles.listStreams.isLoading = true

    try {
      const { p: pageNumberFromQuery } = this.gaApp.route.query

      this.gaApp.stores.articles.listStreams.currentPageNumber =
        pageNumberFromQuery > 1 ? pageNumberFromQuery - 1 : 0

      const params = this.prepareParams({
        pageNumber: this.gaApp.stores.articles.listStreams.currentPageNumber,
        pageSize: STREAMS_PAGE_CHUNK_SIZE,
        filters,
      })

      const response =
        await this.gaApp.services.articles.apiStreams.fetchStreamsListing(
          params,
        )

      if (!response.items.length) {
        this.showEmptyError()
      }

      this.clearPages()
      this.setInitialListingDataToStore(response)
    } catch {
      this.clearPages()
    } finally {
      this.gaApp.stores.articles.listStreams.isLoading = false
    }
  }

  // Загружаем страницу со стримами если это возможно
  // страница не загружается в текущий момент
  // странице не была загружена ранее
  // страница не была отрисована ранее
  async triggerFetchPage(pageNumber, dir) {
    const cannotFetch =
      this.isPageFetching(pageNumber) ||
      !this.isPageExists(pageNumber) ||
      this.isPageRendered(pageNumber)

    if (cannotFetch) {
      return
    }

    await this.fetchPage(pageNumber, dir)
  }

  // Загружаем следующую страницу с материалами
  loadNextPage() {
    this.triggerFetchPage(
      this.gaApp.stores.articles.listStreams.lastLoadedPageNumber + 1,
      LOAD_DIR.NEXT,
    )
  }

  // Загружаем предыдущую страницу с материалами
  loadPrevPage() {
    if (this.gaApp.stores.articles.listStreams.firstLoadedPageNumber === 0) {
      return
    }

    this.triggerFetchPage(
      this.gaApp.stores.articles.listStreams.firstLoadedPageNumber - 1,
      LOAD_DIR.PREV,
    )
  }

  // Загружаем следующую страницу со стримами + проставляем номер страницы в url
  onPageIntersect(pageNumber) {
    // страница появилась в области видимости, проставляем ее как текущую страницу
    // это вызовет обновление данных url
    this.setCurrentPageNumber(pageNumber)
    this.triggerFetchPage(pageNumber + 1, LOAD_DIR.NEXT)
  }

  // Загрузка страницы со списком стримов
  async fetchPage(pageNumber, dir) {
    this.setLoadingDirection(dir)
    this.gaApp.stores.articles.listStreams.fetchingPageNumber = pageNumber

    try {
      const lastTranslationDate =
        dir === LOAD_DIR.NEXT
          ? this.gaApp.stores.articles.list.lastActualPublicationDate
          : undefined

      // Подготовим параметры аналогично статьям,
      // за исключением параметра даты последнего элемента
      const params = this.prepareParams({
        pageNumber,
        lastTranslationDate,
      })

      const { streams } =
        await this.gaApp.services.articles.apiStreams.fetchStreamsListing(
          params,
        )

      const page = {
        number: pageNumber,
        items: streams,
      }

      this.addPage(page, dir)
    } catch (error) {
      console.error('error')
    } finally {
      this.setLoadingDirection(null)
      this.gaApp.stores.articles.listStreams.fetchingPageNumber = null
    }
  }

  // заносим значение статуса стрима в таблицу - ключ (id стрима) - значение (статус)
  async updateStatuses() {
    try {
      const { statuses } =
        await this.gaApp.services.articles.apiStreams.fetchStreamsStatuses()

      if (!statuses || !statuses.length) {
        return
      }

      // Переводим массив статусов в объект
      const mappedStatuses = statuses.reduce((result, status) => {
        return Object.assign(result, { [status.externalId]: status.statusType })
      }, {})

      this.gaApp.stores.articles.streams.statuses = Object.assign(
        {},
        this.gaApp.stores.articles.streams.statuses,
        mappedStatuses,
      )
    } catch (error) {
      console.error(error)
    }
  }

  // Обновление статуса стримов по интервалу
  fetchStatusesByInterval() {
    if (this.updateStatusesIntervalId) {
      return
    }

    // обновляем статусы стримов раз в 30 секунд
    this.updateStatusesIntervalId = setInterval(
      () => this.updateStatuses(),
      STREAM_UPDATE_STATUS_INTERVAL,
    )
  }

  // Удаление интервала обновления стримов
  clearUpdateStatusesInterval() {
    clearInterval(this.updateStatusesIntervalId)
    this.updateStatusesIntervalId = null
  }

  // Обновление статуса одиночно стрима на заданный
  updateStatus(status) {
    this.gaApp.stores.articles.streams.statuses[status.externalId] =
      status.statusType
  }

  // Загружаем материалы конкретной рубрики
  async fetchStreamsRubric(rubric) {
    this.gaApp.stores.articles.listStreams.isLoading = true

    try {
      const { p: pageNumberFromQuery } = this.gaApp.route.query

      const pageNumber = pageNumberFromQuery > 1 ? pageNumberFromQuery - 1 : 0

      this.gaApp.stores.articles.listStreams.currentPageNumber = pageNumber

      const params = {
        pagination: {
          pageSize: STREAMS_PAGE_CHUNK_SIZE,
          pageNumber,
        },
        rubric,
      }

      const response =
        await this.gaApp.services.articles.apiStreams.fetchStreamsFilterListing(
          params,
        )

      const data = {
        ...response,
        items: response.streams,
      }

      this.setInitialListingDataToStore(data)
    } catch {
      this.clearPages()
    } finally {
      this.gaApp.stores.articles.listStreams.isLoading = false
    }
  }

  async fetchStreamsFilterListing({ filterType, filterKey }) {
    // Если это клиент - перенаправляем на листинг и проставляем фильтры в url
    if (!this.gaApp.isServer) {
      return this.redirectToListingPage(filterType, filterKey, STREAMS_BASE_URL)
    }

    // Потенциально помимо рубрик далее появятся теги
    switch (filterType) {
      case FILTER_TYPE.rubric:
        await this.fetchStreamsRubric(filterKey)
        break
      default: {
        const filters = [{ key: filterKey, type: filterType }]
        await this.fetchInitialData(filters)
      }
    }
  }

  showEmptyError() {
    this.gaApp.services.notification.main.open({
      data: {
        props: {
          text: this.gaApp.i18n.t('articles.streamsNotFound'),
          alert: true,
        },
      },
    })
  }

  reset() {
    this.gaApp.stores.articles.listStreams.$reset()
  }
}
