import { ANALYTICS_EVENT_TYPE } from '../../../../constants'
import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'

export class GtmGa4DownloadPoaClickModel extends GtmBaseModel {
  constructor() {
    super({})

    this.action_type = 'click_download_attorney'
    this.type = ANALYTICS_EVENT_TYPE.KPPK
  }
}
