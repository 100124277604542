import { computed, toRefs } from 'vue'

import { GaButtonBase } from '../button-base'
import { GaIcon } from '../icon'
import { GaLinkBase } from '../link-base'
import { GaSpinner } from '../spinner'

import { ICON, ICON_COMPONENT, SIZE, STATE, VIEW } from './scripts/const'

// @vue/component
export default {
  name: 'ga-button-logo',

  components: {
    GaIcon,
    GaSpinner,
  },

  inheritAttrs: false,

  props: {
    /** Внешний вид кнопки */
    view: {
      type: String,
      default: VIEW.MAIN,
      validator: (value) => Object.values(VIEW).includes(value),
    },
    /** Размер кнопки */
    size: {
      type: String,
      default: SIZE.M,
      validator: (value) => Object.values(SIZE).includes(value),
    },
    /** Добавляет иконку */
    icon: {
      type: String,
      required: true,
      validator: (value) => Object.values(ICON).includes(value),
    },
    /** Добавляет анимацию загрузки */
    loading: {
      type: Boolean,
      default: false,
    },
    /** Убирает интерактивность */
    disabled: {
      type: Boolean,
      default: false,
    },
    /** Делает кнопку круглой  */
    round: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { attrs }) {
    const { view, size, icon, loading, disabled, round } = toRefs(props)
    const component = computed(() => {
      return attrs.href ? GaLinkBase : GaButtonBase
    })

    const iconComponent = computed(() => {
      return ICON_COMPONENT[icon.value]
    })

    const state = computed(() => {
      switch (true) {
        case disabled.value:
          return STATE.DISABLED
        case loading.value:
          return STATE.LOADING
        default:
          return STATE.DEFAULT
      }
    })

    const disabledInternal = computed(() => {
      return [STATE.DISABLED, STATE.LOADING].includes(state.value)
    })

    const isLoading = computed(() => {
      return state.value === STATE.LOADING
    })

    const mods = computed(() => {
      return {
        view: view.value,
        size: size.value,
        state: state.value,
        round: round.value,
      }
    })

    return {
      component,
      iconComponent,

      mods,
      disabledInternal,

      isLoading,
    }
  },
}
