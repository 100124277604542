import {
  buildNotificationEntityNameAsHtml,
  ENTITY,
} from '~/modules/notifications'

import {
  NOTIFICATION_ACTION,
  NOTIFICATION_LOCALE_PATH,
  VIEW,
} from '../constants'

export class NotificationService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  getErrorMessage() {
    return this.gaApp.stores.cart.main.errors.at(0)?.trim() || ''
  }

  addSuccess(product, action = NOTIFICATION_ACTION.ADD) {
    const text = this.gaApp.i18n.t(NOTIFICATION_LOCALE_PATH.SUCCESS[action])

    this.gaApp.services.notification.product.open({
      product,
      type: ENTITY.CART,
      text,
      listeners: {
        click: () => {
          this.gaApp.services.modal.main.openSuperModalCart()

          this.gaApp.services.notification.main.closeAll()
        },
      },
    })
  }

  addFailure(product, action = NOTIFICATION_ACTION.ADD) {
    const slotText = this.gaApp.i18n.t(NOTIFICATION_LOCALE_PATH.ERROR[action], {
      product: buildNotificationEntityNameAsHtml(product),
    })

    this.gaApp.services.notification.main.open({
      slotText,
    })
  }

  scrollToItem(container, sku) {
    if (!container || !sku) {
      return
    }

    const item = container.querySelector(`[data-cart-item-sku="${sku}"]`)

    if (item) {
      item.scrollIntoView({ behavior: 'smooth' })
    }
  }

  scrollToOos(container) {
    if (!container) {
      return
    }

    const sectionOos = container.querySelector(
      `[data-cart-section="${VIEW.OUTOFSTOCK}"]`,
    )

    sectionOos && sectionOos.scrollIntoView({ behavior: 'smooth' })

    // todo разобраться почему не работает scrollTo
    /*
    this.gaApp.ctx.$scrollTo(sectionOos, {
      container,
      offset: 60,
    }) */
  }

  showPartOfStockNotification(container) {
    const partOfStockItems = this.gaApp.stores.cart.main.ids.partOfStock
    const itemSku = partOfStockItems[0]

    this.gaApp.services.notification.main.open({
      data: {
        props: {
          text: this.gaApp.i18n.t('cart.partOfStockNotification.text'),
          link: {
            text: this.gaApp.i18n.t('cart.partOfStockNotification.action'),
          },
        },
        listeners: {
          click: () => {
            this.scrollToItem(container, itemSku)
            this.gaApp.services.notification.main.closeAll()
          },
        },
      },
    })
  }

  addNotification(data) {
    this.gaApp.services.notification.main.open(data)
  }

  showAlert(container) {
    const hasPartOfStock = this.gaApp.stores.cart.main.hasPartOfStock
    const errorMessage = this.getErrorMessage()

    if (hasPartOfStock) {
      this.showPartOfStockNotification(container)
    } else if (errorMessage) {
      this.addNotification(errorMessage)
    }
  }

  showShortNotification(text) {
    this.gaApp.services.notification.main.open({
      data: {
        props: {
          text,
          hasMinLinesCount: false,
        },
      },
    })
  }
}
