export class MainRepository {
  constructor(gaApp, endpoints) {
    this.gaApp = gaApp

    this.endpoints = gaApp.api.endpoints(endpoints)
  }

  /**
   * Получение локации по ip адресу (для дадаты)
   */
  async getLocationByIp() {
    const { url } = this.endpoints.locationByIp()

    try {
      const response = await this.gaApp.api.request().get(url)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async geoSuggest(params = {}, requestParams = {}) {
    const { url } = this.endpoints.geoSuggest()

    try {
      const response = await this.gaApp.api
        .request(requestParams)
        .get(url, { params })

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async geoGeocode(params = {}) {
    const { url } = this.endpoints.geoGeocode()

    try {
      const response = await this.gaApp.api.request().get(url, {
        params,
      })

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async geoReverseGeocode(params = {}, requestParams = {}) {
    const { url } = this.endpoints.geoReverseGeocode()

    try {
      const response = await this.gaApp.api.request(requestParams).get(url, {
        params,
      })

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  /**
   * Запись адреса для авторизованного юзера
   * для гостя вернет то, что передали в ручку
   */
  async setAddress(data = {}) {
    const { url } = this.endpoints.setAddress()

    try {
      const response = await this.gaApp.api.request().post(url, data)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }
}
