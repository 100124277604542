import { advDisclaimer } from '~/modules/adv-disclaimer'
import { app } from '~/modules/app'
import { articles } from '~/modules/articles'
import { auth } from '~/modules/auth'
import { bnpl } from '~/modules/bnpl'
import { brands } from '~/modules/brands'
import { brandzone } from '~/modules/brandzone'
import { cache } from '~/modules/cache'
import { captcha } from '~/modules/captcha'
import { cart } from '~/modules/cart'
import { checkout } from '~/modules/checkout'
import { customer } from '~/modules/customer'
import { debugPanel } from '~/modules/debug-panel'
import { dolyame } from '~/modules/dolyame'
import { eyezon } from '~/modules/eyezon'
import { favorites } from '~/modules/favorites'
import { featuresToggle } from '~/modules/features-toggle'
import { filters } from '~/modules/filters'
import { footer } from '~/modules/footer'
import { game } from '~/modules/game'
import { giftCards } from '~/modules/gift-cards'
import { giftCardsB2b } from '~/modules/gift-cards-b2b'
import { header } from '~/modules/header'
import { home } from '~/modules/home'
import { info } from '~/modules/info'
import { labels } from '~/modules/labels'
import { location } from '~/modules/location'
import { modal } from '~/modules/modal'
import { notifications } from '~/modules/notifications'
import { pdp } from '~/modules/pdp'
import { placements } from '~/modules/placements'
import { plp } from '~/modules/plp'
import { productCard } from '~/modules/product-card'
import { promo } from '~/modules/promo'
import { purchased } from '~/modules/purchased-products'
import { review } from '~/modules/review'
import { search } from '~/modules/search'
import { stockists } from '~/modules/stockists'
import { streamerce } from '~/modules/streamerce'
import { subscription } from '~/modules/subscription'
import { support } from '~/modules/support'
import { ticker } from '~/modules/ticker'
import { user } from '~/modules/user'

export default (context, inject) => {
  const gaApp = context.$gaApp

  gaApp.installModules([
    advDisclaimer,
    app,
    auth,
    brands,
    cart,
    cache,
    captcha,
    checkout,
    customer,
    location,
    debugPanel,
    eyezon,
    filters,
    featuresToggle,
    giftCards,
    giftCardsB2b,
    home,
    header,
    info,
    labels,
    notifications,
    placements,
    promo,
    purchased,
    review,
    streamerce,
    search,
    subscription,
    support,
    stockists,
    articles,
    ticker,
    footer,
    productCard,
    brandzone,
    pdp,
    plp,
    dolyame,
    user,
    favorites,
    modal,
    game,
    bnpl,
  ])

  inject('stores', gaApp.modules.stores)
  inject('repositories', gaApp.modules.repositories)
  inject('services', gaApp.modules.services)
}
