import { ACTIONS } from '../../../constants'
import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'
import { GtmGa4ProductBaseModel } from '../gtm-ga4-product-base.model/gtm-ga4-product-base.model'

export class GtmGa4ProductClickModel extends GtmBaseModel {
  constructor({ product }) {
    super(ACTIONS.SELECT_ITEM.GA4)

    const { itemListId, itemListName } = product

    this.item_list_name = itemListName
    this.item_list_id = itemListId

    this.ecommerce = new GtmGa4ProductBaseModel({ product })
  }
}
