<template>
  <svg fill="none" viewBox="0 0 31 33">
    <g filter="url(#video-player-v2-sound-disabled-a)">
      <g opacity=".4" fill="#fff">
        <path
          d="m19.625 15.71-1.5-1.5V7.663l-3.249 3.3-1.06-1.06L19.625 4v11.71Z"
        />
        <path
          fill-rule="evenodd"
          d="M18.125 17.064v-.025l1.5 1.5v.025l7.405 7.406-1.06 1.06-6.345-6.344V29l-7.031-7.143H4V11.143h6.082L5.97 7.03l1.06-1.06 5.173 5.173h.026l1.244 1.244-.012.013 4.664 4.664Zm0 2.122-6.543-6.543H5.5v7.714h7.722l4.903 4.981v-6.152Z"
          clip-rule="evenodd"
        />
      </g>
    </g>
    <defs>
      <filter
        id="video-player-v2-sound-disabled-a"
        width="31.4"
        height="31.4"
        x=".8"
        y=".8"
        color-interpolation-filters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="1.6" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
        />
        <feBlend
          in2="BackgroundImageFix"
          mode="normal"
          result="effect1_dropShadow_3111_10283"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_3111_10283"
          mode="normal"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-video-player-v2-sound-disabled',
}
</script>
