import { computed, toRefs, unref } from 'vue'

import { useSize } from './size'

export const useStyle = (props) => {
  const { size, sizeDiscount, old, showCurrency } = toRefs(props)

  const { fontSize } = useSize(size, old, showCurrency)

  const style = computed(() => ({
    gap: `${unref(sizeDiscount.value?.gap)}px`,
  }))

  const stylePrice = computed(() => ({
    fontSize: `${unref(fontSize)}px`,
  }))

  const styleDiscount = computed(() => ({
    fontSize: `${unref(sizeDiscount.value?.fontSize)}px`,
    lineHeight: `${unref(sizeDiscount.value?.lineHeight)}`,
  }))

  return {
    style,
    styleDiscount,
    stylePrice,
  }
}
