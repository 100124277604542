import { unref, useContext } from '@nuxtjs/composition-api'

import { toRefs } from 'vue'

import { useProductCardCart } from '~/modules/product-card'
// @vue/component
export default {
  name: 'ga-product-card-actions',
  props: {
    product: {
      type: Object,
      default: null,
    },
    productIndex: {
      type: Number,
      default: 1,
    },
    cartAnalyticsDetails: {
      type: Object,
      default: null,
    },
    analyticData: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const { $gaApp } = useContext()

    const { product, cartAnalyticsDetails, analyticData, productIndex } =
      toRefs(props)

    const { addToCart, updateQty, isLoading } = useProductCardCart(
      unref(product),
    )

    const onAddToCart = (cartData) => {
      addToCart(cartData.cartData, cartAnalyticsDetails.value)
    }

    const onUpdateQty = ($event) => {
      updateQty($event)
    }

    const onOpenPreview = () => {
      $gaApp.services.pdp.preview.setAnalyticsData({
        itemListName: unref(analyticData).path,
        itemListId: unref(analyticData).itemListId,
        categoryId: unref(analyticData).categoryId,
        index: unref(productIndex) + 1,
        analyticsData: unref(analyticData),
      })
    }

    return {
      onUpdateQty,
      onAddToCart,
      onOpenPreview,
      isLoading,
    }
  },
}
