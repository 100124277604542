import { ANALYTICS_EVENT_TYPE } from '../../../../constants'
import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'

export class GtmGa4DeliveryTypeClickModel extends GtmBaseModel {
  constructor({ delivery, place }) {
    super({})

    this.action_type = 'click_type_delivery'
    this.type = ANALYTICS_EVENT_TYPE.KPPK
    this.delivery = delivery
    this.place = place
  }
}
