import { useContext } from '@nuxtjs/composition-api'

import { onMounted, ref, unref } from 'vue'

import { useWindowScrollTopListener } from '@ga/use/window-scroll-top-listener'
import { clamp } from '@ga/utils'

import {
  NEGATIVE_SCROLL_DISTANCE_LIMIT,
  POSITIVE_SCROLL_DISTANCE_LIMIT,
  SCROLL_DISTANCE_DEBOUNCE,
} from '../../../../../constants'

export const useExtended = (extended, searchModalOpened) => {
  const { $gaApp } = useContext()

  const scrollTop = ref()
  const scrollDistance = ref(0)
  const scrollDistanceTimeout = ref()

  const resetScrollDistanceTimeout = () => {
    clearTimeout(unref(scrollDistanceTimeout))

    scrollDistanceTimeout.value = setTimeout(
      () => (scrollDistance.value = 0),
      SCROLL_DISTANCE_DEBOUNCE,
    )
  }

  const updateHeaderExtendedState = () => {
    if (unref(searchModalOpened)) {
      $gaApp.services.header.main.setHeaderExtendedState({
        value: true,
      })

      return
    }

    if (
      !unref(extended) &&
      unref(scrollDistance) === NEGATIVE_SCROLL_DISTANCE_LIMIT
    ) {
      $gaApp.services.header.main.setHeaderExtendedState({
        value: true,
      })

      return
    }

    if (
      unref(extended) &&
      unref(scrollDistance) === POSITIVE_SCROLL_DISTANCE_LIMIT
    ) {
      $gaApp.services.header.main.setHeaderExtendedState({
        value: false,
      })
    }
  }

  const updateExtended = (scrollTopNew) => {
    const absoluteDistance = scrollTopNew - unref(scrollTop)
    scrollTop.value = Math.max(scrollTopNew, 0)

    scrollDistance.value = clamp(unref(scrollDistance) + absoluteDistance, {
      min: NEGATIVE_SCROLL_DISTANCE_LIMIT,
      max: POSITIVE_SCROLL_DISTANCE_LIMIT,
    })

    updateHeaderExtendedState()
    resetScrollDistanceTimeout()
  }

  useWindowScrollTopListener(updateExtended)

  onMounted(() => {
    scrollTop.value = window.pageYOffset
  })

  return {
    updateHeaderExtendedState,
  }
}
