export { STATE } from '../../button'

export const VIEW = {
  TINT: 'tint',
  MAIN: 'main',
  INVERT: 'invert',
  SECONDARY: 'secondary',
  LINE: 'line',
}

export const SIZE = {
  XS: 'xs', // 30
  S: 's', // 35
  M: 'm', // 40
  L: 'l', // 45
  XL: 'xl', // 50
}

export const ICON_SIZE = {
  S: 's', // 10
  M: 'm', // 15
  L: 'l', // 20
}

export const BORDER_WIDTH = {
  S: 's', // 1
  M: 'm', // 1.25
  L: 'l', // 1.5
}
