import { PAGINATION_BUTTON } from './../../constants/pagination'

export const getOffersStateInitial = () => ({
  pages: [],
  viewedIds: [],

  offersCount: 0,

  pagination: {
    [PAGINATION_BUTTON.NEXT]: {
      pending: false,
      error: false,
    },
    [PAGINATION_BUTTON.PREV]: {
      pending: false,
      error: false,
    },
  },

  isLoading: false,
  shouldReloadPage: false,
  offersHash: '',
})

export default () => {
  return getOffersStateInitial()
}
