import { ACTION, TYPE } from '../constants'

const { ADD, DEL } = ACTION
const { BRANDS, PRODUCTS } = TYPE

export class ApiService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  async getProducts(data) {
    const res = await this.gaApp.repositories.favorites.main.getProductsV2(data)

    return res
  }

  async getFilters(data) {
    const res = await this.gaApp.repositories.favorites.main.getFilters(data)

    return res
  }

  async getPlp(data) {
    const res = await this.gaApp.repositories.favorites.main.getPlp(data)

    return res
  }

  async addProducts(ids) {
    try {
      const { data } =
        await this.gaApp.repositories.favorites.main.addProducts(ids)

      const products = data?.products || []

      if (products.length) {
        this.gaApp.services.favorites.products.setItems(products)
      }
    } catch (error) {
      this.gaApp.services.notification.main.open(
        this.gaApp.i18n.t('favorites.error.addProducts'),
      )
    }
  }

  async addProduct(product, hasNotification) {
    try {
      await this.gaApp.repositories.favorites.main.addProducts([product.id])

      this.gaApp.services.favorites.products.setItem(product.id)

      if (hasNotification) {
        this.gaApp.services.favorites.notifications.success(
          product,
          ADD,
          PRODUCTS,
        )
      }
    } catch (error) {
      this.gaApp.services.favorites.notifications.failure(product, ADD)
    }
  }

  async deleteProduct(product) {
    try {
      await this.gaApp.repositories.favorites.main.deleteProducts([product.id])

      this.gaApp.services.favorites.products.removeItem(product.id)
    } catch (error) {
      this.gaApp.services.favorites.notifications.failure(product, DEL)
    }
  }

  async addBrands(brand, hasNotification) {
    this.gaApp.services.favorites.brands.setItem(brand.id)
    this.gaApp.services.favorites.brands.setItemEx(brand.id)
    this.gaApp.stores.favorites.brands.addedItems.add(brand.id)
    this.gaApp.stores.favorites.main.brandsMap[brand.id] = true

    try {
      await this.gaApp.repositories.favorites.main.addBrands([brand.id])

      if (hasNotification) {
        this.gaApp.services.favorites.notifications.success(brand, ADD, BRANDS)
      }
    } catch (error) {
      this.gaApp.services.favorites.brands.removeItem(brand.id)
      this.gaApp.stores.favorites.main.brandsMap[brand.id] = false
      this.gaApp.services.favorites.notifications.failure(brand, ADD)
    }
  }

  async deleteBrands(brand, hasNotification) {
    this.gaApp.services.favorites.brands.removeItem(brand.id)
    this.gaApp.services.favorites.brands.setItemEx(brand.id)
    this.gaApp.stores.favorites.main.brandsMap[brand.id] = false

    try {
      await this.gaApp.repositories.favorites.main.deleteBrands([brand.id])

      if (hasNotification) {
        this.gaApp.services.favorites.notifications.success(brand, DEL, BRANDS)
      }
    } catch (error) {
      this.gaApp.services.favorites.brands.setItem(brand.id)
      this.gaApp.stores.favorites.main.brandsMap[brand.id] = true
      this.gaApp.services.favorites.notifications.failure(brand, DEL)
    }
  }
}
