import { ApiService } from './api.service'
import { MainService } from './main.service'
import { PageService } from './page.service'
import { ThankYouService } from './thank-you'

export const services = (gaApp) => ({
  main: new MainService(gaApp),
  api: new ApiService(gaApp),
  page: new PageService(gaApp),
  thankYou: new ThankYouService(gaApp),
})
