export const state = () => {
  return {
    maps: {
      apiKey: null,
      apiStaticUrl: null,
      searchUrl: null,
    },

    mainCity: null,

    minimumAge: null,
    adultAge: null,

    baseUrl: null,
    languageDefaultCode: null,
    languages: [],

    stores: [],

    phoneCodes: [],

    phones: {},

    emails: {},

    messengers: {},
    messengersArr: [],

    toggle: {},

    socials: {
      facebook: {
        text: 'Facebook',
        href: 'https://www.facebook.com/goldapple.ru',
      },
      instagram: {
        text: 'Instagram',
        href: 'https://www.instagram.com/goldapple',
      },
      telegram: {
        text: 'Telegram',
        href: 'https://t.me/goldapple_ru',
      },
      vkontakte: {
        text: 'Вконтакте',
        href: 'https://vk.com/goldapple_ru',
      },
      youtube: {
        text: 'YouTube',
        href: 'https://www.youtube.com/channel/UC5vsg5-xOIT0N-jilqFSz_w',
      },
    },

    marketApps: [],

    apps: {
      android: {
        text: 'Google Play',
        href: 'https://play.google.com/store/apps/details?id=goldapple.ru.goldapple.customers',
      },
      ios: {
        text: 'App Store',
        href: 'https://itunes.apple.com/ru/app/id1154436683',
      },
      huawei: {
        text: 'App Gallery',
        href: 'https://appgallery.huawei.com/app/C103551593',
      },
      rustore: {
        text: 'Ru Store',
        href: 'https://goldapple.onelink.me/ALHv/zu8cwfwh',
      },
    },

    openedPageInModal: '',

    contactsModalOpened: false,
    contactsModalAnchor: null,

    ticker: {
      has: false,
      gap: null,
      position: null,
    },

    emailRegexp: '',
  }
}
