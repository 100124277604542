export const TYPE = {
  CHECKBOX: 'checkType',
  RANGE: 'rangeType',
  CHECKLIST_TREE: 'categoryType',
  CHECKLIST_MULTIPLE: 'multipleSelectionType',
  CHECKLIST_SINGLE: 'singleSelectionType',
  CHECKLIST_ONLY: 'onlySelectionType',
  SORTING: 'sortType',
  QUERY: 'queryType',
  LOCALY: 'localyType',
  QUICK: 'quickType',
}

export const TYPES_RESET_IMMUNITY = [TYPE.QUERY]
export const FILTER_PAGE_NUMBER = {
  id: 'pageNumber',
  key: 'p',
  type: TYPE.LOCALY,
}

export const TYPES_MAIN = [
  TYPE.CHECKLIST_TREE,
  TYPE.CHECKLIST_MULTIPLE,
  TYPE.CHECKLIST_SINGLE,
  TYPE.CHECKLIST_ONLY,
  TYPE.CHECKBOX,
  TYPE.RANGE,
]

export const TYPES_MAIN_MOBILE = [...TYPES_MAIN, TYPE.SORTING]

export const FILTER_ID = {
  SORTING: 'sort',
  BRAND: 'brandobjectid',
  EXPRESS_DELIVERY: 'expressdelivery',
  PAGE_NUMBER: FILTER_PAGE_NUMBER.id,
  ORDER_TYPE: '_orderType',
  ORDER_YEAR: '_orderYear',
}

export const DEFAULT_T_PATHS = {
  applyButtonText: 'filters.applyButtonText',
}
