import { useContext } from '@nuxtjs/composition-api'

import { computed, ref } from 'vue'

import {
  DEFAULT_SEARCH_ICON_SIZE_DESKTOP,
  SEARCH_ICON_SIZE_DIFFERENCE,
} from '../consts'

export const useSqueezeAnimation = (emit) => {
  const { $gaApp } = useContext()

  const isDesktop = computed(() => $gaApp.mq('desktop+'))

  const iconSize = ref(DEFAULT_SEARCH_ICON_SIZE_DESKTOP)

  const onSearchBarAnimated = ({ progress }) => {
    if (!isDesktop.value) {
      return undefined
    }

    iconSize.value =
      DEFAULT_SEARCH_ICON_SIZE_DESKTOP - SEARCH_ICON_SIZE_DIFFERENCE * progress

    emit('animated', progress)
  }

  const iconStyle = computed(() => {
    if (!isDesktop.value) {
      return undefined
    }

    const pixelSize = `${iconSize.value}px`

    return {
      height: pixelSize,
      width: pixelSize,
    }
  })

  return {
    iconStyle,
    onSearchBarAnimated,
  }
}
