import { AUTOMATED_TESTING_ADDRESS_CONFIRMED } from '@ga/constants/automated-testing'

import pkg from '../../package.json'

import { NuxtBase } from './base'

export class NuxtClient extends NuxtBase {
  initAppInterceptors() {
    this.gaApp.api.addRequestCallback({
      success: (config) => {
        if (config.method === 'get' && this.gaApp.i18n.locales.length > 1) {
          config.params = {
            ...config.params,
            locale: this.gaApp.i18n.locale.code,
          }
        }

        return config
      },
    })
  }

  /**
   * Метод инициализации запроса на использование кук
   *
   */
  initCookiePermissions() {
    this.gaApp.services.app.cookiePermission.init()
  }

  /**
   * Метод проверки поддержки media (hover)
   */
  detectHoverMedia() {
    this.gaApp.services.app.browserWindow.detectWindowHoverMedia()
  }

  /**
   * Метод инициализации слушателя события `orientationchange` и `resize`
   */
  initWindowResizeListener() {
    this.gaApp.services.app.browserWindow.bindWindowResizeListeners()
  }

  /**
   * Метод инициализации слушателей для событий
   * keyboard, mousedown, mouseup, click, keydown, focusin
   */
  initKeyboardAndPointerListerner() {
    this.gaApp.services.app.browserWindow.bindKeyboardAndPointerListerners()
  }

  /**
   * На каждый переход провряем, требует ли роут авторизации
   */
  initRouterGuard() {
    this.gaApp.router.beforeEach(async (to, from, next) => {
      return await this.gaApp.services.auth.main.checkRouteAuth(to, from, next)
    })
  }

  /**
   * Метод проверки на модалку в урле(#auth и тд)
   */
  setModalRouterHook() {
    this.gaApp.router.afterEach((to, from) => {
      this.gaApp.services.modal.main.checkRouter(to, from)
    })
  }

  /**
   * Инициализация модального окна "скачать приложение"
   */
  initAdvModal() {
    this.gaApp.services.modal.advModal.init()
  }

  initCurrentBreakpoint() {
    this.gaApp.services.app.window.setCurrentBreakpoint()
  }

  initEyezon() {
    this.gaApp.services.eyezon.main.init()
  }

  /**
   * Метод предположения локации пользователя
   */
  getUserLocation() {
    if (
      this.gaApp.isAutomatedTesting ||
      this.gaApp.automatedTestingConfig?.[AUTOMATED_TESTING_ADDRESS_CONFIRMED]
    ) {
      return
    }

    this.gaApp.services.location.main.getData()
  }

  /**
   * Метод получения корзины пользователя
   */
  getUserCart() {
    this.gaApp.services.cart.user.subscribeOnUser()

    // this.gaApp.services.cart.main.fillData()
    this.gaApp.services.cart.main.getData()
  }

  /**
   * Метод получения подтверждения о 18+
   */
  getUserProofAge() {
    // this.gaApp.services.cart.main.fillData()
    this.gaApp.services.user.main.getProofAge()
  }

  /**
   * Метод устанавливает id-сеанса пользователя
   */
  setUserViewGUID() {
    this.gaApp.services.user.main.setUserViewGUID()
  }

  /**
   * Метод инициализации авторизации
   */
  async initAppAuth() {
    // Заупскаем инициализацию авторизации
    try {
      this.gaApp.services.auth.interceptors.register()

      await this.gaApp.services.auth.core.init()
    } catch (error) {
      console.error(error)
    }
  }

  /**
   * Метод инициализации аналитик
   */
  initAnalytics() {
    this.gaApp.analytics.libs.initDataLayer()
    this.gaApp.analytics.libs.initAllWithPermissionsChecks()
  }

  /**
   * Метод инициализации дебаг панели
   */
  async initDebugPanel() {
    if (this.gaApp.config.get('isDebugPanelEnabled')) {
      await this.gaApp.services.debugPanel.main.init()
    }
  }

  /**
   * Метод заполнения сторов первичными данными страницы
   */
  async prepareData() {
    await Promise.allSettled([
      this.gaApp.services.featuresToggle.main.init(),
      this.gaApp.services.app.api.getCommonData(),
    ])

    this.gaApp.services.header.api.fetchNavigation()

    this.gaApp.services.location.main.setLocationDefault()
    // this.getUserCart()
    this.getUserProofAge()
  }

  prepareClientData() {
    const getRequests = () =>
      [
        !this.gaApp.services.app.main.isSSR && this.prepareData(),
        this.gaApp.services.cache.main.initCacheStorage(),
      ].filter(Boolean)

    return Promise.allSettled(getRequests())
  }

  // eslint-disable-next-line max-statements
  async init() {
    this.gaApp.api.addRequestCallback({
      success: (config) => {
        config.headers.common['x-app-version'] = pkg.version

        return config
      },
    })

    this.gaApp.services.app.main.setStartTime()

    this.initAppInterceptors()

    this.gaApp.services.app.main.fillDeviceId()

    await this.prepareClientData()

    this.initDebugPanel()

    this.initWindowResizeListener()

    this.initCurrentBreakpoint()

    this.detectHoverMedia()
    this.initKeyboardAndPointerListerner()

    this.setLocale()

    this.setUserViewGUID()

    await this.initAppAuth()
    this.gaApp.services.location.main.watchInitialLocationChange()
    this.getUserLocation()
    this.getUserCart()
    this.gaApp.services.location.main.setWatchUser()

    this.initCookiePermissions()

    this.initRouterGuard()

    this.setModalRouterHook()

    this.initAnalytics()
    this.initEyezon()

    this.initAdvModal()

    this.gaApp.router.afterEach(() => {
      if (!this.gaApp.isInit) {
        this.gaApp.eventBus.publish('module/app/init')

        this.gaApp.stores.app.main.init = true

        this.gaApp.services.app.main.updateAppVersion()

        const { isSSR, withHover } = this.gaApp.stores.app
        const { currentBreakpoint, windowSize } = this.gaApp.stores.app.window

        this.gaApp.services.app.apm.setCustomContext({
          app: {
            isSSR,
          },
        })

        this.gaApp.services.app.apm.setCustomContext({
          device: {
            withHover,
            currentBreakpoint,
            ...windowSize,
          },
        })
      }
    })
  }
}
