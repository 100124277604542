import { analytics } from './analytics'
import * as components from './components'
import { locales } from './locales'
import { repositories } from './repositories'
import { routes } from './routes'
import { services } from './services'
import { stores } from './store'

export { buildCorporativeLink } from './helpers'
export { GaGiftCardsB2bExpandTransition } from './components/atoms/expand-transition'

export const giftCardsB2b = {
  meta: {
    name: 'giftCardsB2b',
    locales,
    routes,
  },
  components,
  layers: {
    stores,
    services,
    repositories,
    analytics,
  },
}
