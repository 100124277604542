import { computed, toRefs } from 'vue'

import { propValidator } from '../../../utils'
import { GaProductCardCart } from '../../molecules/cart'

import { useBehavior } from './scripts/composables/behavior'
import { counterAdapter as schemaCounterAdapter } from './scripts/schemas'

// @vue/component
export default {
  name: 'ga-product-card-counter-adapter',

  components: {
    GaProductCardCart,
  },

  props: {
    data: {
      type: Object,
      default: () => ({}),
      validator: (value) => propValidator(value, schemaCounterAdapter),
      required: true,
    },

    cancelDefaultUpdateQty: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const {
      cancelDefaultUpdateQty,
      loading: externalLoading,
      data,
    } = toRefs(props)

    const {
      quantity,
      onCartClick,
      onCartIncrease,
      onCartDecrease,
      loading: internalLoading,
    } = useBehavior(data, cancelDefaultUpdateQty, emit)

    /**
     * Слушаем внешний loading, если поведение каунтера определяется снаружи
     * Слушаем внутренний loading, если используется логика зашитая в этот адаптер - поведение по-умолчанию
     */
    const isLoading = computed(
      () => externalLoading.value || internalLoading.value,
    )

    return {
      quantity,
      isLoading,

      onCartClick,
      onCartIncrease,
      onCartDecrease,
    }
  },
}
