import { PROMOTION_TYPE } from '../constants/keys'

import { EVENT_CATEGORIES, MODULE_TYPES, TYPE } from './../analytics/constants'

export class MainService {
  constructor(gaApp) {
    this.gaApp = gaApp
    this.subscribeToEventBus()
  }

  async loadPromoInfo(promoId) {
    await this.gaApp.services.promo.api.fetchPromoInfo(promoId)
  }

  async loadOfferInfo(promoId) {
    await this.gaApp.services.promo.api.fetchOfferInfo(promoId)
  }

  async loadBrands(promoId) {
    await this.gaApp.services.promo.api.fetchBrands(promoId)
  }

  getItemListName(current, next) {
    if (next.includes(current)) {
      return next
    }
    if (!next) {
      return current
    }
    return current ? `${current}/${next}` : next
  }

  // отправка GA аналитики
  async sendGaAnalytic({ url, nuxt }) {
    const { hash } = this.gaApp.route

    // получение данных редиректа для проверки на внутреннюю страницу
    const { data } = await this.gaApp.repositories.promo.main.getRedirectData({
      url,
    })

    // если это внутренняя страница (и не промо - тк пока не добавлено)
    if (data.success && data.moduleType !== MODULE_TYPES.PROMO) {
      // получаем данные страницы, на которую совершаем переход (конкретно для получения itemListName)
      const analyticData =
        await this.gaApp.analytics.modules.promo.getAnalyticByUrl({
          url: url.replace(hash, ''),
        })

      // получаем данные аналитики из сторы
      const pageAnalyticData = this.gaApp.stores.promo.main.analyticData

      // получаем данные GA аналитики
      const gaAnalytics = analyticData[TYPE.GAA] ? analyticData[TYPE.GAA] : null

      // отправляем GA аналитику
      this.gaApp.analytics.modules.promo.onContentButtonClick({
        ...this.gaApp.stores.promo.main.analyticData,
        url,
        itemListName: this.getItemListName(
          pageAnalyticData.itemListName,
          gaAnalytics.itemListName,
        ),
        type: data?.moduleType,
        pageType: data?.pageType,
        itemId: data?.entityId,
      })
    }
    this.gaApp.stores.promo.main.analyticData = {}

    // делаем принудительный переход
    if (nuxt) {
      this.gaApp.services.app.router.push(url)
    } else {
      window.location = url
    }
  }

  onMainActionClick({ actionLabel, buttonText, buttonLink }) {
    const eventCategory =
      this.gaApp.stores.promo.main.info.type === PROMOTION_TYPE.CLIENT_DAYS
        ? EVENT_CATEGORIES.CLIENT_DAYS
        : EVENT_CATEGORIES.PROMO

    this.gaApp.analytics.modules.promo.onMainActionClick({
      eventCategory,
      actionLabel,
      buttonLink,
      buttonText,
    })
  }

  // пока страница promo не добавлена в редирект - оставляем, после добавления нужно убрать
  subscribeToEventBus() {
    this.gaApp.eventBus.subscribe('module/app/router/page-created', (data) => {
      if (Object.keys(this.gaApp.stores.promo.main.analyticData).length === 0) {
        return
      }

      // отправляем GA аналитику только если это страница деталки PROMO
      if (data.moduleType === MODULE_TYPES.PROMO) {
        this.gaApp.analytics.modules.promo.onContentButtonClick({
          ...this.gaApp.stores.promo.main.analyticData,
          type: data.moduleType,
          pageType: data.pageType,
        })
      }
    })
  }

  setMeta({ metaTitle, metaDescription, inListing }) {
    this.gaApp.stores.promo.main.metaTitle = metaTitle
    this.gaApp.stores.promo.main.metaDescription = metaDescription
    this.gaApp.stores.promo.main.inListing = inListing
  }

  setAnalyticData(value) {
    this.gaApp.stores.promo.main.analyticData = value
  }

  setPrevPagePromo(payload) {
    this.gaApp.stores.promo.main.isPrevPagePromo = payload
  }

  resetStores() {
    this.gaApp.stores.promo.main.$reset()
  }
}
