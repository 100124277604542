export class ApiService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  async getAuthTypes() {
    const { data } = await this.gaApp.repositories.auth.main.getAuthTypes()

    return data
  }

  async sendCode(data, parameters) {
    const result = await this.gaApp.repositories.auth.main.sendCode(
      data,
      parameters,
    )
    return result
  }

  async resendCode(data, parameters) {
    const result = await this.gaApp.repositories.auth.main.resendCode(
      data,
      parameters,
    )

    return result
  }

  async sendCodeV2(data, parameters) {
    const result = await this.gaApp.repositories.auth.main.sendCodeV2(
      data,
      parameters,
    )
    return result
  }

  async resendCodeV2(data, parameters) {
    const result = await this.gaApp.repositories.auth.main.resendCodeV2(
      data,
      parameters,
    )

    return result
  }

  async refreshTokens(data, parameters) {
    const result = await this.gaApp.repositories.auth.main.refreshTokens(
      data,
      parameters,
    )

    return result
  }

  async login(data, parameters) {
    const headersUser = this.gaApp.services.auth.main.getUserData()

    const params = {
      ...parameters,
      headers: { ...headersUser, ...parameters.headers },
    }

    const result = await this.gaApp.repositories.auth.main.login(data, params)

    return result
  }

  async logout(data, parameters) {
    this.gaApp.services.location.confirm.setAddressConfirmed(false)
    const result = await this.gaApp.repositories.auth.main.logout(
      data,
      parameters,
    )

    return result
  }
}
