import { ANALYTICS_EVENT_TYPE } from '../../../../constants'
import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'

export class GtmGa4ShowActiveButtonOrderModel extends GtmBaseModel {
  constructor() {
    super({})

    this.action_type = 'show_active_button_order'
    this.type = ANALYTICS_EVENT_TYPE.KPPK
  }
}
