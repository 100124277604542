import { AR_QA_FEATURES } from './ar-QA.features'
import { EN_QA_FEATURES } from './en-QA.features'
import { RU_BY_FEATURES } from './ru-BY.features'
import { RU_KZ_FEATURES } from './ru-KZ.features'
import { RU_RU_FEATURES } from './ru-RU.features'

export const FT_UPDATE_TIME = 5 // минуты
export const FT_LAST_UPDATE_LOCAL_STORE_KEY = 'module-ft-last-update'

export const STORE_FEATURES_INITIAL = {
  modalCookiesPermission: false, // включает модалку/панель запроса разрешения на использование кук
  modalCookiesPermissionSwitchToPanel: false, // переключает запрос разрешения на использование кук с модалки на панель внизу
  сookiesNotificationPanel: false, // включает панель оповещения об использовании кук
  giftCardsB2bNewLink: true, // Включает лендинг КПК
  giftCardsB2bNewPlastic: false, // Включает прямую ссылку на страницу КППК (если выключен giftCardsB2bNewLink)
  reviewFormPreloadImage: false, // Включает загрузку изображений в превью перед загрузкой изображений на сервер
  isReviewAvailable: true, // открывает функционал отзывов
  reviewShowButtonToForm: true, // показывать кнопку для перехода на форму отзыва без блока отзывов
  reviewProductsAvailable: false, // показывать модалку с продуктами для отзыва
  includeGoogleMaps: false, // включает подключение гугл карт
  giftCardsNewReceiveColors: true, // Включает новые цвета на странице получения подарочной карты
  giftCardsReceiveTermShown: false, // Включает отображение срока подарочных карт при получении
  giftCardsReactions: false, // Включает реакции при получении карты
  geoPolygonDeliveryAreas: false, // включает гео-полигоны
  giftCardsUnlimitedTerm: false, // устанавливает ограничение срока подарочных карт как "бессрочно"
  giftCardsB2bReceivePage: false, // включает отдельную страницу получения подарочных карт для b2b
  pdpHowToShortVideoOn: false, // добавляет короткие видео в галерею pdp
  pdpHowToLongVideoOn: true, // открывает слайдер с видеоплеером на пдп
  pdpHowToShortVideoLoop: false, // добавляет зацикливание коротких видео в галерею pdp
  pdpHideAvailableStore: false, // скрытие кнопки доступных офлайн магазинов в pdp
  plpHowToShortVideoOn: false, // добавляет короткие видео в карточки plp
  plpHowToShortVideoOnlyOne: false, // воспроизводит только одно видео в очереди воспроизведения plp
  promoUrlKey: false, // включает ЧПУ формирование url для страниц промо
  sharingCart: false, // Включает функционал "поделиться корзиной"
  customBrandzoneEnabled: true, // Включет функционал кастомных брендзон
  giftCardsBalanceFractions: false, // включает дробные значения баланса карты на странице баланса ПК
  giftCardsNewPlasticLink: false, // включает новую ссылку на пластиковые подарочные карты
  setNearestCityWithStores: false, // Передача кординат для сортировки городов по ближайшему (офффлайн магазины на пдп)
  giftCardsB2bNewWhatsAppSupportLink: false, // изменяет ссылку поддержки WhatsApp на новую
  giftCardsB2bLandingFaq: false, // включает раздел FAQ на лендинге КПК
  giftCardsNewPayment: false, // включает выбор способа оплаты при оформлении ЭПК
  giftCardsApplePay: false, // включает Apple Pay при оплате ЭПК
  giftCardsPayConfigFetch: false, // включает получение конфига для оплаты ЭПК и отключает получение сохраненных карт только для авторизованного пользователя
  enableLoyaltyProgress: false, // включает прогресс бар на карте лояльности в лк
  giftCardsAboutNewText: false, // включает измененный текст на странице /cards/about
  giftCardsMainFetch: false, // включает получение онбординга для главной /cards
  giftCardsMenuFetch: false, // включает перенос управления меню ПД на бэк
  giftCardsHintsWithWhatsapp: false, // добавляет whatsapp в текстовки при оформлении ЭПК
  plpProductCardUniversal: false, // отображение универсальной карточки продукта
  eyezonV2: false, // Включает айзон
  brandsFakeItemsRendering: false, // облегченный рендеринг брендов на основе псевдо-item
  giftCardsDesignTypes: false, // Включает выбор типа дизайна ЭПК при оформлении
  giftCardsDesignTypesNewLabel: false, // Включает отображение лейбла NEW в табах выбора дизайна ЭПК
  giftCardsCardTypesInTabs: false, // Включает выбор типа карты в виде табов
  giftCardsIndependentNav: true, // Включает независимую навигацию для подарочных карт
  giftCardsBannerMenuShown: false, // Включает меню в баннере на главной карт
  profileExpirePoints: false, // Включает фичу сгорающих юонусок в лк
  pdpBrandzoneSlots: false, // Включает слоты брендзоны в pdp
  plpCoupon: false, // Включает плашку "промокод" на plp
  plpCouponV2: false, // Включает плашку "промокод" версии 2 на plp
  giftCardsPricePromptBubbles: false, // Включает отображение подсказок при выборе номинала
  benefitsModal: false, // Включает новую модалку для бенефитов
  articlesHowToShortVideoOn: false, // Добавляет короткие видео в карточки статей
  flaconAMP: false, // Включает добавление мета тегов линковки на amp версию
  profileMenuFaqLink: false, // Включает отображение ссылки "вопросы-ответы" в меню ЛК
  locationAddressFormEditUnification: false, // унификация формы редактирования адреса
  suggestTwoLines: false, // Включает отображение подсказки на чекауте в странах без карты при вводе адреса доставки в поле и в расширенном адресном блоке на главной
  flaconUseStreamers: false, // Включает стримы для флакона
  reviewGalleryDesktopZoom: false, // Включает зум слайдов на десктопе в слайдере отзывов
  navIndependentScroll: false, // Включает независимый скролл уровней навигации хедера
  pdpCoupon: false, // Включает плашку "промокод" на pdp
  pdpHideDescription: false, // Включает скрытие описания на pdp
  pdpGalleryLabelTooltipOff: false, // Отключает тултипы у лейблов пдп галереи
  pdpPlayerRedesign: false, // Включает новый дизайн видео плейера
  pdpDeliveryFullInfo: false, // Новое отображение экспресс доставки и по клику на PDP
  newBenefits: false, // Включает запрос бенефитов пдп из отдельной ручки v2
  downloadAppModal: false, // Включает модалку "скачать приложение"
  hasQueueModals: false, // включает очередь модалок при старте приложения
  bzWidgetHowToLongVideo: false, // Включает виджет слайдера видео в брендзоне
  productCardPostRenderElements: false, // Включает рендринг некоторых элементов карточки в фоновом режиме
  bzWidgetCategory: false, // Включает виджет категории в брендзоне
  yandexMetrikaScript: false, // Включает установку скрипта яндекс метрики
  bzHowToShortVideoOn: false, // добавляет короткие видео в листинг брендзоны
  bzWidgetPromo: false, // Включает виджет промо слайдера в брендзоне
  storeSwitcher: false, // меняет переключатель яызка в хедере на переключатель стора
  replaceCountrySelectWithModal: false, // заменяет дропдаун в селекте выбора страны в модалке адресов на  модалку
  tooltipResponsiveV2: false, // переключает компонент тултипа на версию v2
  plpDolyame: false, // Включает плашку долями на plp
  gaAnalyticsV2: false, // Включает ga-аналитику версии 2
  showSortByRating: false, // Разрешает отображать сортировку по рейтингу на plp
  profileChangingDeliveryDate: false, // Включает фичу изменения даты доставки в лк
  profileChangingStoragePeriod: false, // Включает фичу изменения cрока хранения в лк
  bzSlotsV2: false, // Включает использование ручки V2 для слотов в брендзоне
  yandexSplit: false, // Включает новый текст для /bnpl и модалки подели на пдп
  gameMobileUserInfoFull: false, // Включает mobile эндпоинт, если игра открыта через мобильное приложение
  isPdpListLineBreak: false, // Включает перенос возможность переноса строки у list-separator в pdp
  giftCardsPlasticCorpNewLink: false, // Включает новый интерфейс оформления заявок в разделе КППК
  plpRating: false, // Включает рейтинг на PLP и BZ
  visitedPlacement: false, // Скрываем/показываем плейсмент 'Вы смотрели' в зависимости от id
  isNewTextEyezonPdp: false, // Новый текст для eyezon pdp
  placementUnavailablePdp: false, // Поднимаем блок "похожие товары" над описанием товара.
  mergingJSONandWidgetsForBZ: false, // Включает использование ручки V3 для слотов в брендзоне
  vat: false, // Включает надпись НДС в pdp, корзине и на TYP
  pdpPriceNewPrice: false, // Новая верстка цены в pdp
  productCardNewPrice: false, // Новая верстка цены в карточке товара
  pdpDolyameKz: false, // Включает плашку с рассрочкой на pdp
  newWelcomeScreen: false, // Новый дизайн велком-модалок с предварительной проверкой наличия номера юзера в др странах
  vatProfile: false, // Включает надпись НДС в заказах ЛК
  enableProfileBanner: false, // Включает отображение баннера повышенных бонусов в ЛК,
  cartPlaidApi: false, // Включает плейдовские ручки для корзины
  updateUserLanguagePlaid: false, // Включает плейд ручку изменения языка
  fractionalDigitsByDenominator: false, // Если ВКЛ, минимальное кол-во знаков после запятой в ценах и бонусах, если не указано явно, регулируется деноминатором
  plpReversedContentMilan: false, // Развернуть выравнивание контента по краю средней карточки в PLP
  flaconPromo: false, // Включает отображение рекламных банеров
  unavailableMePdp: false, // Заглушка для кросскантри
  profileEditFormRequiredFields: false, // Получение required полей для формы редактирования пользователя с бэка
  profileOrdersCountryFlag: false, // Включает отображение флага страны в заказах, в ЛК
  typHandleBonusDenominator: false, // Включает обработку деноминатора для бонусов на typ
  cartNewDesignGifts: false, // Включает новый дизайн подарков и секции подарков в корзине
  flaconUseStreamIframe: false, // Включает iframe страницу для стримов
  reviewFlagListing: false, // Включает отображение флага страны отзыва, в листинге отзывов (работает совместно с включенным reviewNewCardListing)
  reviewNewCardListing: false, // Включает отображение нового дизайна в листинге и модалке для отзывов
  pdpCounterAddingToCart: false, // Включает кнопку добавления в корзину с множественным добавлением на PDP
  pdpUnavailableCrosscountry: false, // Новый вид pdp при AvailableInOtherCountry.
  plpCounterAddingToCart: false, // Включает кнопку добавления в корзину с множественным добавлением на PLP
  bnplHideProductList: false, // Выключает отображение списка товаров на странице /bnpl
  flaconUseCarouselVertical: false, // Разрешает использование вертикального слайдера в компоненте редактора
  analyticsInitWayV2: false, // Инит аналитики альтернативным путём V2
  emptyCartUrlFromAPI: false, // Включает использование ссылки на новые поступления в пустой корзине из API
  showLoyaltyCardScreens: false, // Включает новый флоу выдачи карты лояльности
  authorizationTypesEnabled: false, // Включает запрос типов аторизации и новый вид стартового шага модалки авторизации
  redirectToGoCheckout: false, // При нажатии на кнопку "оформить заказ" перенаправляет пользователя на страницу /gocheckout вместо /checkout.
  flaconUseFiltersChips: false, // Использует общий компонент чипсов в фильтрах листингов
  plpDolyameInstallment: false, // Включает новую плашку с рассрочкой на plp (текст из локалей, без цены)
  flaconUseMobilePromoParallax: false, // Разрешает использование параллакс эффекта для мобильных промо баннеров
  profileWebQR: false, // Включает отображения qr-кода карты лояльности в ЛК
  bnplProductCardBadgeV2: false, // Включает использование компонентов модуля BNPL (бейдж "долями" на PLP)
  bnplTabbyAePlpPdp: false, // Включает использование бейджа "долями" для провайдера Tabby на PLP и PDP
  giftCardsPlasticCorpEnableInputChips: false, // Включает чипсы в инпуте номинала КППК
  giftCardsPlasticCorpFormTargetDesign: false, // Включает целевой дизайн формы заявки на КППК
  bzAlwaysSkeleton: false, // Включает показ скелетонов для слотов в брендзонах
  cartShowFractionDigits: false, // Включает отоюражение копеек на корзине
}

export const STORE_FEATURES_BY_REGION = {
  'ru-RU': {
    ...RU_RU_FEATURES,
  },
  'ru-BY': {
    ...RU_BY_FEATURES,
  },
  'ru-KZ': {
    ...RU_KZ_FEATURES,
  },
  'en-QA': {
    ...EN_QA_FEATURES,
  },
  'ar-QA': {
    ...AR_QA_FEATURES,
  },
  'en-AE': {
    ...EN_QA_FEATURES,
  },
  'ar-AE': {
    ...AR_QA_FEATURES,
  },
  'en-SA': {
    ...EN_QA_FEATURES,
  },
  'ar-SA': {
    ...AR_QA_FEATURES,
  },
}
