import isString from 'lodash/isString'

import { getPhoneWithoutMask } from '@ga/utils'

import { getPhoneRegionCode, getPhoneRegionCodes, withMessage } from '../utils'

/**
 * Проверят номер телефона на соответствие текущему региону. Если правила для региона не определены, то возвращает true.
 *
 * @param {object} locale - данные локализации
 * @param {string} [message] - сообщение при ошибке
 */
export const phoneLocale = (locale, message) => {
  const validator = (value) => {
    if (!locale?.iso || !isString(value) || !value.trim()) {
      return true
    }

    const clearPhoneNumber = getPhoneWithoutMask(value, 'withPlus')
    const phoneRegionCode = getPhoneRegionCode(locale.iso, clearPhoneNumber)
    const allowedPhoneRegionCodes = getPhoneRegionCodes(locale.iso)

    if (phoneRegionCode === null || allowedPhoneRegionCodes === null) {
      return true
    }

    return allowedPhoneRegionCodes.includes(phoneRegionCode)
  }

  return withMessage(validator, {
    locale,
    message: 'app.validation.phone',
    type: 'phone-locale',
  })(message)
}
