export class MainRepository {
  constructor(gaApp, endpoints) {
    this.gaApp = gaApp

    this.endpoints = gaApp.api.endpoints(endpoints)
  }

  /**
   * Получение данных TYP.
   * @param {object} params - параметры запроса данных TYP.
   * @param {string} params.maskedId - id заказа.
   * @param {boolean} params.isPlaid - заказ находится в Plaid.
   */
  async getThankYou(params) {
    const { url } = this.endpoints.thankYou()

    try {
      const response = await this.gaApp.api.request().get(url, { params })

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }
}
