import { useContext } from '@nuxtjs/composition-api'

import { computed } from 'vue'

import { MEDIA_TYPES } from '../../../../../constants'

export const useMediaType = () => {
  const { $gaApp } = useContext()

  const mediaType = computed(() => {
    if ($gaApp.mq('desktop+')) return MEDIA_TYPES.DESKTOP

    if ($gaApp.mq('tablet+')) return MEDIA_TYPES.TABLET

    return MEDIA_TYPES.MOBILE
  })

  return {
    mediaType,
  }
}
