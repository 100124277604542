/**
 * Временное решение пока формат цен на бэкенде переходит с формата
 * {
 *   amount: 59.15,
 *   currency: 'BYN',
 *   denominator: 1,
 * }
 *
 * На
 * {
 *   amount: 5915,
 *   currency: 'BYN',
 *   denominator: 100,
 * }
 *
 * Сделано так как после включения фича тоггла fractionalDigitsByDenominator, отображение цен на корзине сломается
 */

export class CurrencyService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  /**
   * Получение количества знаков после затяпой
   */
  getMinimumFractionDigits() {
    return this.gaApp.features.get('cartShowFractionDigits') ? 2 : null
  }
}
