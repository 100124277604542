import { ACTIONS } from '../../../constants'
import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'

export class GtmGa4StreamWatchClickModel extends GtmBaseModel {
  constructor(data) {
    super(ACTIONS.USER_INTERACTION.GA4)

    const { eventCategory, actionType, streamName, state } = data

    this.event_category = eventCategory
    this.action_type = actionType

    this.stream_name = streamName
    this.state = state
  }
}
