import { MODULE_TYPE } from '../constants/keys'

import { PAGE_TYPE } from './../constants/pages'

export class BrandsService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  // метод нужен для перехода из брендов исключения лиюо в акцию, либо на страницу листинга
  onBackLinkClick = () => {
    // проверяем есть ли в ссылке url акции для возвращения
    if (this.gaApp.route.query?.referer?.length) {
      this.gaApp.services.app.router.pushToRoute({
        moduleType: MODULE_TYPE,
        pageType: PAGE_TYPE.OFFER_PAGE,
        params: {
          id: this.gaApp.route.query?.referer,
        },
      })
    } else {
      // если нет - кидаем на листинг акций
      this.gaApp.services.app.router.pushToRoute({
        moduleType: MODULE_TYPE,
        pageType: PAGE_TYPE.OFFERS_LISTING,
      })
    }
  }
}
