import { isTruthyIncludingZero } from '@ga/utils'

import { TYPE } from '../../constant'

export class QueryParseService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  filterQueryValued(filter, queryValue) {
    const filterInternal = {
      id: filter.id,
      key: filter.key,
      type: filter.type,
    }

    switch (filter.type) {
      case TYPE.RANGE: {
        const [min, max] = queryValue.split('-')

        if (!isNaN(+min) && !isNaN(+max)) {
          filterInternal.currentMinValue = {
            ...filter.currentMinValue,
            amount: min,
          }
          filterInternal.currentMaxValue = {
            ...filter.currentMaxValue,
            amount: max,
          }
        } else {
          filterInternal.currentMinValue = filter.currentMinValue
          filterInternal.currentMaxValue = filter.currentMaxValue
        }
        break
      }
      case TYPE.CHECKBOX: {
        filterInternal.value = Boolean(queryValue)
        break
      }
      case TYPE.SORTING: {
        filterInternal.valueId = queryValue
        break
      }
      case TYPE.CHECKLIST_SINGLE:
      case TYPE.CHECKLIST_ONLY: {
        filterInternal.value = queryValue
        break
      }
      case TYPE.CHECKLIST_TREE:
      case TYPE.CHECKLIST_MULTIPLE:
      case TYPE.QUICK: {
        filterInternal.values = queryValue
          .split(',')
          .filter(isTruthyIncludingZero)
        break
      }
    }

    return filterInternal
  }

  getFiltersFromQuery(filters = [], query = {}) {
    return filters
      .filter((filter) => query[filter.key])
      .map((filter) => this.filterQueryValued(filter, query[filter.key]))
  }
}
