import {
  AddSetClickBuilder,
  BackClickBuilder,
  ChangeDesignClickBuilder,
  ChangeDesignPlasticClickBuilder,
  ChangeInfoClickBuilder,
  ChangeInnClickBuilder,
  ChipClickBuilder,
  ClickChangeCityBuilder,
  ClickNewCityBuilder,
  ContactClickBuilder,
  CounterpartyUploadClickBuilder,
  DeliveryTypeClickBuilder,
  DownloadPoaClickBuilder,
  ExitClickBuilder,
  FindCounterpartyClickBuilder,
  MailConfirmedBuilder,
  MainClickBuilder,
  MenuClickBuilder,
  MenuItemClickBuilder,
  MoreClickBuilder,
  NextClickBuilder,
  OrderClickBuilder,
  OrderSendClickBuilder,
  PreviewClickBuilder,
  SaleRulesClickBuilder,
  ShowActiveButtonOrderBuilder,
  SuccessOrderBuilder,
  UploadPoaClickBuilder,
} from '../../builders'
import { BaseController } from '../base.controller/base.controller'

export class GiftCardsB2bAnalytics extends BaseController {
  /**
   * Клик по ссылке на главную страницу на странице лендинга
   */
  onMainClick() {
    this.send(MainClickBuilder)
  }

  /**
   * Клик по бургер-меню на странице лендинга
   */
  onMenuClick() {
    this.send(MenuClickBuilder)
  }

  /**
   * Клик по пункту меню на странице лендинга
   * @param {string} path надпись пункта меню
   */
  onMenuItemClick(path) {
    this.send(MenuItemClickBuilder, { path })
  }

  /**
   * Клик по ссылке оформления заявки на странице лендинга
   * @param {'header' | 'more' | 'footer' | 'menu'} place расположение ссылки
   * @param {'plastic' | 'digital'} type тип оформляемой заявки
   */
  onOrderClick(place, type) {
    this.send(OrderClickBuilder, { place, type })
  }

  /**
   * Клик по кнопке "подробнее" о типах карт на странице лендинга
   * @param {'plastic' | 'digital'} type тип отображаемой информации заявки
   */
  onMoreClick(type) {
    this.send(MoreClickBuilder, { type })
  }

  /**
   * Клик по контактам в подвале на странице лендинга
   * @param {'mail' | 'phone' | 'whatsapp'} type тип контакта
   */
  onContactClick(type) {
    this.send(ContactClickBuilder, { type })
  }

  /**
   * Клик по правилам продажи в подвале на странице лендинга
   */
  onSaleRulesClick() {
    this.send(SaleRulesClickBuilder)
  }

  /**
   * Клик по кнопки смены дизайна на странице КЭПК
   */
  onChangeDesignClick() {
    this.send(ChangeDesignClickBuilder)
  }

  /**
   * Клик по кнопке добавления наборов на страницах КЭПК/КППК
   * @param {'kepk' | 'kppk'} type тип страницы
   */
  onAddSetClick(type) {
    this.send(AddSetClickBuilder, { type })
  }

  /**
   * Клик по кнопке добавления наборов на странице КЭПК
   * @param {
   *  { success: 'yes' }
   *  | { success: 'no', error: string }
   * } options информация о запросе
   */

  /**
   * Клик по кнопке поиска контрагента на странице КЭПК/КППК
   * @param {{ success: 'yes' } | { success: 'no', error: string }} options  информация о запросе
   * @param {'kepk' | 'kppk'} type тип страницы
   */
  onFindCounterpartyClick(options, type) {
    this.send(FindCounterpartyClickBuilder, { ...options, type })
  }

  /**
   * Клик по кнопке предпросмотра поздравления на странице КЭПК
   */
  onPreviewClick() {
    this.send(PreviewClickBuilder)
  }

  /**
   * Клик по кнопке "далее" на страницах КПК (мобильная версия)
   * @param {number} step номер шага на котором пользователь нажал на кнопку
   * @param {'kepk' | 'kppk'} type тип страницы
   */
  onNextClick(step, type) {
    this.send(NextClickBuilder, { step, type })
  }

  /**
   * Клик по кнопке "далее" на странице КПК (мобильная версия)
   * @param {number} step номер шага на котором пользователь нажал на кнопку
   * @param {'kepk' | 'kppk'} type тип страницы
   */
  onExitClick(step, type) {
    this.send(ExitClickBuilder, { step, type })
  }

  /**
   * Клик по кнопке назад на странице КПК (мобильная версия)
   * @param {number} step номер шага на котором пользователь нажал на кнопку
   * @param {'kepk' | 'kppk'} type тип страницы
   */
  onBackClick(step, type) {
    this.send(BackClickBuilder, { step, type })
  }

  /**
   * Отправка заявки на заказ КПК
   * @param {'kepk' | 'kppk'} type тип страницы
   * @param {number} amount сумма заказа
   */
  onOrderSendClick(type, amount) {
    this.send(OrderSendClickBuilder, { type, amount })
  }

  /**
   * Заказ создан на странице КПК
   * @param {string} orderId номер заказа
   * @param {'kepk' | 'kppk'} type тип страницы
   */
  onSuccessOrder(orderId, type) {
    this.send(SuccessOrderBuilder, { orderId, type })
  }

  /**
   * Пользователь подтвердил email на странице КЭПК
   */
  onMailConfirmed() {
    this.send(MailConfirmedBuilder)
  }

  /**
   * Клик на кнопку загрузки файла реквизитов странице КППК
   */
  onCounterpartyUploadClick() {
    this.send(CounterpartyUploadClickBuilder)
  }

  /** Новый флоу КППК */

  /**
   * Клик по кнопке изменения города в навигации
   * @param {string} city текущий город
   * @param {string} place место расположения кнопки – header
   */
  onChangeCityClick(city, place) {
    this.send(ClickChangeCityBuilder, { city, place })
  }

  /**
   * Клик по кнопке выбора типа доставки
   * @param {'courier' | 'pickup'} delivery тип доставки
   * @param {place} place место расположения кнопки
   */
  onDeliveryTypeClick(delivery, place) {
    this.send(DeliveryTypeClickBuilder, { delivery, place })
  }

  /** Клик по названию города в модальном окне смены города
   * @param {string} city выбранный город
   */
  onNewCityClick(city) {
    this.send(ClickNewCityBuilder, { city })
  }

  /** Клик по лого дизайна КППК при смене дизайна
   * @param {string} id id дизайна
   */
  onChangeDesignPlasticClick(id) {
    this.send(ChangeDesignPlasticClickBuilder, { id })
  }

  /** Клик по кнопке Изменить в блоке ИНН
   *  @param {'kepk' | 'kppk'} type тип страницы
   */
  onChangeInnClick(type) {
    this.send(ChangeInnClickBuilder, { type })
  }

  /** Клик по кнопке Изменить в summary */
  onChangeInfoClick(step) {
    this.send(ChangeInfoClickBuilder, { step })
  }

  /** Клик по кнопке загрузки доверенности */
  onDownloadPoaClick() {
    this.send(DownloadPoaClickBuilder)
  }

  /** Клик по кнопке загрузки файла реквизитов */
  onUploadPoaClick() {
    this.send(UploadPoaClickBuilder)
  }

  /** Кнопка "Оформить заказ" стала активной на шаге 2 (десктоп) */
  onShowActiveButtonOrder() {
    this.send(ShowActiveButtonOrderBuilder)
  }

  /** Клик по чипcу суммы */
  onChipClick(amount, type) {
    this.send(ChipClickBuilder, { amount, type })
  }
}
