/**
 * Разбивает строку на подстроки определенной длины и добавляет между ними разделитель
 * @param {string} value строка для форматирования
 * @param {number} chunkSize длина подстроки
 * @param  {string} separator разделитель
 * @returns
 */
export const chunkString = (value, chunkSize, separator = ' ') => {
  // очищаем строку от возможных пробелов спереди и сзади
  const valueInternal = value.trim()

  if (!valueInternal || chunkSize < 1) return

  // регулярка диапазона подстроки
  const regExp = new RegExp(`(.{1,${chunkSize}})`, 'g')

  return (
    valueInternal
      // разделяем строку на чанки
      .split(regExp)
      // очищаем массив от пустых строк
      .filter((chunk) => chunk)
      // добавляем разделитель
      .join(separator)
  )
}
