export const PAGE_TYPE = {
  CART: 'cart',
  SHARE: 'share',
}

export const AUTH_MODAL_REASON_TO_OPEN = {
  LOGIN_TO_CHECKOUT_BY_CART: 'login-to-checkout-by-cart',
}

export const STORAGE_GUEST_CART_ID_KEY = 'ga-cart-id'

export const VIEW = {
  DEFAULT: 'default',
  OUTOFSTOCK: 'outofstock',
  GIFT: 'gift',
}

export const ORDER_SECTIONS = [VIEW.DEFAULT, VIEW.GIFT, VIEW.OUTOFSTOCK]
export const NOTIFICATION_LOCALE_PATH = {
  ERROR: {
    add: 'cart.error.add',
    delete: 'cart.error.delete',
  },

  SUCCESS: {
    add: 'cart.success.add',
    delete: 'cart.success.delete',
  },
}

export const NOTIFICATION_ACTION = {
  DEL: 'delete',
  ADD: 'add',
}

export const NOTIFICATION_SIZE = {
  S: 's',
  M: 'm',
}

// 0 - нет в стоке - определяется по признаку "section_code" == "outofstock"
// 1 - в стоке - если ни одно другое условие не сработало
// 2 - к-во в корзине == к-ву в стоке - "cant_add_more" == true
// 3 - к-во в корзине больше, чем есть в стоке - "more_than_in_stock" == true
export const ITEM_STATUS = {
  OUT_OF_STOCK: 0,
  IN_STOCK: 1,
  CANT_ADD_MORE: 2,
  MORE_THAN_IN_STOCK: 3,
}

export const DELETE_ALL = 'all'

export const DIALOG = {
  AUTH: 'auth',
  DELETE_SECTION: 'deleteSection',
  DELETE_ALL: 'deleteAll',
  DELETE_BEFORE_CHECKOUT: 'deleteBeforeCheckout',
  ITEM: 'item',
}

export const TEST_IDS = {
  [DIALOG.DELETE_ALL]: {
    title: 'ga-cart-confirm__delete-all-title',
    acceptButton: 'ga-cart-confirm__delete-all-accept-button',
    cancelButton: 'ga-cart-confirm__delete-all-cancel-button',
  },
  [DIALOG.AUTH]: {
    title: 'ga-cart-confirm__auth-title',
    acceptButton: 'ga-cart-confirm__auth-accept-button',
    cancelButton: 'ga-cart-confirm__auth-cancel-button',
  },
}

export const ERROR = {
  QUOTE_NOT_ACTIVE: 'quote_not_active',
}
