import * as components from './components'
import { locales } from './locales'

export const bnpl = {
  meta: {
    name: 'bnpl',
    locales,
  },
  components,
}
