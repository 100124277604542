export class PaymentApplePayService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  /** Флаг доступности Apple Pay */
  get isApplePayAvailable() {
    return window.ApplePaySession
  }

  /**
   * Устанавливает параметры для оплаты Apple Pay
   * @param {string} merchantId
   * @param {Array} merchantCapabilities
   * @param {Array} supportedNetworks
   */
  setApplePayParams({ merchantId, merchantCapabilities, supportedNetworks }) {
    this.gaApp.stores.giftCards.payment.applePayParams = {
      merchantId,
      merchantCapabilities,
      supportedNetworks,
    }
  }

  /**
   * Начало оплаты картой Apple Pay
   */
  startPayment() {
    throw new Error('Not implemented yet')
  }
}
