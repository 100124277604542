import { ref } from 'vue'

/**
 * Юс для анимации "тряски" контролов
 */
export const useShakeAnimation = () => {
  // Флаг, указывающий на то, что анимация запущена
  const isAnimating = ref(false)

  // Функция, запускающая анимацию
  const runAnimation = () => {
    if (isAnimating.value) {
      return false
    }

    isAnimating.value = true
  }

  // Функция, вызываемая по окончании анимации
  const onAnimationEnd = () => {
    isAnimating.value = false
  }

  return {
    isAnimating,

    runAnimation,
    onAnimationEnd,
  }
}
