// @vue/component
export default {
  name: 'ga-brands-letter',

  props: {
    letter: {
      type: String,
      required: true,
    },
    rtlIgnore: {
      type: Boolean,
      default: false,
    },
  },
}
