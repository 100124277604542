import { computed, toRefs, unref } from 'vue'

import { GaPrice } from '~/components/price'

import { propValidator } from '../../../utils'
import { price as priceSchema } from '../../../utils/schemas'

import { useStyle } from './scripts/composables'
import { HIGHLIGHT_THEME } from './scripts/consts'
import {
  priceSize as priceSizeSchema,
  testIds as testIdsSchema,
} from './scripts/schemas'

// @vue/component
export default {
  name: 'ga-product-card-price-row',

  components: {
    GaPrice,
  },

  props: {
    size: {
      type: Object,
      default: null,
      validator: (value) => propValidator(value, priceSizeSchema),
    },

    actual: {
      type: Object,
      default: null,
      validator: (value) => propValidator(value, priceSchema),
    },

    old: {
      type: Object,
      default: null,
      validator: (value) => propValidator(value, priceSchema),
    },

    prefix: {
      type: Boolean,
      default: false,
    },

    highlight: {
      type: Boolean,
      default: false,
    },

    highlightTheme: {
      type: String,
      default: HIGHLIGHT_THEME.DEFAULT,
      validator: (value) => Object.values(HIGHLIGHT_THEME).includes(value),
    },

    reversed: {
      type: Boolean,
      default: false,
    },

    showCurrency: {
      type: Boolean,
      default: false,
    },

    oldStrikethrough: {
      type: Boolean,
      default: false,
    },

    oos: {
      type: Boolean,
      default: false,
    },

    testIds: {
      type: Object,
      default: () => ({}),
      validator: (value) => propValidator(value, testIdsSchema),
    },
  },

  setup(props) {
    const { highlight, reversed, highlightTheme } = toRefs(props)

    const { style } = useStyle(props)

    const mods = computed(() => ({
      highlight: unref(highlight),
      reversed: unref(reversed),
      highlight_theme: unref(highlightTheme),
    }))

    return {
      style,
      mods,
    }
  },
}
