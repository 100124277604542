<template>
  <svg fill="none" viewBox="0 0 35 35">
    <path
      fill="#000"
      fill-rule="evenodd"
      d="M25 1h9v9h1V0H25v1ZM10 1V0H0v10h1V1h9ZM1 25H0v10h10v-1H1v-9Zm24 9v1h10V25h-1v9h-9Z"
      clip-rule="evenodd"
      opacity=".15"
    />
    <g opacity=".3">
      <path
        stroke="#000"
        stroke-width="1.3"
        d="M10.65 10.65h5.257v5.257H10.65z"
      />
      <path
        stroke="#000"
        stroke-width="1.3"
        d="M19.093 10.65h5.257v5.257h-5.257z"
      />
      <path fill="#000" d="M18.441 18.443h1.9v1.9h-1.9z" />
      <path fill="#000" d="M23.1 18.443H25v1.9h-1.9z" />
      <path fill="#000" d="M23.1 23.1H25V25h-1.9z" />
      <path fill="#000" d="M18.441 23.1h1.9V25h-1.9z" />
      <path fill="#000" d="M20.771 20.773h1.9v1.9h-1.9z" />
      <path
        stroke="#000"
        stroke-width="1.3"
        d="M10.65 19.092h5.257v5.257H10.65z"
      />
    </g>
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-customer-qr-button',
}
</script>
