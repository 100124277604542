import { NOTIFICATION_MODAL_TYPE } from '../../constants'

export const getters = (gaApp) => ({
  /**
   * Проверяет, есть ли данные в объекте для модального окна
   */
  hasData() {
    return this.data && !!Object.values(this.data).length
  },

  /**
   * Проверяет, нужно ли отобразить компонент как модальное окно
   */
  isModal() {
    return this.data.uiType === NOTIFICATION_MODAL_TYPE.MODAL
  },

  /**
   * Проверяет, нужно ли отобразить компонент как боттомшит на мобилках
   */
  isBottomsheet() {
    return this.data.uiType === NOTIFICATION_MODAL_TYPE.BOTTOMSHEET
  },

  /**
   * Проверяет, что модальное окно для показа отстутсвует
   */
  isModalNone() {
    return this.data.uiType === NOTIFICATION_MODAL_TYPE.NONE
  },

  /**
   * Проверяет, нужно ли показывать старое модальное окно
   */
  isModalOld() {
    return this.data.uiType === NOTIFICATION_MODAL_TYPE.MODALOLD
  },

  /**
   * Проверяет, разрешено ли показывать модальное окно
   */
  isAllowedToShow() {
    return gaApp.stores.user.main.isAuthorized && this.hasData
      ? this.isModal || this.isBottomsheet
      : false
  },
})
