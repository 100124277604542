import { ACTIONS } from '../../../constants'
import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'

export class GtmGa4ArticleScrollModel extends GtmBaseModel {
  constructor({ eventCategory, actionType, articleId, depth }) {
    super(ACTIONS.USER_INTERACTION.GA4)

    this.event_category = eventCategory
    this.action_type = actionType
    this.article_id = articleId
    this.depth = depth
  }
}
