import { attributes, hide, media } from '../../../../../utils/schemas'

export const counterAdapter = {
  type: 'object',
  properties: {
    itemId: { type: 'string', required: true },
    name: { type: 'string', required: true },
    brand: { type: 'string', required: true },
    attributes: {
      oneOf: [attributes, hide],
      required: true,
    },
    media: { ...media, required: true },
    configurable: { type: 'boolean', required: true },
    isAdult: { type: 'boolean', required: true },
    needOnlineConsultation: { type: 'boolean', required: true },
  },
}
