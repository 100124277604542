export const getCartStateInitial = () => ({
  id: null,
  isCacheData: false,
  sections: [],
  regularDelivery: {},
  storeDelivery: {},
  itemQty: 0,
  freeDeliveryProgress: null,
  totals: {
    coupon: null,
    couponDiscount: null,
    availableCoupons: [],
  },
  totalsWeight: {},
  errors: [],
  reorderPending: false,
  emptyCartPath: '/',
})

export default () => {
  return getCartStateInitial()
}
