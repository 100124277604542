// так как сетка одной страницы КД на разных расширениях разная, то получаются пустые ячейки
// на мобильных сетка из 3, на планшете из 4, и настольных ПК из 10 элементов
// чтобы этого избежать используем НОД для данных чисел
export const CLIENT_DAYS_PAGE_SIZE = 60
export const PROMOTIONS_PAGE_SIZE = 18
export const OFFERS_PAGE_SIZE = 15

export const PAGE_TYPE = {
  MAIN: 'main',
  OFFER_PAGE: 'offer',
  LISTING_PROMO: 'promo-listing',
  OFFERS_LISTING: 'offers-listing',
  LISTING_CLIENT_DAYS: 'client-days-listing',
  EXCLUSION_BRANDS: 'exclusion-brands',
}
