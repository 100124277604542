import { render, staticRenderFns } from "./item-list.vue?vue&type=template&id=00111822"
import script from "./item-list.script.js?vue&type=script&lang=js&external"
export * from "./item-list.script.js?vue&type=script&lang=js&external"
import style0 from "./item-list.style.scss?vue&type=style&index=0&prod&lang=scss&module=true&external"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports