import { useContext } from '@nuxtjs/composition-api'

import { unref, watch } from 'vue'

import { useSuperModal } from '~/modules/modal'

import { useDataCart } from './data-cart'

/**
 * @typedef {Object} DataForBehavior
 * @property {String} itemId - id продукта (из api)
 * @property {Boolean} configurable - требует ли показа превью с вариантами
 * @property {Boolean} isAdult - 18+
 */

/**
 * Возвращает обработчик
 * @param {Ref<DataForBehavior>} data
 * @param {Ref<Boolean>} cancelDefaultAddToCart
 * @param {Function} emit
 */
export const useBehavior = (data, cancelDefaultAddToCart, emit) => {
  const { $gaApp } = useContext()
  const { hasOpenedAdultModal } = useSuperModal()

  const { dataCart } = useDataCart(data)

  const askAdult = () => {
    return new Promise((resolve) => {
      // Следим за закрытием модалки
      const unwatch = watch(hasOpenedAdultModal, (isOpen) => {
        // Когда модалка закрылась, смотрим значение isAdult в сторе юзера
        if (!isOpen) {
          // Убираем слежение за закрытием модалки
          unwatch()

          // Резолвим промис с значением isAdult
          resolve($gaApp.stores.user.main.isAdult)
        }
      })

      // Открываем модалку
      $gaApp.services.modal.main.openAdultModal()
    })
  }

  const onCartClick = async () => {
    emit('click-add-to-cart', unref(dataCart))

    if (unref(cancelDefaultAddToCart)) return

    /* дефолтное поведение при добавлении товара в корзину */

    // Если товар 18+, то открываем модалку с подтверждением возраста
    if (unref(data).isAdult && !$gaApp.stores.user.main.isAdult) {
      const isAdult = await askAdult()

      // Если юзер не подтвердил совершеннолетие, то не добавляем товар в корзину
      // и завершаем дальнейшее выполнение функции
      if (!isAdult) {
        return false
      }
    }

    if (!unref(data).configurable) {
      emit('add-to-cart', unref(dataCart))
    } else {
      $gaApp.services.modal.main.openQuickPreviewProductModal(
        unref(data).itemId,
      )
      emit('open-preview', unref(dataCart))
    }
  }

  const onUpdateQty = ($event) => emit('update-qty', $event)

  return {
    onCartClick,
    onUpdateQty,
  }
}
