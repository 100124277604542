export const EXPIRE_POINT_UTC_OFFSET = 3

export const EXPIRE_POINT_DATE_FORMAT = 'DD MMM YYYY'
export const EXPIRE_POINT_DATE_FORMAT_RTL = 'YY/MM/DD'

export const LOYALTY_BANNER_VIEW = {
  MENU: 'menu',
  CARDS: 'cards',
}

export const LOYALTY_CARD_STATE = {
  HAS_CARD: 'HasCard',
  IN_PROCESS: 'InProcess',
  NO_CARD: 'NoCard',
}

export const LOYALTY_CARD_PENDING = {
  ATTEMPTS: 10,
  DELAY: 5000,
}

export const CARDS_PAGE_REDIRECT_PARAMS = {
  MODULE_TYPE: 'customer',
  PAGE_TYPE: 'giftcards',
}
