export class ApiService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  /**
   * Получает данные заказа для TYP.
   * @param {string} maskedId - id заказа.
   */
  async getThankYouData(maskedId) {
    if (!maskedId) {
      throw new Error('maskedId - обязательный параметр')
    }

    const { data } = await this.gaApp.repositories.checkout.main.getThankYou({
      maskedId,
      isPlaid: this.gaApp.features.get('cartPlaidApi'),
    })

    return data
  }
}
