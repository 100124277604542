import { ApiService } from './api/api.service'
import { ApiAdvBannersService } from './api/api-adv-banners.service'
import { ApiStreamsService } from './api/api-streams.service'
import { AdvBannersService } from './adv-banners.service'
import { FiltersService } from './filters.service'
import { GalleryService } from './gallery.service'
import { HeaderService } from './header.service'
import { ListingService } from './listing.service'
import { MainService } from './main.service'
import { OneService } from './one.service'
import { PageService } from './page.service'
import { StreamService } from './stream.service'
import { StreamsService } from './streams.service'

export const services = (gaApp) => ({
  main: new MainService(gaApp),
  api: new ApiService(gaApp),
  apiStreams: new ApiStreamsService(gaApp),
  apiAdvBanners: new ApiAdvBannersService(gaApp),
  page: new PageService(gaApp),
  gallery: new GalleryService(gaApp),
  one: new OneService(gaApp),
  filters: new FiltersService(gaApp),
  header: new HeaderService(gaApp),
  stream: new StreamService(gaApp),
  streams: new StreamsService(gaApp),
  listing: new ListingService(gaApp),
  advBanners: new AdvBannersService(gaApp),
})
