import { useContext } from '@nuxtjs/composition-api'

import { computed, toRefs } from 'vue'

// @vue/component
export default {
  name: 'ga-link-base',

  inheritAttrs: false,

  props: {
    nuxt: {
      type: Boolean,
      default: false,
    },
    openInModal: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { listeners, attrs, emit }) {
    const { nuxt, openInModal } = toRefs(props)
    const { route, localePath } = useContext()

    const rel = computed(() => {
      const { target, rel: relInternal } = attrs

      return target === '_blank' ? 'noopener noreferrer' : relInternal
    })

    const component = computed(() => {
      const attrsInternal = { ...attrs, rel: rel.value }
      const { href } = attrs

      return nuxt.value && route.value.fullPath !== href
        ? {
            is: 'nuxt-link',
            attrs: {
              ...attrsInternal,
              to: {
                path: localePath({ path: href }),
                query: openInModal.value
                  ? { openInModal: openInModal.value }
                  : {},
              },
            },
            listeners,
          }
        : {
            is: 'a',
            attrs: { ...attrsInternal, href },
            listeners,
          }
    })

    const onClick = (event) => {
      emit('click', event)
    }

    return { component, onClick }
  },
}
