<template>
  <svg fill="inherit" stroke="none" viewBox="0 0 20 20">
    <path
      fill="inherit"
      fill-rule="evenodd"
      d="M17.25 6.25V1.5h1.5v6.25H12.5v-1.5h4.75Z"
      clip-rule="evenodd"
    />
    <path
      fill="inherit"
      fill-rule="evenodd"
      d="M9.977 2.75C5.987 2.75 2.75 5.994 2.75 10s3.238 7.25 7.227 7.25c2.14 0 4.062-.932 5.386-2.416a7.248 7.248 0 0 0 1.43-2.417l1.414.499a8.75 8.75 0 0 1-1.725 2.917 8.697 8.697 0 0 1-6.505 2.917c-4.822 0-8.727-3.92-8.727-8.75s3.905-8.75 8.727-8.75a8.726 8.726 0 0 1 7.85 4.922l-1.35.656a7.225 7.225 0 0 0-6.5-4.078Z"
      clip-rule="evenodd"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-common-20-rotate',
}
</script>
