<template>
  <svg fill="none" viewBox="0 0 25 25">
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M19.535 4.964 20 4.375c.464-.59.465-.589.465-.589l.002.002.004.003.012.01a3.602 3.602 0 0 1 .176.15c.114.101.273.249.462.44.377.385.876.95 1.375 1.687.997 1.473 2.004 3.648 2.004 6.422 0 2.774-1.007 4.949-2.004 6.422a12.1 12.1 0 0 1-1.375 1.686 9.304 9.304 0 0 1-.638.591l-.012.01-.004.003-.002.001-.465-.588c-.464-.59-.465-.589-.465-.589l.004-.002.023-.02.102-.087c.09-.08.224-.205.387-.37.326-.332.764-.828 1.203-1.476C22.132 16.784 23 14.897 23 12.5c0-2.397-.868-4.284-1.746-5.581a10.595 10.595 0 0 0-1.203-1.476 7.871 7.871 0 0 0-.49-.458l-.022-.019-.004-.002Zm0 0Z"
      clip-rule="evenodd"
    />
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M8.594 7.143H0v10.714h8.594L15.625 25V0L8.594 7.143Zm5.531-3.481-4.903 4.98H1.5v7.715h7.722l4.903 4.981V3.662Z"
      clip-rule="evenodd"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-video-player-v2-sound',
}
</script>
