export class PageService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  async getData(redirect, context) {
    this.gaApp.services.customer.menu.setActiveMenuItem(redirect.pageType)
    this.gaApp.services.customer.menu.setSelectedMenuItem(redirect.pageType)
    this.gaApp.services.customer.menu.getMenu(redirect.pageType)

    try {
      if (this.gaApp.features.get('enableProfileBanner')) {
        await this.gaApp.services.customer.api.fetchLoyaltyBanner()
      }

      switch (redirect.pageType) {
        case 'order':
          return await this.gaApp.services.customer.api.requestOrderDetail(
            redirect.id,
          )
        case 'settings':
          return await this.gaApp.services.customer.api.getSubscriptionsSettings()

        case 'orders':
          return await this.gaApp.services.customer.api.getOrdersListing()

        case 'favorites':
          return await this.gaApp.services.favorites.plp.getListing()

        case 'addresses':
          if (!this.gaApp.stores.app.common.toggle.addressBlockEnabled) {
            return this.gaApp.redirectError({
              statusCode: 404,
            })
          }
          return await this.gaApp.services.location.addressesApi.fetchAddressList()
        case 'giftcards':
          return this.gaApp.features.get('showLoyaltyCardScreens')
            ? await this.gaApp.services.customer.api.fetchLoyaltyState()
            : await this.gaApp.services.customer.api.fetchLoyalty()

        case 'edit':
          return await this.gaApp.services.customer.api.getProfileInfo()
      }
    } catch (error) {
      switch (redirect.pageType) {
        case 'order':
          this.gaApp.services.app.router.redirectToRoute({
            moduleType: 'customer',
            pageType: 'orders',
          })

          break

        default:
          this.redirectError(error)
      }
    }
  }

  redirectError(error) {
    return this.gaApp.redirectError({
      statusCode: 500,
      message: error,
    })
  }
}
