import { computed, nextTick, toRefs } from 'vue'

import { useTransitionProps } from '@ga/use/transition-props'

// @vue/component
export default {
  name: 'ga-gift-cards-b2b-expand-transition',

  props: {
    /**
     * Объект, содержащий классы анимаций для transition.
     *
     * Для генерации этого объекта используйте утилиту `useTransitionProps`. Если настройки анимации не указаны, то будет использована анимация по умолчанию: ./styles/elems/expand.scss
     */
    transitionProps: {
      type: Object,
      default: null,
    },
  },

  setup: (props, { attrs }) => {
    const { transitionProps } = toRefs(props)

    const transitionPropsBuilder = useTransitionProps()
    const transitionPropsInternal = computed(() => {
      if (!transitionProps.value) {
        return transitionPropsBuilder('expand')
      }

      return transitionProps.value
    })
    const rootProps = computed(() => ({
      ...attrs,
      ...transitionPropsInternal.value,
    }))

    const resetStyles = (element) => {
      element.style.height = ''
      element.style.overflow = ''
    }

    const onEnter = (element) => {
      const height = element.offsetHeight
      element.style.height = '0px'
      element.style.overflow = 'hidden'

      nextTick(() => {
        element.style.height = `${height}px`
      })
    }

    const onAfterEnter = (element) => {
      resetStyles(element)
    }

    const onLeave = (element) => {
      element.style.height = `${element.offsetHeight}px`
      element.style.overflow = 'hidden'

      nextTick(() => {
        element.style.height = '0px'
      })
    }

    const onAfterLeave = (element) => {
      resetStyles(element)
    }

    return {
      onAfterEnter,
      onAfterLeave,
      onEnter,
      onLeave,
      rootProps,
    }
  },
}
