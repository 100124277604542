import { ACTIONS } from '../../../constants'
import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'
import { GtmGa4ProductBaseModel } from '../gtm-ga4-product-base.model/gtm-ga4-product-base.model'

export class GtmGa4ProductShowModel extends GtmBaseModel {
  constructor({ product }) {
    super(ACTIONS.VIEW_ITEM_LIST.GA4)

    const { itemListId, itemListName, rating, recommend, reviews } = product

    this.item_list_name = itemListName
    this.item_list_id = itemListId
    this.rating = rating
    this.recommend = recommend
    this.reviews = reviews

    this.ecommerce = new GtmGa4ProductBaseModel({ product })
  }
}
