export const addressesEndpoints = {
  getAddressList: () => ({
    url: `customer/addresses/list-v2`,
  }),
  setAddressDefault: () => ({
    url: `customer/addresses/setDefault-v2`,
  }),
  deleteAddress: () => ({
    url: `customer/addresses/delete-v2`,
  }),
  updateAddress: () => ({
    url: `customer/addresses/update-v2`,
  }),
}
