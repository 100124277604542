<template>
  <svg fill="none" viewBox="3 3 62 62">
    <g filter="url(#video-player-v2-play-a)">
      <path
        fill="#fff"
        fill-rule="evenodd"
        d="M34 64c16.569 0 30-13.431 30-30C64 17.431 50.569 4 34 4 17.431 4 4 17.431 4 34c0 16.569 13.431 30 30 30Zm-6.5-20 17-10-17-10v20Z"
        clip-rule="evenodd"
      />
    </g>
    <defs>
      <filter
        id="video-player-v2-play-a"
        width="66.4"
        height="66.4"
        x=".8"
        y=".8"
        color-interpolation-filters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="1.6" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
        />
        <feBlend
          in2="BackgroundImageFix"
          mode="normal"
          result="effect1_dropShadow_3324_7268"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_3324_7268"
          mode="normal"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-video-player-v2-play',
}
</script>
