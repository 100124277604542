import { SUGGEST_ABORT_KEY } from '../constants/search'

export class ApiService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  /**
   * Запрос подсказок по городам в плед
   * @param { string } query - то, что ввел юзер в инпут
   */
  async suggestCities(query) {
    const params = {
      query,
      type: 'city',
    }

    try {
      // отменяем предыдущий запрос
      this.gaApp.api.requestAbort.abortRequest(SUGGEST_ABORT_KEY)

      const { data } = await this.gaApp.repositories.location.main.geoSuggest(
        params,
        {
          abortKey: SUGGEST_ABORT_KEY,
        },
      )

      this.gaApp.services.location.search.setItems(data)
    } catch (error) {
      // не показываем уведомление если это мы отменили запрос
      if (error?.message === SUGGEST_ABORT_KEY) {
        return
      }

      this.gaApp.services.notification.main.open(
        this.gaApp.i18n.t('location.search.error.default'),
      )
      throw error
    }
  }

  /*
   * Запрос подсказок для саджеста по улицам
   * */
  async suggestStreets(street) {
    const location =
      this.gaApp.stores.location.main.locationTemp ||
      this.gaApp.stores.location.main.location

    const query =
      this.gaApp.services.location.formatter.formatStreetSuggestQuery(
        location,
        street,
      )

    const { cityName, regionName } =
      this.gaApp.services.location.formatter.formatStreetSuggestFilterParams(
        location,
      )

    /*
     * плейд
     * cityName и regionName нужно передавать как отдельными полями, так и в query
     * это нужно для единообразного формата по всем странам
     * сейчас ориентир больше на query
     * отдельный параметр cityName используется для фильтрации подсказок по городу/поселению
     * */
    const params = {
      query,
      type: 'street',
      cityName,
      regionName,
    }

    try {
      // отменяем предыдущий запрос
      this.gaApp.api.requestAbort.abortRequest(SUGGEST_ABORT_KEY)

      const { data } = await this.gaApp.repositories.location.main.geoSuggest(
        params,
        {
          abortKey: SUGGEST_ABORT_KEY,
        },
      )

      this.gaApp.stores.location.search.streets.items = data
    } catch (error) {
      // не показываем уведомление если это мы отменили запрос
      if (error?.message === SUGGEST_ABORT_KEY) {
        return
      }

      this.gaApp.services.notification.main.open(
        this.gaApp.i18n.t('location.search.error.default'),
      )
      throw error
    }
  }

  /*
   * Запрос локации после выбора подсказки с городом
   * */
  async geocodeCities(objectId, fullAddress) {
    const params = {
      objectId,
      type: 'city',
      query: fullAddress,
    }

    try {
      const { data } =
        await this.gaApp.repositories.location.main.geoGeocode(params)

      return data
    } catch (error) {
      this.gaApp.services.notification.main.open(
        this.gaApp.i18n.t('location.search.error.default'),
      )
      throw error
    }
  }

  /*
   * Запрос локации после выбора подсказки с улицей
   * */
  async geocodeStreets(objectId, fullAddress) {
    const location =
      this.gaApp.stores.location.main.locationTemp ||
      this.gaApp.stores.location.main.location

    const { cityName, regionName } =
      this.gaApp.services.location.formatter.formatStreetSuggestFilterParams(
        location,
      )

    /*
     * fullAddress передаем с ранее полученной подсказки
     * чтобы решить проблемы с точностью и бэк вернул корректный ответ
     * */
    const params = {
      objectId,
      type: 'street',
      query: fullAddress,
      cityName,
      regionName,
    }

    try {
      const { data } =
        await this.gaApp.repositories.location.main.geoGeocode(params)

      return data
    } catch (error) {
      this.gaApp.services.notification.main.open(
        this.gaApp.i18n.t('location.search.error.default'),
      )
      throw error
    }
  }

  /*
   * Запрос локации по координатам в плейд
   * */
  async reverseGeocode(
    coordinates,
    requestParams,
    { hasAlertError } = { hasAlertError: true },
  ) {
    try {
      const { data } =
        await this.gaApp.repositories.location.main.geoReverseGeocode(
          {
            useDefaultCity: true, // вернет дефолтный город, если нет ответа от дадаты по координатам
            ...coordinates,
          },
          requestParams,
        )

      return data
    } catch (error) {
      if (hasAlertError) {
        this.gaApp.services.notification.main.open(
          this.gaApp.i18n.t('location.geolocation.error.default'),
        )
      }

      throw error
    }
  }

  async getGeolocation(
    { longitude, latitude, abortKey },
    { hasAlertError } = { hasAlertError: true },
  ) {
    try {
      this.gaApp.services.location.geolocation.setPending(true)
      const data = await this.gaApp.services.location.api.reverseGeocode(
        {
          lon: longitude,
          lat: latitude,
        },
        { abortKey },
        { hasAlertError },
      )

      if (!data?.id) {
        throw new Error('Ошибка')
      }

      return data
    } catch (error) {
      // внутри reverseGeocode уже есть уведомление, если словили ошибку

      return null
    } finally {
      this.gaApp.services.location.geolocation.setPending(false)
    }
  }

  /**
   * Смена адреса
   * плед
   * если авторизован - бэк записывает адрес
   * если неавторизован - бэк обогащает адрес, а адрес хранится на клиенте
   */
  async setAddress(data) {
    const address = {
      id: data.saveId,
      addressId: data.addressId || data.exactId || data.id,
      dadataJson: data?.dadataJSON || null,
    }

    if (data.latitude && data.longitude) {
      address.addressLatitude = data.latitude
      address.addressLongitude = data.longitude
    }

    try {
      const { data } =
        await this.gaApp.repositories.location.main.setAddress(address)

      const { addresses, selectedAddress } = data || {}

      if (addresses) {
        this.gaApp.stores.user.main.addresses = addresses
      }

      if (selectedAddress) {
        this.gaApp.services.location.main.setLocation(selectedAddress)
        this.gaApp.services.location.cache.saveGuestSelectedAddress(
          selectedAddress,
        )
      }

      if (
        selectedAddress &&
        !this.gaApp.stores.app.common.toggle.addressBlockEnabled
      ) {
        this.gaApp.services.location.confirm.saveClientLocation(
          selectedAddress.id,
        )
      }
    } catch (error) {
      console.error(error)
    }
  }

  /**
   * Получение геолокации по ip
   * фича дадаты и РФ
   *
   * Что можно получить в ответе:
   * РФ - адрес по ip | дефолтный город
   * Остальные страны - дефолтный город
   */
  async getLocationByIp() {
    // нет смысла дергать, если не РФ
    if (!this.gaApp.isCountry.ru) {
      return
    }

    try {
      const { data } =
        await this.gaApp.repositories.location.main.getLocationByIp()

      const { location } = data || {}

      if (!location.city || !location.id) {
        const error = new Error('Ошибка получения локации по ip')
        error.data = JSON.stringify(data || {})

        this.gaApp.services.app.apm.captureError(error)
        this.gaApp.services.location.main.setLocationDefault()
        return
      }

      return location
    } catch (error) {
      this.gaApp.services.location.main.setLocationDefault()

      this.gaApp.services.notification.main.open(
        this.gaApp.i18n.t('location.error.get'),
      )
    }
  }

  async getCitiesModalData() {
    const { data } =
      await this.gaApp.repositories.location.retailStores.getCitiesModalData()

    if (!data?.cities) {
      throw new Error('Список c городами пуст')
    }

    return data
  }
}
