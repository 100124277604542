import isNumber from 'lodash/isNumber'

import { CURRENCIES } from '@ga/constants/currency'

export class CurrencyService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  // получение символа валюты
  getSymbol(currencyCode) {
    // Если локаль en, то возвращаем код валюты
    const [locale] = this.gaApp.i18n.locale.iso.split('-')
    if (locale === 'en') {
      return currencyCode ?? ''
    }

    // В остальных случаях, возвращаем символ валюты
    return CURRENCIES[currencyCode] ?? ''
  }

  // получение номинала разделенного на деноминатор валюты
  getNominal({ amount, denominator, isNominator }) {
    const amountNumber = Number(amount)
    const denominatorNumber = Number(denominator) || 1
    if (isNominator) {
      return amountNumber * denominatorNumber
    }

    return amountNumber / denominatorNumber
  }

  // получение номинала валюты как строки с разделителем
  getNominalFormatted({
    amount,
    denominator,
    currency,
    isNominator,
    withSymbol = false,
    minimumFractionDigits = null,
  }) {
    const nominal = this.getNominal({ amount, denominator, isNominator })

    const options = {
      minimumFractionDigits: this.getMinimumFractionDigits(
        minimumFractionDigits,
        denominator,
      ),
    }

    const value = this.gaApp.services.app.number.format(nominal, options)

    return withSymbol ? `${value} ${this.getSymbol(currency)}` : value
  }

  getPriceWeight(price) {
    // Вставляем пробел после минуса. Нужно, что бы при подсчете веса этот пробел был учтен
    const insertSpaceAfterMinus = (str) => {
      return price.amount >= 0 ? str : `${str.slice(0, 1)} ${str.slice(1)}`
    }

    const nominal = insertSpaceAfterMinus(this.getNominalFormatted(price))

    const weightNominal = this.gaApp.services.app.string.getWeight(nominal)
    const weightCurrency = this.getSymbol(price.currency).length

    return price.amount
      ? {
          value: weightNominal,
          withCurrency: weightNominal + weightCurrency + 0.5,
        }
      : {
          value: 0,
          withCurrency: 0,
        }
  }

  getMinimumFractionDigits(value, denominator) {
    if (isNumber(value)) {
      return value
    }

    const MINIMUM_FRACTION_DIGITS_BY_DENOMINATOR = {
      1: 0,
      10: 1,
      100: 2,
    }

    const MINIMUM_FRACTION_DIGITS_BY_STORE = {
      BY: 2,
    }

    if (this.gaApp.features.get('fractionalDigitsByDenominator')) {
      return MINIMUM_FRACTION_DIGITS_BY_DENOMINATOR[denominator] ?? 0
    } else {
      const [, storeName] = this.gaApp.i18n.locale.iso.split('-')
      return MINIMUM_FRACTION_DIGITS_BY_STORE[storeName] ?? 0
    }
  }
}
