import { ACTIONS } from '../../../constants'
import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'
import { GtmGa4ProductAddToBaseModel } from '../gtm-ga4-product-add-to-base.model/gtm-ga4-product-add-to-base.model'

export class GtmGa4ProductQuickViewModel extends GtmBaseModel {
  constructor({ product }) {
    super(ACTIONS.VIEW_ITEM.GA4)

    this.pdp_type = 'quick_view'

    this.ecommerce = new GtmGa4ProductAddToBaseModel({ product })
  }
}
