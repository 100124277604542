import { useContext } from '@nuxtjs/composition-api'

import { computed, onMounted, ref, toRefs, watch } from 'vue'

import { getId } from '@ga/utils'

import { GaExpandTransition } from '../expand-transition'

// @vue/component
export default {
  name: 'ga-radio-group-base',

  components: {
    GaExpandTransition,
  },

  model: {
    event: 'change',
    prop: 'value',
  },

  props: {
    /**
     * Имя для input элемента
     */
    name: {
      type: String,
      required: true,
    },

    /**
     * Значение для v-model
     */
    value: {
      type: [String, Number],
      required: true,
    },

    /**
     * Список элементов
     */
    options: {
      type: Array,
      required: true,
      validator: (value) => value.every((i) => i.value),
    },

    /**
     * Идентификатор зоны лейблов
     */
    labelId: {
      type: String,
      default: '',
    },

    /**
     * Класс CSS для элемента опции
     */
    optionClass: {
      type: String,
      default: '',
    },

    /**
     * Только для чтения
     */
    isReadonly: {
      type: Boolean,
    },

    /**
     * Параметры для transition-group элементов.
     *
     * Если параметр пуст, то анимация не будет применена.
     * Если параметр имеет значение `true`, то будет использована стандартная анимация ga-expand-transition
     */
    transition: {
      type: [Object, Boolean],
      default: null,
    },

    /** Включает скролл до выбранного элемента */
    scrollToActive: {
      type: Boolean,
      default: false,
    },

    /** Принудительно отключает все элементы */
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const { labelId, options, value, transition, disabled } = toRefs(props)
    const { $gaApp } = useContext()
    const focused = ref(null)
    const isMounted = ref(false)

    const uuid = getId()

    const hasOwnLabel = computed(() => !labelId.value)

    const labelIdInternal = computed(() => labelId.value || `${uuid}-label`)

    const optionsInternal = computed(() =>
      options.value.map((option) => {
        // чтобы избежать срабатывания нативного инпута при кликах до подгрузки js
        const isOptionDisabled =
          !isMounted.value || option.disabled || disabled.value

        const state = {
          checked: value.value === option.value,
          focused: focused.value === option.value,
          disabled: isOptionDisabled,
        }

        return {
          ...option,
          disabled: isOptionDisabled,
          state,
        }
      }),
    )

    const tabIndex = computed(() => (props.isReadonly ? '-1' : ''))

    const updateValue = (val) => {
      emit('change', val)
    }

    const onInputChange = ($event, val) => {
      updateValue(val)

      if (props.scrollToActive && $event?.target) {
        scrollToActive($event.target)
      }
    }

    const onInputFocus = (val) => {
      if (props.isReadonly) {
        return
      }

      if ($gaApp.stores.app.main.withFocusKeyboard) {
        focused.value = val
      }
    }

    const onInputClick = (event) => {
      if (props.isReadonly) {
        event.preventDefault()
      }
    }

    const onInputBlur = () => {
      focused.value = null
    }

    const scrollToActive = (element) => {
      element.scrollIntoView?.({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      })
    }

    onMounted(() => {
      isMounted.value = true
    })

    watch(
      () => $gaApp.stores.app.main.withFocusKeyboard,
      () => {
        if (focused.value) {
          focused.value = false
        }
      },
    )

    const transitionEnabled = computed(() => Boolean(transition.value))
    const transitionProps = computed(() => {
      const props = {
        tag: 'div',
      }

      if (transition.value && typeof transition.value === 'object') {
        props.transitionProps = transition.value
      }

      return props
    })
    const transitionComponent = computed(() =>
      transitionEnabled.value ? 'ga-expand-transition' : 'div',
    )

    return {
      hasOwnLabel,
      labelIdInternal,
      optionsInternal,
      tabIndex,
      transitionEnabled,
      transitionComponent,
      transitionProps,

      onInputChange,
      onInputFocus,
      onInputBlur,
      onInputClick,
    }
  },
}
