import { useContext } from '@nuxtjs/composition-api'
import { useIntersectionObserver } from '@vueuse/core'

import { computed, ref, unref, watch } from 'vue'

import { doubleRAF, isIOS } from '@ga/shared-browser'

export const useItems = ({ groupRef, props }) => {
  const { $gaApp } = useContext()

  const observer = ref(null)
  const isVisible = ref(false) // указывает, что список в области видимости
  const canBeRendered = ref(true) // указывает, можно ли рендерить реальные items
  const initialRenderingСompleted = ref(false) // указывает, что стартовые расчеты завершены
  const fakeItemsRendering = $gaApp.features.get('brandsFakeItemsRendering')

  // TODO: убрать ограничение на рендер IOS,
  // временное решение до появления виртуального скролла
  const MAX_ITEMS_LENGTH = 80
  const isSSR = computed(() => $gaApp.services.app.main.isSSR)
  const isOsIOS = unref(isSSR) ? false : isIOS()
  const actualItems = computed(() => {
    return isOsIOS && !fakeItemsRendering
      ? props.items.slice(0, MAX_ITEMS_LENGTH)
      : props.items
  })

  const itemsCount = computed(() => props.items?.length || 0)
  const hasItems = computed(() => itemsCount.value > 0)

  const isPageScrolling = computed(
    () => $gaApp.stores.brands.main.isPageScrolling,
  )
  // touchmove по алфавитной навигации
  const isBlocksScrolling = computed(
    () => $gaApp.stores.brands.main.isBlocksScrolling,
  )
  // указывает на необходимость рендеринга реальных items
  const needsRealItems = computed(
    () =>
      unref(isSSR) ||
      !fakeItemsRendering ||
      (unref(isVisible) && unref(canBeRendered)),
  )

  const initObserver = () => {
    observer.value = useIntersectionObserver(
      groupRef,
      ([{ isIntersecting }]) => {
        isVisible.value = isIntersecting

        if (!unref(initialRenderingСompleted)) {
          initialRenderingСompleted.value = true
        }
      },
      { threshold: [0, 1] },
    )
  }

  if (fakeItemsRendering) {
    initObserver()

    // следим за скроллом страницы
    watch(isPageScrolling, (val) => {
      if (!val && unref(isVisible)) {
        // по завершению, если блок в зоне видимости
        canBeRendered.value = true
      } else if (val && !unref(isVisible)) {
        // при возобновлении
        canBeRendered.value = false
      }
    })

    // следим за touchmove по алфавитной навигации
    watch(isBlocksScrolling, (val) => {
      if (!val) {
        // по завершению перезапускаем observer
        initObserver()

        doubleRAF(() => {
          if (unref(isVisible)) {
            // если список оказался в области видимости
            canBeRendered.value = true
          }
        })
      } else if (val) {
        // сбрасываем observer при возобновлении
        observer.value.stop()
        isVisible.value = false
      }
    })
  }

  return { hasItems, actualItems, needsRealItems, initialRenderingСompleted }
}
