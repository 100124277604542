<template>
  <svg fill="none" stroke="none" viewBox="0 0 25 26">
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M23.5 2h-22v22h22V2ZM0 .5v25h25V.5H0Z"
      clip-rule="evenodd"
    />
    <path
      fill="currentColor"
      d="M16.893 17c-2.369 0-3.69-1.554-3.69-3.946v-.087C13.203 10.63 14.757 9 16.872 9c1.734 0 3.077.891 3.246 2.717h-1.427c-.159-1.043-.709-1.565-1.808-1.565-1.322 0-2.2 1.065-2.2 2.805v.086c0 1.75.804 2.783 2.21 2.783 1.058 0 1.776-.543 1.935-1.641h1.375C19.98 16.065 18.7 17 16.893 17Z"
    />
    <path
      fill="currentColor"
      d="M8.485 17c-2.368 0-3.69-1.554-3.69-3.946v-.087C4.795 10.63 6.349 9 8.465 9c1.733 0 3.076.891 3.245 2.717h-1.427c-.159-1.043-.709-1.565-1.808-1.565-1.322 0-2.2 1.065-2.2 2.805v.086c0 1.75.804 2.783 2.21 2.783 1.058 0 1.777-.543 1.935-1.641h1.375C11.573 16.065 10.293 17 8.485 17Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-video-player-v2-subtitle-disabled',
}
</script>
