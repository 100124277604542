export const state = () => {
  return {
    loyalty: {},
    loyaltyBanner: null,
    isLoyaltyDetailsModalOpened: false,
    isLoyaltyInfoModalOpened: false,
    loyaltyCardState: null,
    loyaltyQrModal: {
      active: false,
      data: null,
    },
    animateLoyaltyCard: false,
  }
}
