import { GaAction } from '@ga/ui-components/action'

// @vue/component
export default {
  name: 'ga-auth-privacy-policy-confirm',

  components: {
    GaAction,
  },

  props: {
    tPath: {
      type: String,
      required: true,
    },
  },
}
