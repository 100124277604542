import { OFFER_EXCLUSION_BRANDS } from '../constants/keys'

export class OfferService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  // метод нужен для перехода в бренды исключения и сохранения ссылки акции, с которой был совершен переход
  goToExclusionBrands = (event) => {
    // находим ссылку в эвенте
    if (event.target instanceof HTMLAnchorElement) {
      // смотрим куда идет переход - если переход на страницу брендов исключений
      if (event.target.pathname.startsWith(OFFER_EXCLUSION_BRANDS)) {
        event.preventDefault()
        // пушим ссылку с query url акции
        this.gaApp.router.push({
          path: event.target.pathname,
          query: {
            referer: this.gaApp.stores.promo.offer.data?.url,
          },
        })
      }
    }
  }
}
