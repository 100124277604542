<template>
  <svg fill="none" stroke="transparent" stroke-width="1" viewBox="0 0 25 25">
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M25 0H0v25h25V0ZM4.635 12.467c0-2.41 1.612-4.117 3.82-4.117.89 0 1.695.229 2.302.706.61.479 1.005 1.197 1.093 2.147l.015.164h-1.721l-.02-.128c-.076-.5-.242-.854-.502-1.084-.258-.228-.63-.353-1.157-.353-.617 0-1.123.247-1.478.693-.358.45-.572 1.115-.572 1.961v.087c0 .855.197 1.513.546 1.955.345.436.851.678 1.514.678.504 0 .911-.13 1.21-.375.297-.245.502-.618.577-1.138l.018-.129h1.674l-.02.168c-.115.972-.505 1.714-1.114 2.211-.607.496-1.415.737-2.345.737-1.218 0-2.184-.401-2.844-1.125-.658-.723-.996-1.749-.996-2.971v-.087Zm8.408 0c0-2.41 1.612-4.117 3.819-4.117.892 0 1.696.229 2.303.706.609.479 1.005 1.197 1.093 2.147l.015.164h-1.721l-.02-.128c-.076-.5-.242-.854-.502-1.084-.258-.228-.63-.353-1.157-.353-.617 0-1.123.247-1.478.693-.358.45-.572 1.115-.572 1.961v.087c0 .855.197 1.513.545 1.955.345.436.852.678 1.515.678.504 0 .911-.13 1.21-.375.297-.245.502-.618.577-1.138l.018-.129h1.674l-.02.168c-.115.972-.506 1.714-1.114 2.211-.607.496-1.416.737-2.345.737-1.218 0-2.184-.401-2.844-1.125-.658-.723-.996-1.749-.996-2.971v-.087Z"
      clip-rule="evenodd"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-video-player-v2-subtitle',
}
</script>
