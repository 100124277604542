import qs from 'qs'

import { CLIENT_ID, GRANT_TYPE, REQUEST_FIELD_NAME } from '../../constants'

const REQUEST_PAYLOAD = {
  sendCode({ phone, phoneCode, captchaToken, magento }) {
    return {
      [REQUEST_FIELD_NAME.CLIENT_ID]: CLIENT_ID,
      [REQUEST_FIELD_NAME.CAPTCHA_TOKEN]: captchaToken,
      [REQUEST_FIELD_NAME.PHONE_NUMBER]: phone,
      [REQUEST_FIELD_NAME.PHONE_COUNTRY_ISO]: phoneCode,
      [REQUEST_FIELD_NAME.USE_MAGENTO_FOR_WEB]: magento,
    }
  },

  resendCode({ phone, phoneCode, resendToken, captchaToken, magento }) {
    return {
      [REQUEST_FIELD_NAME.CLIENT_ID]: CLIENT_ID,
      [REQUEST_FIELD_NAME.PHONE_NUMBER]: phone,
      [REQUEST_FIELD_NAME.PHONE_COUNTRY_ISO]: phoneCode,
      [REQUEST_FIELD_NAME.RESEND_TOKEN]: resendToken,
      [REQUEST_FIELD_NAME.CAPTCHA_TOKEN]: captchaToken,
      [REQUEST_FIELD_NAME.USE_MAGENTO_FOR_WEB]: magento,
    }
  },

  login({ code, phone, magento }) {
    return {
      [REQUEST_FIELD_NAME.CLIENT_ID]: CLIENT_ID,
      [REQUEST_FIELD_NAME.PHONE_NUMBER]: phone,
      [REQUEST_FIELD_NAME.VERIFICATION_TOKEN]: code,
      [REQUEST_FIELD_NAME.GRANT_TYPE]: GRANT_TYPE.PHONE_NUMBER,
      [REQUEST_FIELD_NAME.USE_MAGENTO_FOR_WEB]: magento,
    }
  },

  loginGrandType({ code, phone, grantType, magento }) {
    return {
      [REQUEST_FIELD_NAME.CLIENT_ID]: CLIENT_ID,
      [REQUEST_FIELD_NAME.PHONE_NUMBER]: phone,
      [REQUEST_FIELD_NAME.VERIFICATION_TOKEN]: code,
      [REQUEST_FIELD_NAME.GRANT_TYPE]: grantType,
      [REQUEST_FIELD_NAME.USE_MAGENTO_FOR_WEB]: magento,
    }
  },

  loginNoPhoneGrantType({ code, grantType }) {
    return {
      [REQUEST_FIELD_NAME.CLIENT_ID]: CLIENT_ID,
      [REQUEST_FIELD_NAME.CHALLENGE_CODE]: code,
      [REQUEST_FIELD_NAME.GRANT_TYPE]: grantType,
    }
  },

  logout() {
    return {
      [REQUEST_FIELD_NAME.CLIENT_ID]: CLIENT_ID,
    }
  },

  refreshTokens({ magento }) {
    return {
      [REQUEST_FIELD_NAME.CLIENT_ID]: CLIENT_ID,
      [REQUEST_FIELD_NAME.GRANT_TYPE]: GRANT_TYPE.REFRESH,
      [REQUEST_FIELD_NAME.USE_MAGENTO_FOR_WEB]: magento,
    }
  },
}

export const buildRequestPayload = (name, reqType = 'json', params = {}) => {
  const payload = REQUEST_PAYLOAD[name](params)

  return reqType === 'json' ? payload : qs.stringify(payload)
}
