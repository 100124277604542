import { unref } from 'vue'

export const useVirtualKeyboardHide = (rootRef) => {
  const detachListener = () => {
    document.removeEventListener('touchstart', onTouch)
  }

  const isValidTarget = (eventTarget) => {
    const rootElement = unref(rootRef)

    return eventTarget !== rootElement && !rootElement?.contains(eventTarget)
  }

  const onTouch = (event) => {
    if (!isValidTarget(event.target)) {
      return undefined
    }

    document.activeElement?.blur()
    detachListener()
  }

  const attachListener = () => {
    document.addEventListener('touchstart', onTouch)
  }

  return {
    attachListener,
    detachListener,
  }
}
