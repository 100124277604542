import { computed, ref } from 'vue'

import { GaBrandsLetter } from '../../atoms/letter'
import { GaBrandsItem } from '../item'
import { GaBrandsItemFake } from '../item-fake'

import { useItems } from './scripts/composables/items'

// @vue/component
export default {
  name: 'ga-brands-item-list',

  components: {
    GaBrandsItem,
    GaBrandsLetter,
    GaBrandsItemFake,
  },

  props: {
    letter: {
      type: String,
      default: null,
    },

    letterRtlIgnore: {
      type: Boolean,
      default: false,
    },

    items: {
      type: Array,
      default: () => [],
    },

    itemsFavorites: {
      type: Array,
      default: () => [],
    },

    className: {
      type: String,
      default: null,
    },

    canRemoveFromFavorites: {
      type: Boolean,
      default: false,
    },

    showFavourite: {
      type: Boolean,
      default: true,
    },

    clickableLabel: {
      type: Boolean,
      default: true,
    },

    highlight: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const groupRef = ref(null)

    const { hasItems, actualItems, needsRealItems, initialRenderingСompleted } =
      useItems({
        props,
        groupRef,
      })

    const isFavorite = (id) => {
      return props.itemsFavorites.includes(id)
    }

    const listMods = computed(() => ({
      highlight: props.highlight,
    }))

    const mods = computed(() => ({
      'no-letter': !props.letter,
    }))

    return {
      listMods,
      mods,
      groupRef,
      hasItems,
      actualItems,
      needsRealItems,
      initialRenderingСompleted,

      isFavorite,
    }
  },
}
