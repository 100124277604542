import { SCREEN_TYPES } from '../analytics/constants'

export class AddressesApiService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  /**
   * Получение списка адресов для ЛК
   */
  async fetchAddressList() {
    const response =
      await this.gaApp.repositories.location.addresses.fetchAddressList()

    this.updateAddresses(response)

    return response
  }

  async setDefaultAddress(addressId) {
    try {
      const response =
        await this.gaApp.repositories.location.addresses.setAddressDefault({
          id: addressId,
        })

      this.updateAddresses(response)

      this.gaApp.stores.location.addresses.activeId =
        this.gaApp.stores.location.addresses.defaultId

      this.gaApp.services.user.main.dropTemporaryAddresses()

      this.gaApp.services.notification.main.open({
        slotText: this.gaApp.i18n.t('customer.addresses.addressChanged', {
          value:
            this.gaApp.services.location.addresses.formatAddressStreetHouse(
              this.gaApp.stores.location.addresses.activeId,
            ),
        }),
      })
    } catch {
      this.gaApp.services.notification.main.open(
        this.gaApp.i18n.t('customer.addresses.error.setDefaultId'),
      )
    }
  }

  async setAddress(addressId) {
    this.gaApp.analytics.modules.location.onClickChangeAddress(
      SCREEN_TYPES.ACCOUNT_MY_ADDRESSES,
    )

    await this.setDefaultAddress(addressId)
  }

  async deleteAddress(addressId) {
    try {
      const response =
        await this.gaApp.repositories.location.addresses.deleteAddress({
          id: addressId,
        })

      this.updateAddresses(response)

      this.gaApp.services.user.main.dropAddress(addressId)

      this.gaApp.services.notification.main.open({
        slotText: this.gaApp.i18n.t('customer.addresses.delete.success', {
          value:
            this.gaApp.services.location.addresses.formatAddressStreetHouse(
              this.gaApp.stores.location.addresses.activeId,
            ),
        }),
      })
    } catch {
      this.gaApp.services.notification.main.open(
        this.gaApp.i18n.t('customer.addresses.error.delete'),
      )
    }
  }

  async updateAddress(addressData) {
    if (this.gaApp.features.get('locationAddressFormEditUnification')) {
      this.gaApp.stores.location.addresses.isLoading = true
    }

    try {
      const response =
        await this.gaApp.repositories.location.addresses.updateAddress(
          addressData,
        )

      this.updateAddresses(response)

      this.gaApp.services.notification.main.open({
        slotText: this.gaApp.i18n.t('customer.addresses.edit.success', {
          value:
            this.gaApp.services.location.addresses.formatAddressStreetHouse(
              this.gaApp.stores.location.addresses.activeId,
            ),
        }),
      })
    } catch {
      this.gaApp.services.notification.main.open(
        this.gaApp.i18n.t('customer.addresses.error.update'),
      )
    } finally {
      this.gaApp.stores.location.addresses.isLoading = false
    }
  }

  updateAddresses(response) {
    const { addresses, selectedAddress, addressesAsLocations } =
      response?.data || {}

    // список сохраненнных адресов в формате для ЛК
    this.gaApp.stores.location.addresses.list = addresses

    // список сохраненнных адресов в формате location, как selectedAddress
    // для чекаута, уточнения адреса
    if (addressesAsLocations) {
      this.gaApp.services.user.main.setUserAddresses(addressesAsLocations)
    }

    if (selectedAddress) {
      this.gaApp.services.location.main.setLocation(selectedAddress)
    }
  }
}
