import { computed, useContext } from '@nuxtjs/composition-api'

/**
 * Функция-компаньон для FT emptyCartUrlFromAPI, для выбора источника ссылки на новые поступления, в ссылке в пустой корзине.
 * Возвращает путь из локали, или тот, что приходит из API. Удалить при удалении FT.
 */
export const useEmptyCartLink = () => {
  const { $gaApp } = useContext()

  const pathFromApi = $gaApp.stores.cart.main.emptyCartPath
  const fallbackPath = $gaApp.i18n.t('cart.empty.newArrivalsPath')

  const path = computed(() =>
    $gaApp.features.get('emptyCartUrlFromAPI') ? pathFromApi : fallbackPath,
  )

  return { path }
}
