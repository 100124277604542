export const AR_QA_FEATURES = {
  hideUnitsInCart: false, // Скрывает единицы измерения в корзине
  newAvailableCouponImage: false, // Включает новый вид отображения фона купона
  giftCards12hFormat: false, // Включает 12-часовой формат времени при оформлении ЭПК
  giftCardsShowMainBenefits: false, // Включает отображение основных преимуществ ЭПК на главной
  giftCardsShowMainFaq: false, // Включает отображение основных вопросов-ответов ЭПК на главной
  giftCardsShowMainStickyMenu: false, // Включает отображение меню для навигации по главной
  giftCardsStickyBurgerShown: false, // Включает фиксированное бургер-меню на главной карт
  giftCardsMobileMenuThemeLight: false, // Включает светлую тему меню на главной карт для мобильного веба
}
