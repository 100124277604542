export const getStateInitial = () => ({
  filterModalDeferRendered: false,
  filtersModalOpened: false,

  filtersPending: false,

  filters: [],
  filtersSelected: [],
  filtersAnalytics: [],
  countProductsFiltered: 0,
  countSelectedFilters: 0,

  filtersHash: '',

  filtersLocaly: [],

  filtersQuick: [],
  filtersQuickSelected: [],

  // TODO: переделать систему взаимодействия с check-tree
  filterMobileValuesOfTrees: [],

  requestFiltersFn: null,
  areHashesEqualFn: null,
  getItemListNameFn: null,
})

export const state = () => {
  return getStateInitial()
}
