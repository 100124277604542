export const state = () => {
  return {
    isLoading: true,
    isPreview: false,
    totalArticlesAmount: 0,
    seoTitle: '',
    seoAttrs: [],
    currentPageNumber: null,
    lastViewedPageNumber: 0,
    pages: [],
    fetchingPageNumber: null,
    filters: [],
    // количество загруженных изображений для первой страницы
    // (считаем только первые 4 изображения)
    loadedFirstImages: 0,
    loadingDirection: null,
  }
}
