export class NotificationModalService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  /**
   * Устанавливает данные для модального окна уведомлений
   * @param {Object} data - Объект с данными для модального окна уведомлений
   */
  setData(data) {
    this.gaApp.stores.customer.notificationModal.data = data
  }

  /**
   * Открывает модальное окно уведомлений
   */
  openModal() {
    this.gaApp.stores.customer.notificationModal.opened = true
  }

  /**
   * Закрывает модальное окно уведомлений
   */
  closeModal() {
    this.gaApp.stores.customer.notificationModal.opened = false
  }

  /**
   * Метод открытия модального окна после авторизации
   */
  onAuth() {
    // Проверяем, разрешено ли показывать модалку
    if (!this.gaApp.stores.customer.notificationModal.isAllowedToShow) {
      return
    }

    this.gaApp.ctx.$doubleRAF(() => {
      /** Определяем событие для подписки, в зависимости от изменения маршрута
       * Если маршрут изменился, используем событие 'page-mounted', если нет - 'finish-close/auth'
       */
      const eventName = this.gaApp.stores.app.main.routeChange
        ? 'module/app/router/page-mounted'
        : 'module/modal/event/finish-close/auth'

      // Подписываемся на событие
      const unsubscribe = this.gaApp.eventBus.subscribe(eventName, () => {
        // Открываем модалку при событии
        this.openModal()

        unsubscribe()
      })
    })
  }
}
