export const COOKIE_LOCATION_ID = 'ga-location-city-id'
export const COOKIE_ADDRESS_CONFIRMED = 'isAddressConfirmed'

export const STORAGE_SELECTED_ADDRESS_KEY = 'ga-selected-address'

export const COOKIE_SELECTED_ADDRESS = {
  ID: 'ga-selected-address-id',
  CITY_DISTRICT: 'ga-selected-address-city-district',
  GEO_POLYGONS: 'ga-selected-address-geo-polygons',
}
