import { useContext } from '@nuxtjs/composition-api'

import { computed, unref } from 'vue'

import { GaAction } from '@ga/ui-components/action'
import { GaButtonLogo, SIZE } from '@ga/ui-components/button-logo'
import { GaImage } from '@ga/ui-components/image'
import { GaVisuallyHidden } from '@ga/ui-components/visually-hidden'

// @vue/component
export default {
  name: 'ga-auth-modal-auth-services',

  components: {
    GaAction,
    GaButtonLogo,
    GaImage,
    GaVisuallyHidden,
  },

  setup() {
    const { $gaApp } = useContext()

    const externalAuthServices = computed(
      () => $gaApp.stores.auth.modal.externalAuthServices,
    )

    const title = computed(() =>
      unref(externalAuthServices).length === 1
        ? $gaApp.i18n.t('auth.modalAuthTypes.services.title')
        : $gaApp.i18n.t('auth.modalAuthTypes.services.titlePlural'),
    )

    const isTablet = computed(
      () => $gaApp.mq('tablet+') && $gaApp.mq('desktop-small-'),
    )

    const buttonSize = computed(() => (isTablet.value ? SIZE.XL : SIZE.L))

    return {
      title,
      externalAuthServices,

      buttonSize,
    }
  },
}
