<template>
  <svg fill="currentColor" viewBox="2 0 40 40">
    <path
      fill="currentColor"
      d="M32.307 10.493c-5.172-5.171-13.542-5.181-18.694-.028-3.35 3.35-4.519 8.058-3.505 12.366l4.154-4.154 1.061 1.06-5.785 5.785-5.63-5.63 1.06-1.061 3.51 3.51c-.8-4.56.557-9.42 4.074-12.937 5.742-5.741 15.06-5.726 20.815.029 5.755 5.754 5.77 15.073.029 20.815a14.646 14.646 0 0 1-11.222 4.273 14.74 14.74 0 0 1-5.529-1.42l.648-1.352a13.241 13.241 0 0 0 4.966 1.274 13.146 13.146 0 0 0 10.076-3.836c5.153-5.153 5.143-13.522-.028-18.694Z"
    />
    <path
      fill="currentColor"
      d="M23.015 24.467c-2.175 0-3.198-1.191-3.302-2.784h1.606c.142.867.492 1.45 1.683 1.45 1.075 0 1.67-.738 1.67-1.878 0-1.126-.634-1.826-1.67-1.826-.945 0-1.515.324-1.93.803h-.88l.078-5.154h5.62v1.36h-4.3l-.012 2.512c.362-.414 1.01-.777 1.994-.777 1.67 0 2.849 1.179 2.849 3.108 0 1.969-1.412 3.186-3.406 3.186Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-video-player-v2-rewind-animated',
}
</script>
