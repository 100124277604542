import { useContext } from '@nuxtjs/composition-api'

export const useAnalytics = () => {
  const { $gaApp } = useContext()

  // При клике на ссылку бренда
  const onBrandItemClick = (data) => {
    $gaApp.analytics.modules.brands.onBrandItemClick(data)
  }

  // При добавлении бренда в избранные
  const onAddBrandToFavorite = (data) => {
    $gaApp.analytics.modules.brands.onAddBrandToFavorite(data)
  }

  // При клике/тапе/скролле по алфавитной навигации
  const onAlphabetNavigation = (data) => {
    $gaApp.analytics.modules.brands.onAlphabetNavigation(data)
  }

  // При клике по полю поиска
  const onSearchFocus = () => {
    $gaApp.analytics.modules.brands.onSearchFocus()
  }

  // При вводе в поле поиска
  const onSearchQueryEntering = () => {
    $gaApp.analytics.modules.brands.onSearchQueryEntering()
  }

  // Получить itemListName
  const getItemListName = () => {
    return $gaApp.analytics.modules.brands.getItemListName()
  }

  return {
    getItemListName,
    onBrandItemClick,
    onAddBrandToFavorite,
    onAlphabetNavigation,
    onSearchFocus,
    onSearchQueryEntering,
  }
}
