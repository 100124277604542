import { useContext } from '@nuxtjs/composition-api'

import { computed } from 'vue'

import { GaButton } from '@ga/ui-components/button'

import { STEP } from '../../constants'
import GaAuthModalHeader from '../modal-header'

import { GaAuthModalAuthServices } from './children/services'

// @vue/component
export default {
  name: 'ga-auth-modal-auth-types',

  components: {
    GaButton,

    GaAuthModalHeader,
    GaAuthModalAuthServices,
  },

  setup() {
    const { $gaApp } = useContext()

    const externalAuthServices = computed(
      () => $gaApp.stores.auth.modal.externalAuthServices,
    )

    const onButtonClick = () => {
      $gaApp.services.auth.modal.goToStep(STEP.START)
    }

    const onServiceClick = (serviceName) => {
      $gaApp.services.auth.modalAuthTypes.redirectToExternalAuthService(
        serviceName,
      )
    }

    return {
      externalAuthServices,

      onServiceClick,
      onButtonClick,
    }
  },
}
