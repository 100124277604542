import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'

export class GtmGa4ChipClickModel extends GtmBaseModel {
  constructor({ amount, type }) {
    super({})

    this.action_type = 'click_bubble_amount'
    this.type = type
    this.amount = amount
  }
}
