import { TYPE } from '../../constants'
import { GtmGa4ProductShowModel } from '../../models'
import { BaseBuilder } from '../base.builder/base.builder'

export class ProductShowBuilder extends BaseBuilder {
  constructor(data) {
    const models = new Map([[TYPE.GTM_4, GtmGa4ProductShowModel]])
    super(models, data)
  }
}
