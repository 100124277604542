import {
  LOAD_DIR,
  SEO_ATTRS_KEY,
  STREAMS_PAGE_CHUNK_SIZE,
} from '../../constants'

export const getters = () => ({
  // размер страницы в зависимости от типа листинга
  chunkSize() {
    return STREAMS_PAGE_CHUNK_SIZE
  },

  totalPagesAmount() {
    return Math.ceil(this.totalArticlesAmount / this.chunkSize)
  },

  fetchingPageBefore() {
    return (
      this.fetchingPageNumber &&
      this.pages[0] &&
      this.fetchingPageNumber < this.pages[0].number
    )
  },

  fetchingPageAfter() {
    return (
      this.fetchingPageNumber &&
      this.pages[this.pages.length - 1] &&
      this.fetchingPageNumber > this.pages[this.pages.length - 1].number
    )
  },

  canLoadPageBefore() {
    return this.pages.length > 0 && this.pages[0].number > 0
  },

  canLoadPageAfter() {
    const pageCount = this.pages.length
    const lastPage = this.pages[pageCount - 1]

    // если на последней загруженной странице есть материалы и если последняя страница меньше текущей
    return Boolean(
      lastPage &&
        lastPage.items.length &&
        lastPage.number + 1 < this.totalPagesAmount,
    )
  },

  activeFilters() {
    return this.filters.filter((filter) => filter.active)
  },

  hasActiveFilters() {
    return Boolean(this.filters.find((filter) => filter.active))
  },

  titleSeo() {
    return this.seoTitle
  },

  descriptionSeo() {
    return this.seoAttrs.find((attr) => attr.key === SEO_ATTRS_KEY.DESCRIPTION)
      ?.value
  },

  additionalAttributesSeo() {
    return this.seoAttrs.filter(
      (attr) =>
        attr.key !== SEO_ATTRS_KEY.DESCRIPTION &&
        attr.key !== SEO_ATTRS_KEY.TITLE &&
        attr.value,
    )
  },

  isLoadingPageBefore() {
    return this.loadingDirection === LOAD_DIR.PREV
  },

  isLoadingPageNext() {
    return this.loadingDirection === LOAD_DIR.NEXT
  },

  lastItem() {
    const lastPage = this.lastPage

    if (!lastPage) {
      return
    }

    const itemsLength = lastPage.items.length

    return lastPage.items[itemsLength - 1]
  },

  lastActualPublicationDate() {
    if (!this.lastItem) {
      return
    }

    return this.lastItem?.translationDate
  },

  lastPage() {
    return this.pages[this.pages.length - 1]
  },

  firstLoadedPageNumber() {
    return this.pages[0]?.number || 0
  },

  lastLoadedPageNumber() {
    return this.lastPage?.number || 0
  },
})
