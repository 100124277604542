import { DELIVERY_ABORT_KEY, STATE, TARGET } from '../../constants/common'
import { DELAY_LOADING, DELAY_LOADING_OFF } from '../../constants/common/delay'

/**
 * ApiService предназначен для получения данных о продукте,
 * доставке и описании продукта из репозиториев и кэша.
 */
export class ApiService {
  constructor(gaApp) {
    this.gaApp = gaApp

    this.gaApp.eventBus.subscribe('module/pdp/prefetch', (item) => {
      this.fetchProductBase({ itemId: item.id })
    })
  }

  /**
   * Асинхронно получает данные о продукте.
   *
   * @param {Object} param - Объект с параметрами.
   * @param {string|null} param.itemId - Идентификатор продукта, по умолчанию null.
   * @param {string} param.view - Название представления, по умолчанию TARGET.MAIN.
   * @return {Promise<Object>} - Возвращает Promise с данными о продукте.
   */
  async getProduct({ itemId = null, view = TARGET.MAIN } = {}) {
    this.gaApp.stores.pdp[view][STATE.PRODUCT].pending = true
    const {
      id: cityId,
      cityDistrict,
      geoPolygons,
    } = this.gaApp.services.location.main.getDeliveryAddress()

    const { customerGroupId, groupId } = this.gaApp.services.user.main.data

    const requestParams = {
      itemId: itemId || this.gaApp.stores.pdp[view].productSelectedId,
      cityId,
      cityDistrict,
      geoPolygons,
      customerGroupId: customerGroupId || groupId || '0',
    }

    const cacheData =
      this.gaApp.services.cache.main.getServerData('productCard')

    const response =
      cacheData ||
      (await this.gaApp.repositories.pdp[view].getProduct(requestParams))

    this.gaApp.stores.pdp[view][STATE.PRODUCT].data = response?.data
    this.gaApp.stores.pdp[view].attributesSelected =
      response?.data?.attributesSelectedDefault

    this.gaApp.stores.pdp[view][STATE.PRODUCT].pending = false
    return response
  }

  // ручка запроса продукта без записи в стор с кэшем
  async fetchProductBase({ itemId }) {
    const { customerGroupId, groupId } = this.gaApp.services.user.main.data
    const cityId = this.gaApp.services.app.main.getMainCity()?.value

    const requestParams = {
      itemId,
      cityId,
      customerGroupId: customerGroupId || groupId || '0',
    }

    const cacheData =
      this.gaApp.services.cache.main.getServerData('productCard')

    const response =
      cacheData ||
      (await this.gaApp.repositories.pdp.main.getProductBase(requestParams))

    return response
  }

  // ручка запроса карточки продукта
  async getProductBase({ itemId = null, view = TARGET.MAIN } = {}) {
    this.gaApp.stores.pdp.main[STATE.PRODUCT].pending = true

    const productId = itemId || this.gaApp.stores.pdp[view].productSelectedId
    const response = await this.fetchProductBase({ itemId: productId })

    this.gaApp.stores.pdp[view][STATE.PRODUCT].data = response?.data
    this.gaApp.stores.pdp[view].attributesSelected =
      response?.data?.attributesSelectedDefault

    this.gaApp.stores.pdp[view][STATE.PRODUCT].pending = false

    return response
  }

  // ручка для обновления карточки в зависимости от геоданных и данных пользака
  async getProductAdditional({ itemId = null, view = TARGET.MAIN } = {}) {
    const {
      id: cityId,
      cityDistrict,
      geoPolygons,
    } = this.gaApp.services.location.main.getDeliveryAddress()

    const { customerGroupId, groupId } = this.gaApp.services.user.main.data

    const requestParams = {
      itemId: itemId || this.gaApp.stores.pdp[view].productSelectedId,
      cityId,
      cityDistrict,
      geoPolygons,
      customerGroupId: customerGroupId || groupId || '0',
    }

    const { data } =
      await this.gaApp.repositories.pdp.main.getProductAdditional(requestParams)

    const variants = this.gaApp.stores.pdp[view].product.data?.variants

    // мерж кэшированных вариантов из базовой ручки и геозависимых данных
    if (variants?.length && data?.variants?.length) {
      this.gaApp.stores.pdp[view].product.data.variants = variants.map(
        (variant, index) => {
          if (data.variants[index]) {
            return { ...variant, ...data.variants[index] }
          }

          return variant
        },
      )
    }
  }

  /**
   * Асинхронно получает данные о доставке продукта.
   *
   * @param {Object} param - Объект с параметрами.
   * @param {string|null} param.itemId - Идентификатор продукта, по умолчанию null.
   * @param {string} param.view - Название представления, по умолчанию TARGET.MAIN.
   * @return {Promise<void>} - Возвращает Promise после обработки данных о доставке.
   */
  async getDelivery({
    itemId = null,
    view = TARGET.MAIN,
    ...otherParams
  } = {}) {
    const timer = setTimeout(() => {
      this.gaApp.stores.pdp[view][STATE.DELIVERY].pending = true
    }, DELAY_LOADING)

    try {
      // отменяем предыдущий запрос
      this.gaApp.api.requestAbort.abortRequest(DELIVERY_ABORT_KEY)

      const cacheData = this.gaApp.services.cache.main.getServerData('delivery')
      const {
        id: cityId,
        cityDistrict,
        geoPolygons,
      } = this.gaApp.services.location.main.getDeliveryAddress()

      const { data } =
        cacheData ||
        (await this.gaApp.repositories.pdp.main.getDelivery(
          {
            itemId: itemId || this.gaApp.stores.pdp[view].productSelectedId,
            cityId,
            cityDistrict,
            geoPolygons,
            ...otherParams,
          },
          { abortKey: DELIVERY_ABORT_KEY },
        ))
      this.gaApp.stores.pdp[view][STATE.DELIVERY].data = data
    } catch ({ data, message }) {
      if (message === DELIVERY_ABORT_KEY) {
        return
      }

      this.gaApp.stores.pdp[view][STATE.DELIVERY].error = data
    } finally {
      clearTimeout(timer)
      this.gaApp.stores.pdp[view][STATE.DELIVERY].pending = false
    }
  }

  /**
   * Асинхронно получает описание продукта.
   *
   * @param {Object} param - Объект с параметрами.
   * @param {string} param.itemId - Идентификатор продукта.
   * @param {string} param.view - Название представления, по умолчанию TARGET.MAIN.
   * @return {Promise<void>} - Возвращает Promise после обработки описания продукта.
   */
  async getProductDescription({ itemId, view = TARGET.MAIN }) {
    const timer = setTimeout(() => {
      this.gaApp.stores.pdp[view].hasLoadingDescription = true
    }, DELAY_LOADING)

    try {
      const { id: cityId } =
        this.gaApp.services.location.main.getDeliveryAddress()

      const user = this.gaApp.services.user.main.data

      const { data } =
        await this.gaApp.repositories.pdp.main.getProductDescription({
          itemId,
          cityId,
          customerGroupId: user.customerGroupId || user.groupId || '0',
        })

      this.gaApp.stores.pdp[view][STATE.PRODUCT].data.productDescription =
        data.productDescription
    } catch (error) {
      this.gaApp.services.app.apm.captureError(error)
    } finally {
      clearTimeout(timer)
      const timerOff = setTimeout(() => {
        this.gaApp.stores.pdp[view].hasLoadingDescription = false
        clearTimeout(timerOff)
      }, DELAY_LOADING_OFF)
    }
  }

  /**
   * Асинхронно получает слоты для продукта.
   *
   * @param {Object} product — Текущий продукт
   * @return {Promise<void>} Возвращает Promise после обработки слотов.
   */
  async getProductSlots(product) {
    const { data } = await this.getSlots(product)

    return (this.gaApp.stores.pdp.main.slots = data)
  }

  async getBenefits({ itemId = null }) {
    const { id: cityId, geoPolygons } =
      this.gaApp.services.location.main.getDeliveryAddress()

    const requestParams = {
      itemId: itemId || this.gaApp.stores.pdp.main.productSelectedId,
      cityId,
      geoPolygons,
    }

    const { data } =
      await this.gaApp.repositories.pdp.main.getBenefits(requestParams)

    this.gaApp.stores.pdp.main[STATE.PRODUCT].data.benefits = data.benefits

    return data
  }

  /**
   * Нужно для того, что бы получить слоты БЗ для продукта.
   * Например logobar
   */
  getSlots(product) {
    if (this.gaApp.features.get('bzSlotsV2')) {
      /**
       * По контракту, поле brandZoneInfo обязательно должно быть у товара
       * Но бывают кейсы, когда бренд отсутствует в системе, из-за этого поле brandZoneInfo может отсутствовать
       *
       * Делаем опицональную проверку для того, что бы не выбрасывалась ошибка
       */
      if (!product.brandZoneInfo?.brandMainCategoryId) {
        return {
          data: null,
        }
      }

      // Проверям, является ли категория брендзоной
      return this.gaApp.repositories.pdp.main.getSlotsV2({
        categoryId: product.brandZoneInfo.brandMainCategoryId,
      })
    }

    return this.gaApp.repositories.pdp.main.getSlots({
      brandUrl: product.brandUrl,
    })
  }
}
