import { LAST_ORDER_ID_LOCAL_STORAGE_KEY } from '../constants/main'

export class ThankYouService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  /**
   * Получает данные заказа для отображения страницы успешного оформления заказа - thank you page (TYP).
   * @param {object} redirect - данные редиректа от роута
   * @param {string} redirect.maskedId - id заказа
   */
  async getData({ maskedId }) {
    try {
      const data =
        await this.gaApp.services.checkout.api.getThankYouData(maskedId)

      this.gaApp.stores.checkout.thankYou.$patch(data)
      this.removeLastOrderId(maskedId)
    } catch (error) {
      console.log(error)
      this.gaApp.redirectError({ statusCode: 500, message: error })
    }
  }

  /**
   * Удаляет данные последнего оформленного заказа из local storage, если id заказа ему соответствует
   * @param {string} maskedId - id заказа
   */
  removeLastOrderId(maskedId) {
    const lastOrderId = this.gaApp.services.app.browserStorage.local.get(
      LAST_ORDER_ID_LOCAL_STORAGE_KEY,
    )

    // Удаляем сохраненный id последнего оформленного заказа только в случае, если TYP был открыт для него
    if (maskedId === lastOrderId) {
      this.gaApp.services.app.browserStorage.local.remove(
        LAST_ORDER_ID_LOCAL_STORAGE_KEY,
      )
    }
  }
}
