import Vue from 'vue'

import {
  AUTOMATED_TESTING_CONFIG_COOKIES_KEY,
  AUTOMATED_TESTING_COOKIES_KEY,
} from '@ga/constants/automated-testing'
import { EventBus } from '@ga/utils'

import { COUNTRY, COUNTRY_GROUP, LANGUAGE } from '../constant'
import { routes } from '../router'

import { locationHandler, mergeLocales } from './utils'

import { getCountryFromLocale } from '~/locales/utils'

export class GaApp {
  constructor(context, config) {
    this.ctx = context

    this.appVue = context.app
    this.config = config
    this.eventBus = new EventBus()
    this.routes = routes
    this.router = this.getRouter()

    this.modules = {
      routes: [],
      services: {},
      locales: {},
      stores: {},
      repositories: {},
      analytics: {},
    }

    this.isCountry = this.getIsCountry()
    this.isLanguage = this.getIsLanguage()
  }

  get configNuxt() {
    return this.ctx.$config
  }

  get services() {
    return this.modules.services
  }

  get stores() {
    return this.modules.stores
  }

  get repositories() {
    return this.modules.repositories
  }

  get isClient() {
    return Boolean(process.client)
  }

  get isServer() {
    return Boolean(!process.client)
  }

  get isWebview() {
    return !!this.modules.stores.app.main.webview
  }

  get redirectError() {
    return this.ctx.error
  }

  get redirect() {
    const {
      localePath,
      i18n: { locale, defaultLocale, localeCodes },
    } = this.ctx.app
    return (location) =>
      this.ctx.redirect(
        locationHandler(location, {
          locale,
          defaultLocale,
          localeCodes,
          localePath,
        }),
      )
  }

  get serverRequest() {
    return this.ctx.req
  }

  get route() {
    return this.appVue.context.route
  }

  get store() {
    return this.appVue.store
  }

  get cookies() {
    return this.appVue.$cookies
  }

  get isAutomatedTesting() {
    return Boolean(this.cookies.get(AUTOMATED_TESTING_COOKIES_KEY))
  }

  get automatedTestingConfig() {
    return this.cookies.get(AUTOMATED_TESTING_CONFIG_COOKIES_KEY)
  }

  get i18n() {
    return this.ctx.$i18n
  }

  get isRtl() {
    return this.ctx.$i18n.locale.dir === 'rtl'
  }

  get isMiddleEast() {
    return COUNTRY_GROUP.ME.includes(this.ctx.$i18n.locale.country)
  }

  /**
   * Предоставляет флаги чтобы проверить какая страна или группа стран сейчас выбрана
   */
  getIsCountry() {
    const locale = this.config.get('locale.active')
    const currentCountry = getCountryFromLocale(locale)

    const countryBooleanFlags = Object.fromEntries(
      Object.entries(COUNTRY).map(([key, value]) => [
        key.toLowerCase(),
        value === currentCountry,
      ]),
    )

    const countryGroupBooleanFlags = Object.fromEntries(
      Object.entries(COUNTRY_GROUP).map(([key, value]) => [
        key.toLowerCase(),
        value.includes(currentCountry),
      ]),
    )

    return {
      ...countryBooleanFlags,
      group: {
        ...countryGroupBooleanFlags,
      },
    }
  }

  getIsLanguage() {
    const currentLanguage = this.ctx.i18n.locale

    const languageBooleanFlags = Object.fromEntries(
      Object.entries(LANGUAGE).map(([key, value]) => {
        return [key.toLowerCase(), value === currentLanguage]
      }),
    )

    return languageBooleanFlags
  }

  get analytics() {
    return this.ctx.$analytics
  }

  get api() {
    return this.ctx.$api
  }

  get mq() {
    return this.ctx.$mq
  }

  get isInit() {
    return this.stores.app.main.init
  }

  get libs() {
    return {
      apm: this.appVue.$apm,
      dateWrapper: this.appVue.$dateWrapper,
      notifier: this.appVue.$notifier,
      scrollTo: this.appVue.$scrollTo,
    }
  }

  get features() {
    return {
      get: (key) => this.services.featuresToggle.main.get(key),
    }
  }

  get getDataTestId() {
    return this.ctx.$getDataTestId
  }

  getRouter() {
    return new Proxy(this.appVue.router, {
      get: (target, prop) => {
        if (prop === 'push') {
          const {
            localePath,
            i18n: { locale, defaultLocale, localeCodes },
          } = this.ctx.app

          return (location, onComplete, onAbort) =>
            target.push(
              locationHandler(location, {
                locale,
                defaultLocale,
                localeCodes,
                localePath,
              }),
              onComplete,
              onAbort,
            )
        }

        return target[prop]
      },
    })
  }

  installModules(modules) {
    modules.forEach((module) => {
      const { meta = {}, components = {}, layers = {} } = module

      if (!meta.name) {
        return
      }

      if (meta.routes) {
        meta.routes.forEach((route) => {
          this.routes.push(route)
        })
      }

      // если в локалях модуля есть дефолтная локаль (ru-RU)
      if (meta.locales?.[this.config.get('locale.default')]) {
        const defaultLocale = meta.locales[this.config.get('locale.default')]

        // берем список доступных локалей
        const locales = this.ctx.i18n.locales.map(({ iso }) => iso)

        locales.forEach((locale) => {
          if (!(locale in this.modules.locales)) {
            this.modules.locales[locale] = {}
          }
          // добавляем локали
          // если какой-либо локали нет в модуле, будет добавлено содержимое дефолтной локали
          this.modules.locales[locale][meta.name] = mergeLocales(
            defaultLocale,
            meta.locales[locale],
          )
        })
      }

      Object.keys(components).forEach((name) => {
        Vue.component(name, components[name])
      })

      Object.keys(layers).forEach((layer) => {
        if (layer === 'analytics') {
          this.analytics.modules[meta.name] = layers[layer](
            this,
            this.analytics.libs,
          )
        } else {
          this.modules[layer][meta.name] = layers[layer](this)
        }
      })
    })
  }
}
