import {
  GaIconServicesApple,
  GaIconServicesApplePay,
  GaIconServicesGoogle,
  GaIconServicesGooglePay,
  GaIconServicesOplati,
  GaIconServicesSber,
  GaIconServicesSberPay,
  GaIconServicesSbp,
  GaIconServicesTinkoff,
  GaIconServicesVk,
  GaIconServicesYandex,
  GaIconServicesYandexPay,
} from '@ga/icons'

export { STATE } from '../../button/scripts/const'

export const VIEW = {
  MAIN: 'main',
}

export const SIZE = {
  M: 'm',
  L: 'l',
  XL: 'xl',
}

export const ICON = {
  APPLE: 'apple',
  APPLE_PAY: 'apple-pay',
  GOOGLE: 'google',
  GOOGLE_PAY: 'google-pay',
  SBER: 'sber',
  SBER_PAY: 'sber-pay',
  SBP: 'sbp',
  TINKOFF: 'tinkoff',
  VK: 'VK',
  YANDEX: 'yandex',
  YANDEX_PAY: 'yandex-pay',
  OPLATI: 'oplati',
}

export const ICON_COMPONENT = {
  [ICON.APPLE]: GaIconServicesApple,
  [ICON.APPLE_PAY]: GaIconServicesApplePay,
  [ICON.GOOGLE]: GaIconServicesGoogle,
  [ICON.GOOGLE_PAY]: GaIconServicesGooglePay,
  [ICON.SBER]: GaIconServicesSber,
  [ICON.SBER_PAY]: GaIconServicesSberPay,
  [ICON.SBP]: GaIconServicesSbp,
  [ICON.TINKOFF]: GaIconServicesTinkoff,
  [ICON.VK]: GaIconServicesVk,
  [ICON.YANDEX]: GaIconServicesYandex,
  [ICON.YANDEX_PAY]: GaIconServicesYandexPay,
  [ICON.OPLATI]: GaIconServicesOplati,
}
