import { computed, toRefs, unref } from 'vue'

import { useBem } from '@ga/bem'
import { transitionPropsBuilder } from '@ga/utils'

import { GaProductCardCounter } from '../../atoms/counter'
import { GaProductCardCartButton } from '../../molecules/cart-button'
import { SIZE } from '../../molecules/cart-button/scripts/consts'

// @vue/component
export default {
  name: 'ga-product-card-cart',

  components: {
    GaProductCardCartButton,
    GaProductCardCounter,
  },

  props: {
    // Количество добавленного продукта
    count: {
      type: Number,
      default: 0,
    },

    // Размер кнопки добавления в корзину и каунтера
    size: {
      type: String,
      default: SIZE.S,
    },

    // В состоянии загрузки ли
    loading: {
      type: Boolean,
      default: false,
    },

    // Заблокировано ли добавление продуктов
    disabled: {
      type: Boolean,
      default: false,
    },

    // Внешний класс для кнопки корзины
    counterCartClass: {
      type: String,
      default: null,
    },
  },

  emits: ['click', 'decrease', 'increase'],

  setup(props, { emit }) {
    const b = useBem()

    const { count, size } = toRefs(props)

    // Нужно для того, что бы не показывать "0", когда добавленных больше нет
    const countInternal = computed(() => {
      return unref(count) < 1 ? 1 : unref(count)
    })

    const hasCount = computed(() => {
      return unref(count) > 0
    })

    const mods = computed(() => ({
      size: unref(size),
    }))

    const onCartClick = () => {
      emit('click')
    }

    const onDecreaseClick = () => {
      emit('decrease')
    }

    const onIncreaseClick = () => {
      emit('increase')
    }

    // Стили перехода между кнопкой добавить в корзину и каунтером
    const transitionProps = computed(() => transitionPropsBuilder(b)('in-out'))

    return {
      mods,

      transitionProps,

      hasCount,
      countInternal,

      onCartClick,
      onDecreaseClick,
      onIncreaseClick,
    }
  },
}
