export const CURRENCY_CODE_RUB = 'RUB'
export const CURRENCY_CODE_BYR = 'BYR'
export const CURRENCY_CODE_BYN = 'BYN'
export const CURRENCY_CODE_KZT = 'KZT'
export const CURRENCY_CODE_QAR = 'QAR'
export const CURRENCY_CODE_AED = 'AED'
export const CURRENCY_CODE_SAR = 'SAR'
export const CURRENCY_CODE_BONUS = 'BONUS'

export const CURRENCY_SYMBOL_RUB = '₽'
export const CURRENCY_SYMBOL_BYN = 'BYN'
export const CURRENCY_SYMBOL_KZT = '₸'
export const CURRENCY_SYMBOL_QAR = 'ر.ق'
export const CURRENCY_SYMBOL_AED = 'د.إ.'
export const CURRENCY_SYMBOL_SAR = 'ر.س'

export const CURRENCIES = {
  [CURRENCY_CODE_RUB]: CURRENCY_SYMBOL_RUB,
  [CURRENCY_CODE_BYR]: CURRENCY_SYMBOL_BYN,
  [CURRENCY_CODE_BYN]: CURRENCY_SYMBOL_BYN,
  [CURRENCY_CODE_KZT]: CURRENCY_SYMBOL_KZT,
  [CURRENCY_CODE_QAR]: CURRENCY_SYMBOL_QAR,
  [CURRENCY_CODE_AED]: CURRENCY_SYMBOL_AED,
  [CURRENCY_CODE_SAR]: CURRENCY_SYMBOL_SAR,
}
