import { queryHighlight } from '@ga/utils'

import { findQueryPosition } from '../composable/find-query-position'

export class SearchService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  buildBrandLabel({ start, size, label }) {
    const highlighted = label.substr(start, size)

    return queryHighlight(label, highlighted, {
      htmlTag: 'mark',
    })
  }

  // Для того, чтобы выделить жирным совпадение поискового запроса в названии бренда,
  // нужно учесть возможность наличия апострофа и т.н. алиасов букв вроде è, é и т.д.
  buildBrand(option) {
    const query = this.gaApp.stores.promo.search.queryPlain
    const startPos = option.labelPlain.indexOf(query)
    const querySize = query.length

    const apostropheIndex = option.label.search(/['`’]/)

    const { start, size } = findQueryPosition(
      query,
      startPos,
      querySize,
      apostropheIndex,
    )

    const label = this.buildBrandLabel({
      start,
      size,
      label: option.label,
    })

    return {
      ...option,
      label,
    }
  }

  /**
   * Возвращает рузльтаты поиска
   *
   * @param {string} queryPlain нормализованная строка поиска
   * @param {object} groupped содержит сгруппированные значения брэндом по буквам
   * @param {array} resultFirst результаты поиска, где поисковая фраза входит название брэнда с первого символа
   * @param {array} resultSecond результаты поиска, где поисковая фраза входит название брэнда НЕ с первого символа
   *
   * @returns {object} объект содуржащий результаты поиска
   */
  getSearchResult({ queryPlain, groupped, resultFirst, resultSecond }, b) {
    let first = []
    let second = []
    let entryDirectChar = ''

    // Если введен только один символ, то пытаемся найти группу элементов по этому символу
    const queryUppercase = queryPlain.value.toUpperCase()
    const elements = groupped.value[queryUppercase]

    if (elements) {
      entryDirectChar = queryUppercase
      first = elements
      second = []
    }

    // Иначе билдим результат с учетом всей строки поиска
    if (!elements && queryPlain.value) {
      first = resultFirst.value.map((option) => this.buildBrand(option, b))
      second = resultSecond.value.map((option) => this.buildBrand(option, b))
    }

    return {
      first,
      second,
      entryDirectChar,
    }
  }

  /**
   * Нужно для того, что сформировать результаты поиска
   *
   * @param {object} $event объект, который возвращается из компонента алфивитного списка при поиске
   */
  search($event, b) {
    this.gaApp.stores.promo.search.queryOriginal = $event.query.value
    this.gaApp.stores.promo.search.queryPlain = $event.queryPlain.value

    const { first, second, entryDirectChar } = this.getSearchResult($event, b)

    this.gaApp.stores.promo.search.resultFirst = first
    this.gaApp.stores.promo.search.resultSecond = second
    this.gaApp.stores.promo.search.firstCharEntryDirect = entryDirectChar
  }

  reset() {
    this.gaApp.stores.promo.search.$reset()
  }
}
