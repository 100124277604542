import { PAGE_TYPE } from '../constants'

export const routes = [
  {
    moduleType: 'auth',
    pageType: PAGE_TYPE.CONFIRM_CODE,
    path: '/auth/:id',
    meta: {
      analyticDisabled: false,
      headerVisible: false,
      footerVisible: false,
    },
    component: () =>
      import(
        /* webpackChunkName: 'modules/auth/page/external-auth' */
        '~/modules/auth/components/pages/external-auth.vue'
      ),
  },
]
