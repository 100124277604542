import { CARDS_PAGE_REDIRECT_PARAMS } from '../constants/cards'

export class CardsService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  setLoyalty(data) {
    this.gaApp.stores.customer.cards.loyalty = data
  }

  setLoyaltyBanner(data) {
    this.gaApp.stores.customer.cards.loyaltyBanner = data
  }

  toggleLoyaltyInfoModal(value) {
    this.gaApp.stores.customer.cards.isLoyaltyInfoModalOpened = value
  }

  toggleLoyaltyDetailsModal(value) {
    this.gaApp.stores.customer.cards.isLoyaltyDetailsModalOpened = value
  }

  setLoyaltyCardState(value) {
    this.gaApp.stores.customer.cards.loyaltyCardState = value
  }

  toggleLoyaltyQrModal(value) {
    this.gaApp.stores.customer.cards.loyaltyQrModal.active = value
  }

  /**
   * Метод для редиректа пользователя на страницу карт с отображением анимации выпуска карты;
   * Вызывается после первичного заполнения профиля пользователя в ЛК
   * @param {Object} config - Конфиг с опциями
   * @param {boolean} config.animate - Флаг для включения анимацию выпуска карты при редиректе
   */
  redirectToCardsPage({ animate }) {
    // Устанавливаем флаг анимации
    this.gaApp.stores.customer.cards.animateLoyaltyCard = animate

    // Перенаправляем пользователя на страницу карт лояльности
    this.gaApp.services.app.router.pushToRoute({
      moduleType: CARDS_PAGE_REDIRECT_PARAMS.MODULE_TYPE,
      pageType: CARDS_PAGE_REDIRECT_PARAMS.PAGE_TYPE,
    })
  }
}
